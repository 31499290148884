import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../page.service'

@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.css']
})
export class TagFilterComponent implements OnInit {
  @Input() tp: string
  @Input() value: string
  @Input() label: string

  constructor(private pgs: PageService) { }

  ngOnInit() {
  }


  switch() {
    this.pgs.setSelectedFilter(this.tp, this.value)
  }

  get isSelected() {
    return this.pgs.isSelectedFilter(this.tp, this.value)
  }

}
