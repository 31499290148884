<div class='main'
    [class.accepted]='selectReasons("accept")'
    [class.rejected]='selectReasons("reject")'
    [class.undecided]='selectReasons("undecided")'>

  <div class='menu'>
    <app-tag-fulltext-rating tp='main'
        value='accept'
        label='Process'
        [dbRecord]='dbRecord'
        [fulltextRating]='fulltextRating'></app-tag-fulltext-rating>

    <app-tag-fulltext-rating tp='main'
        value='undecided'
        label='Undecided'
        [dbRecord]='dbRecord'
        [fulltextRating]='fulltextRating'></app-tag-fulltext-rating>

    <app-tag-fulltext-rating tp='main'
        value='reject'
        label='Reject'
        [dbRecord]='dbRecord'
        [fulltextRating]='fulltextRating'></app-tag-fulltext-rating>
  </div>

  <div class='reasons'>
    <div *ngIf='selectReasons("accept")'>
      <div *ngFor='let obj of acceptanceLs'>
        <app-tag-fulltext-rating [dbRecord]='dbRecord'
            [label]='obj.title'
            [value]='obj.value'
            [fulltextRating]='fulltextRating'
            tp='reason'></app-tag-fulltext-rating>
      </div>
    </div>
    <div *ngIf='selectReasons("undecided")'>
      <div *ngFor='let obj of undecidedLs'>
        <app-tag-fulltext-rating [dbRecord]='dbRecord'
            [label]='obj.title'
            [value]='obj.value'
            [fulltextRating]='fulltextRating'
            tp='reason'></app-tag-fulltext-rating>
      </div>
    </div>
    <div *ngIf='selectReasons("reject")'>
      <div *ngFor='let obj of rejectLs'>
        <app-tag-fulltext-rating [dbRecord]='dbRecord'
            [label]='obj.title'
            [value]='obj.value'
            [fulltextRating]='fulltextRating'
            tp='reason'></app-tag-fulltext-rating>
      </div>
    </div>
  </div>
</div>
