import { Editor } from './editor'
import  { FlowerNotes } from './flower-notes'

export class Note {
  text: string
  editor: Editor

  constructor(public id: string,
    public parent: FlowerNotes,
    text: string,
    public date: Date
  ) {
      this.editor = parent.editor
      this.text = text.trim()
    }

  get saveParams() {
    return { id: this.id,
      text: this.text,
      date: this.date ? this.date.toJSON() : null
     }
  }
}
