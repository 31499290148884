<button *ngIf='!isExpanded'
    (click)='switch()'>
    Process searches
  </button>

<div *ngIf='isExpanded'
    class='main'>
  <div>
    <button (click)="switch()">Cancel</button>
    <button (click)="process()">Process</button>
    <span class='processing'>{{processMessage}}</span>
  </div>

  <h3>Files</h3>
  <div class='file'
      *ngFor='let obj of files;let i = index'>
    {{i+1}}.
    <a target='_blank'
        href='https://gae-test.ecomole.com/s/r{{obj["gaePath"]}}'>
    {{obj['filename']}}
    </a> ({{obj['size']}})
    <button *ngIf='!isFileForDelete(i)'
        (click)='deleteFile(i)'>Delete file</button>
    <span *ngIf='isFileForDelete(i)'>
      <button (click)='cancelDelete(i)'>Cancel</button>
      <button (click)='deleteConfirmed(i)'>!!! Delete - no way back !!!</button>
    </span>
  </div>

</div>
