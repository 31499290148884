// tslint:disable:curly
import { Injectable } from '@angular/core';
import { CriterionItem } from './criterion-item';
import { DbRecord } from './db-record';
import { Flower } from './flower';
import { Editor } from './editor';


@Injectable()
export class PageService {
  recordsLength: number;
  pageLength: number;
  flowersLength: number;
  flowersPageLength = 50;
  currentStart = 0;
  searchFilter: RegExp;
  authorFilter: RegExp;
  targetDbRecordId: string;

  private _abstractFilter;
  private _fulltextFilter;
  private _ownerFilter: Editor;
  private _assigneeFilter: Editor;
  private _flowerStatusFilter: string;

  private _onCriterionItemsSet = new Set<string>();
  _allCriterionMatchState = false;
  private _sortFlowersByUniquState = false;
  private _familyFilterState: string = null;
  private _isTop50 = false;
  private _isTop100 = false;
  _dbRecordsCache: DbRecord[];
  langInclude = new Set<string>();
  langExclude = new Set<string>();

  constructor() { }

  langSwitch(lang: string, isExclude: boolean) {
    if (isExclude) {
      this.langInclude.delete(lang);
      if (!this.langExclude.delete(lang))
        this.langExclude.add(lang);
    } else {
      this.langExclude.delete(lang);
      if (!this.langInclude.delete(lang))
        this.langInclude.add(lang);
    }
  }

  isLangSelected(lang: string) {
    return this.langInclude.has(lang);
  }

  isLangExcluded(lang: string) {
    return this.langExclude.has(lang);
  }

  getPage(ls: DbRecord[]) {
    if (this.targetDbRecordId) {
      for (let i = 0; i < ls.length; i++) {
        if (ls[i].id === this.targetDbRecordId) {
          this.currentStart = Math.floor(i / this.pageLength) * this.pageLength;
          break;
        }
      }
    }
    let pgLs = ls.slice(this.currentStart, this.currentStart + this.pageLength);
    return pgLs;
  }

  getFlowersPage(ls: Flower[]) {
    return ls.slice(this.currentStart, this.currentStart + this.flowersPageLength);
  }

  get currentFilter() {
    return this._fulltextFilter || this._abstractFilter;
  }

  get isOwnerFilterOn() {
    return !!this._ownerFilter;
  }

  get isAssigneeFilterOn() {
    return !!this._assigneeFilter;
  }


  get isFlowerStatusFilterOn() {
    return !!this._flowerStatusFilter;
  }

  setFlowerStatus(status: string) {
    if (this._flowerStatusFilter === status)
      this._flowerStatusFilter = null;
    else
      this._flowerStatusFilter = status;
    this.currentStart = 0;
  }

  isFlowerStatusFilter(status: string) {
    return this._flowerStatusFilter === status;
  }

  setOwnerFilter(owner: Editor) {
    if (this._ownerFilter === owner)
      this._ownerFilter = null;
    else
      this._ownerFilter = owner;
    this.currentStart = 0;
  }

  isOwnerFilter(owner: Editor) {
    return this._ownerFilter === owner;
  }

  setAssigneeFilter(assignee: Editor) {
    if (this._assigneeFilter === assignee)
      this._assigneeFilter = null;
    else
      this._assigneeFilter = assignee;
    this.currentStart = 0;
  }

  isAssigneeFilter(assignee: Editor) {
    return this._assigneeFilter === assignee;
  }


  isSelectedFilter(tp, tag) {
    if (tp === 'abstract')
      return this._abstractFilter === tag;
    else
      return this._fulltextFilter === tag;
  }

  setSelectedFilter(tp, tag) {
    if (tp === 'abstract') {
      this._abstractFilter = this.isSelectedFilter(tp, tag) ? null : tag;
      if (this._abstractFilter !== 'accepted')
        this._fulltextFilter = null;
    } else {
      this._fulltextFilter = this.isSelectedFilter(tp, tag) ? null : tag;
      if (this._fulltextFilter)
        this._abstractFilter = 'accepted';
    }
    this.currentStart = 0;
  }

  switchCriterionItem(ci: CriterionItem) {
    if (!this._onCriterionItemsSet.delete(ci.regexp.source))
      this._onCriterionItemsSet.add(ci.regexp.source);
  }

  isOnCriterionItem(ci: CriterionItem) {
    return this._onCriterionItemsSet.has(ci.regexp.source);
  }


  criterionHits(txt: string, criterionItems: CriterionItem[]) {
    if (!txt)
      return txt;
    if (this.searchFilter)
      txt = txt.replace(this.searchFilter, '<span class="searchHit">$&</span>');
    criterionItems.forEach((ci) => {
      if (this._allCriterionMatchState || this._onCriterionItemsSet.has(ci && ci.regexp.source))
        txt = txt.replace(ci && ci.regexp, '<span class="highlight">$&</span>');
    });
    return txt;
  }

  switchAllCriterionMatch() {
    this._allCriterionMatchState = !this._allCriterionMatchState;
  }

  switchSortFlowersByUnique() {
    this._sortFlowersByUniquState = !this._sortFlowersByUniquState;
  }

  switchFamilyFilter(tag: string) {
    if (tag === this._familyFilterState)
      this._familyFilterState = null;
    else
      this._familyFilterState = tag;
  }

  get isOnAllCriterionMatch() {
    return this._allCriterionMatchState;
  }

  get isOnSortFlowersByUnique() {
    return this._sortFlowersByUniquState;
  }

  get isOnFamilyFilter() {
    return !!this._familyFilterState;
  }

  get familyFilterValue() {
    return this._familyFilterState;
  }

  clearFamily() {
    this._familyFilterState = null;
  }

  isCurrentFamilyFilter(tag: string) {
    return this._familyFilterState === tag;
  }

  get isTop50() {
    return this._isTop50;
  }

  top50switch() {
    this._isTop100 = false;
    this._isTop50 = !this._isTop50;
    if (this._isTop50)
      this.currentStart = 0;
  }

  get isTop100() {
    return this._isTop100;
  }

  top100switch() {
    this._isTop50 = false;
    this._isTop100 = !this._isTop100;
    if (this._isTop100)
      this.currentStart = 0;
  }

  clear() {
    this.currentStart = 0;
    this._onCriterionItemsSet.clear();
    this._allCriterionMatchState = false;
    this._isTop50 = false;
    this._abstractFilter = null;
    this._fulltextFilter = null;
    this._ownerFilter = null;
    this._flowerStatusFilter = null;
  }

}
