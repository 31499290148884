import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { Flower } from './flower'
import { Subject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { UtilsService } from './utils.service'
import { ArcService } from './arc.service'
import { DbRecord } from './db-record'
import { DbRecordService } from './db-record.service';
import { EditorService } from './editor.service';
import { AbstractRatingService } from './abstract-rating.service';
import { FulltextRatingService } from './fulltext-rating.service';
import { BookmarkService } from './bookmark.service';
import { NoteService } from './note.service';
import { CriterionService } from './criterion.service';
import { StatusService } from './status.service';
import { FamilyService } from './family.service';
import { PermalinkService } from './permalink.service';
import { ParserService } from './parser.service';
import { PageService } from './page.service';
import { CompoundHitsService } from './compound-hits.service';
import { Criterion } from './criterion';
import { HttpClient } from '@angular/common/http';
import { FulltextService } from './fulltext.service';



@Injectable()
export class FlowerService {
  private _flowerLs: Flower[];

  botanicalStatus = {};
  searchStats: Object;
  //searchDates: Object
  processMessage = '';

  initFinished = false;
  searchStatsFinished = false;
  _initStartedSet = new Set<string>();
  initObservable;
  showEFSAratingsOnly = false;
  showEFSAratingsExclude = false;
  showExtractAbstractOnly = false;
  currentFlower: Flower;
  isSavingFlower = false;
  startFlower: string;
  hash: string;
  currentFlowerList = []

  constructor(
    private arcs: ArcService,
    private ars: AbstractRatingService,
    private bs: BookmarkService,
    private chs: CompoundHitsService,
    private cs: CriterionService,
    private dbrs: DbRecordService,
    private es: EditorService,
    private fmls: FamilyService,
    private frs: FulltextRatingService,
    private http: HttpClient,
    private fulltexts: FulltextService,
    private ns: NoteService,
    private parserService: ParserService,
    private ps: PermalinkService,
    private router: Router,
    private utils: UtilsService,
    public pgs: PageService,
    public stats: StatusService,
  ) {
    this.bs.fs = this;
    this.parserService.fs = this;
    this.stats.fs = this;
    this.fulltexts.fs = this;
  }

  sendReferenceData(flower: Flower, dbRecord: DbRecord) {
    const dct = {
      type: 'referenceData',
      inList: flower.inList,
      flowerId: flower.id,
      combo1id: flower.combo1id,
      record: dbRecord.sendToSimek(),
      info_coded_from_abstract: flower.isAbstractExtract(dbRecord)
    };
    this.arcs.sendToSimek(dct).subscribe(
      resp => window.open(resp['redirect_url'])
    );
  }

  getOwner(flower: Flower) {
    return this.es.getOwner(flower);
  }

  getFlowerOpinions(flower: Flower) {
    /*
    const dct = {
      'count_total': 4,
      'count_with_data': 2,
      'name': 'Abelmoschus moschatus Medik.',
      'opinia': [
        {
          'id': 'eid=2-s2.0-1242274995', 'has_data': true,
          'stats': { 'composition': 1, 'genotox': 0, 'endpoint': 0 }
        },
        {
          'id': 'WOS:000171261800009', 'has_data': true,
          'stats': { 'composition': 1, 'genotox': 0, 'endpoint': 0 }
        }]
      }; */

    const url = `https://combodb.ecomole.com/yellowlist/api/${flower.id}`;
    const obs = this.http.get(url);

    obs.subscribe(dct => {
      const opLs = dct && dct['opinia'];
      if (!opLs) {
        return;
      }
      opLs.forEach(x => flower.opinions.set(x['id'], x['stats']));
    });
  }

  sendToSimek() {
    this.arcs.sendToSimek(this.currentFlower.sendToSimek()).subscribe();
  }

  clearFlowerList() {
    this._flowerLs = null;
  }

  get list() {
    if (!this._flowerLs) {
      this._flowerLs = [];
      Flower.flowersMap.forEach((fl) => this._flowerLs.push(fl));
      this._flowerLs.sort((a, b) => a.mainName[0].toLowerCase() < b.mainName[0].toLowerCase() ? -1 : 1);
    }
    return this._flowerLs;
  }

  newFlower(): Flower {
    let id = this.utils.uniqueIdentifier;
    if (!id)
      return null;
    this._flowerLs = null;
    return new Flower(this, { id: id });
  }

  deleteFlower(flower: Flower) {
    let obs = this.arcs.run({
      fun: 'deleteFlower',
      flower: flower.id
    });
    obs.subscribe((x) => {
      flower.finalizeDelete();
      //this.refreshFlowersListCache()
      //console.log(112233, x)
    });
    return obs;
  }

  get(id: string): Flower {
    return Flower.flowersMap.get(id);
  }

  refreshFlowersListCache() {
    let obs = this.arcs.run({
      fun: 'getFlowerList',
      cachePath: `/combo2cache/FlowerList`,
      refreshCache: true
    });

    obs.subscribe((obj) => {
      console.log('CACHE REFRESH', obj);
    });
  }

  save(flower: Flower, isNewFlower: boolean) {
    this.isSavingFlower = true;
    let obs = this.arcs.run({
      fun: 'saveFlower',
      flower: flower.id,
      params: flower.saveParams
    });
    obs.subscribe((res) => {
      this.isSavingFlower = false;
      //console.log(res)
      //this.refreshFlowersListCache()
    });
    return obs;
  }

  normalize_search(txt: string): string {
    return txt.toLowerCase().trim();
  }

  updateAbstractExtract(flower, data) {
    const obs = this.arcs.run(data);

    obs.subscribe(resp => {
      flower.initAbstractExtractIds(resp[0]);
    });
  }

  private _initFlowerList() {
    const gaedir = '/combo_json/_combo';
    this.arcs.gaeDownload(`${gaedir}/flowerList2.json`)
      .subscribe(resp => {
        Object.keys(resp).forEach(k =>
          new Flower(this, resp[k]));

        this._flowerLs = null;
        this.initFinished = true;

        window.postMessage({
          action: 'flowerListIsReady',
        }, "*");

        console.log('POST flowerListIsReady')

        this.ps.checkHash();
        if (this.startFlower) {
          let f = this.get(this.startFlower);
          this.router.navigate(f.navigateTuple);
        }
      });
  }

  private _initFamilies() {
    const gaedir = '/combo_json/_combo';
    this.arcs.gaeDownload(`${gaedir}/families.json`).subscribe(
      resp => {
        // console.log("FAMILIES", resp)
        this._initFlowerList();
        this.fmls.init(resp);
      }
    );
  }


  private _initAllFlowerStats() {
    const gaedir = '/combo_json/_combo';

    this.arcs.gaeDownload(`${gaedir}/flowerStats.json`).subscribe(
      resp => {
        this._initFamilies();
        this.searchStats = resp;
        this.searchStatsFinished = true;
      });
  }

  private _initCombo2Data() {
    this.arcs.run({
      fun: 'getCombo2Data',
    }).subscribe(obj => {
      this._initAllFlowerStats();
      if (obj['noEmail'])
        return this.router.navigate(['unknown-user']);
      obj = obj[0];
      let criterions = obj['criterions'];
      for (let k of Object.keys(criterions))
        this.cs.create(k, criterions[k]);

      this.dbrs.setMainCriterion(this.cs.mainCriterion);
      this.bs.init(obj['bookmarks']);
      this.es.init(obj);

      this.stats.initFlowerStatuses(obj['statuses']);
      //  this.fmls.init(obj['families'])
    });
  }

  init() {
    let colorObs = this.arcs.getColorsFromSimek();
    colorObs.subscribe((resp) => {
      this.botanicalStatus = resp['botanicalStatus']
      this.chs.init(resp['substances'])
    }
    );

    return this._initCombo2Data();
  }

  private _finalizeInitFlower(params: Object) {
    const f: Flower = params['f']
    const criterionOrderLs = params['criterionOrderLs']
    const dbRecords = params['dbRecords']
    const weights = params['weights']
    const criterionItems = params['criterionItems']
    const flowerData = params['flowerData']

    let sources = new Set<string>();
    let isSavePersistentIds = false;
    // let jjj = 0
    criterionOrderLs.forEach(recordId => {
      const oldRecordId = recordId
      recordId = this.dbrs.finalizeId(recordId);
      let r = dbRecords[recordId];
      if (!r) {
        return;
      }
      let ciMatches = criterionItems[recordId] || [];

      ciMatches.sort((a, b) =>
        a.regexp.source.toLowerCase().replace(/[^a-z]/g, '') < b.regexp.source.toLowerCase().replace(/[^a-z]/g, '') ?
          -1 : 1);
      f._dbRecordsCriterionOrder.push(r);
      r.criterionPosition = f._dbRecordsCriterionOrder.length;
      r.yearPosition = -1 * r.year || 0;
      r.mainCriterionWeight = weights[r.id] || weights[oldRecordId];
      r.criterionItemMatches = ciMatches;
      sources.add((r.source || 'zzz').toLowerCase());
      if (!f.getPersistentRecordId(r)) {
        f.setPersistentRecordId(r);
        isSavePersistentIds = true;
      }
    });
    if (isSavePersistentIds) {
      this.savePersistentRecordIds(f);
    }
    let sss = Array.from(sources);
    let sssDct = {};
    sss.sort((a, b) => a < b ? -1 : 1);
    sss.forEach((x, i) => sssDct[x] = i);
    f.dbRecords.forEach(r =>
      r.sourcePosition = sssDct[(r.source || 'zzz').toLowerCase()]);

    this.ns.initFlower(f, flowerData['notes']);
    this.bs.initDbRecords(f, flowerData['dbRecordBookmarks']);
    setTimeout(x => this.chs.prepareDbRecords(f.dbRecords), 2000);

    this.ars.initFlower(f, flowerData['abstracts'], flowerData['abstractStamps']);
    this.frs.initFlower(f, flowerData['fulltexts'], flowerData['fulltextStamps']);
    f.initKeepReferences(
      flowerData['abstracts'],
      flowerData['fulltexts']);
    f.isReady = true;
    window.postMessage({
      action: 'flowerIsReady',
      flowerId: this.currentFlower.id
    }, "*");
    console.log('POST flowerIsReady')
  }


  private _initIdAbstractExtract(params: Object) {
    const gaedir = params['gaedir']
    const f = params['f']

    this.arcs.gaeDownload(`${gaedir}/idAbstractExtract.json`)
      .subscribe(resp => {
        f.initAbstractExtractIds(resp)
        this._finalizeInitFlower(params);
      });
  }

  private _initIdRecords(params: Object) {
    const gaedir = params['gaedir']
    const f = params['f']

    this.arcs.gaeDownload(`${gaedir}/idRecords.json`)
      .subscribe(resp => {
        this._initIdAbstractExtract(params);
        f.initPersistentRecordIds(resp)
      });
  }

  private _initMainRecords(params: Object) {
    const gaedir = params['gaedir']
    const f = params['f']

    const dbRecords = params['dbRecords'];

    this.arcs.gaeDownload(`${gaedir}/mainRecords.json`)
      .subscribe(resp => {
        this._initIdRecords(params);
        Object.keys(resp).forEach((recordId) => {
          let r = this.dbrs.create(recordId, f);
          r.setData(resp[recordId]);
          dbRecords[r.id] = r;
        });
      });
  }

  private _initFlowerCriterions(params: Object) {
    const gaedir = params['gaedir']

    const weights = params['weights'];
    const criterionOrderLs = params['criterionOrderLs'];
    const criterionItems = params['criterionItems'];

    this.arcs.gaeDownload(`${gaedir}/criterions.json`)
      .subscribe(resp => {
        this._initMainRecords(params)
        let criterion;
        if (resp['regexpWeights']) {
          const cnm = resp['regexpType']
          criterion = new Criterion(cnm, resp['regexpWeights']);
          CriterionService.criterionName = cnm
        } else {
          criterion = this.cs.mainCriterion;
        }
        resp['weights'].forEach(tpl => {
          weights[tpl[0]] = tpl[1];
          criterionOrderLs.push(tpl[0]);
        });

        Object.keys(resp['hits']).forEach(k => {
          let ci = criterion.getItem(k);
          // console.log(ci);
          resp['hits'][k].forEach(recordId => {
            recordId = this.dbrs.finalizeId(recordId);
            let ls = criterionItems[recordId];
            if (!ls) {
              ls = [];
              criterionItems[recordId] = ls;
            }
            ls.push(ci);
          });
        });
      });
  }

  initFlower(flowerId: string) {
    const f = this.get(flowerId);

    const params = {
      f: f,
      dbRecords: {},
      weights: {},
      criterionOrderLs: [],
      criterionItems: {},
      flowerData: {},
      gaedir: `/combo_json/${flowerId}`
    }

    if (!f)
      return this.startFlower;
    if (this._initStartedSet.has(flowerId))
      return null;

    this._initStartedSet.add(flowerId);
    this.getFlowerOpinions(f);
    let finishObservable = new Subject<any>();

    this.arcs.run({
      fun: 'getFlowerData',
      flowerId: flowerId
    }).subscribe(resp => {
      params['flowerData'] = resp[0];
      this._initFlowerCriterions(params)
    });




    return finishObservable;
  }


  getFamily(f: Flower) {
    return this.fmls.familyForFlower(f);
  }

  reset() {
    let obs = this.arcs.resetEdge('flower');
    obs.subscribe((x) => {
      Flower.flowersMap.clear();
      this._flowerLs = null;
    });
    return obs;
  }

  savePersistentRecordIds(f: Flower) {
    let obj = {};
    f._persistentRecordIds.forEach((v, k) => {
      obj[k] = v;
    });
    console.log('SAVING RECORDS PERSISTENT IDS');
    let s = this.arcs.gaeSave(`/combo_json/${f.id}/idRecords.json`,
      obj);
    s.subscribe(resp => console.log('PERSISTENT IDS SAVED'));
  }

}
