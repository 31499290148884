import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { BookmarkService } from '../bookmark.service'

@Component({
  selector: 'app-bookmark-switch-box',
  templateUrl: './bookmark-switch-box.component.html',
  styleUrls: ['./bookmark-switch-box.component.css']
})
export class BookmarkSwitchBoxComponent implements OnInit {
  @Input() flower: Flower
  @Input() isDetail = false

  constructor(private bs:BookmarkService) { }

  ngOnInit() {
  }

get isSelected() {
  return this.bs.has(this.flower)
}

  get image() {
    if (this.bs.has(this.flower))
      return "bookmarkStarSelected.png"
    return "bookmarkStar.png"
  }

  switch() {
    this.bs.switch(this.flower)
  }
}
