<div *ngIf='flower && ps.isAutoMode'>
  Parsing in auto mode: {{name}}
</div>

<div *ngIf='flower && !ps.isAutoMode && fs.initFinished'>
  <div *ngIf='utils.isAdmin'
       class='admin'>
    <button (click)='edit()'>Edit</button>
    <!-- app-upload-search [flower]='flower'></app-upload-search-->
    <!--app-process-searches [flower]='flower'></app-process-searches-->
  </div>
  <table>
    <tr class='name'>
      <th>
        Name:
      </th>
      <td>
        <span class='flowerName'>{{name}}</span>
        <app-bookmark-switch-box [flower]='flower'
                                 [isDetail]='true'></app-bookmark-switch-box>
      </td>
    </tr>
    <tr class='synonym'>
      <th>
        Synonyms:
      </th>
      <td>
        {{synonyms}}
      </td>
    </tr>
    <tr>
      <th>
        <img class='plantList'
             src='/assets/plant_list.jpg' />
      </th>
      <td class='plantList'>
        <span [innerHTML]='plantList'></span>
        <a target='_blank'
           style='margin-left: 2em'
           href='https://en.wikipedia.org/wiki/{{flower.names[0][0]}}'>
          <img style='height: 20px;'
               src='assets/wikilogo.svg'>
        </a>
        <a target='_blank'
           style='margin-left: 2em'
           href='https://identify.plantnet.org/species/the-plant-list/{{flower.names[0].join(" ")}}'>
          <img style='height: 20px'
               src='assets/plantnet-sm.png'>
        </a>
      </td>
    </tr>
    <tr>
      <th>
        <img class='plantList'
             src='/assets/kewScience.png' />
      </th>
      <td class='plantList'>
        <span [innerHTML]='kewSciencePlants'></span>
       
      </td>
    </tr>
    <tr>
      <th></th>
      <td>
        <div class='white list'
             *ngIf='flower.isWhite'>
          WHITE LIST
        </div>
        <div class='yellow list'
             *ngIf='!flower.isWhite && 
          !flower.isNovelFoods &&
          !flower.isInsect &&
          !flower.isAlgae &&
          !flower.isFungi &&
          !flower.isCompound'>
          YELLOW LIST
        </div>
        <div class='novelFoods list'
             *ngIf='flower.isNovelFoods'>
          NOVEL FOODS LIST
        </div>

        <div class='insect list'
             *ngIf='flower.isInsect'>
          INSECT LIST
        </div>

        <div class='algae list'
             *ngIf='flower.isAlgae'>ALGAE LIST</div>

        <div class='fungi list'
             *ngIf='flower.isFungi'>FUNGI LIST</div>

        <div class='compound list'
             *ngIf='flower.isCompound'>
          COMPOUND LIST
        </div>
      </td>
    </tr>
    <tr *ngIf='utils.isAdmin'>
      <th>Searches:</th>
      <td>
        <ul>
          <li *ngFor='let s of searches'>"{{s}}"</li>
        </ul>
      </td>
    </tr>

    <tr class='synonym'>
      <th>
        Family:
      </th>
      <td>
        <app-family-box [flower]='flower'></app-family-box>
      </td>
    </tr>
    <tr>
      <th>
        Database statistics:
      </th>
      <td>
        <app-flower-statistics [flower]='flower'></app-flower-statistics>
      </td>
    </tr>
    <tr>
      <th>
        Status:
      </th>
      <td>
        <app-tag-status-edit *ngFor='let s of statuses'
                             [flower]='flower'
                             [value]='s'></app-tag-status-edit>
      </td>
    </tr>
    <tr>
      <th>
        Owner:
      </th>
      <td>
        <app-editor-box [editor]='owner'
                        [flower]='flower'></app-editor-box>
      </td>
    </tr>
    <tr>
      <th>
        Assignee:
      </th>
      <td>
        <app-editor-box [editor]='assignee'
                        [flower]='flower'
                        [isAssignee]='true'></app-editor-box>
      </td>
    </tr>
    <tr>
      <th>
        Notes:
      </th>
      <td>
        <app-note-box *ngFor='let n of flowerNotes'
                      [note]='n'></app-note-box>
        <div *ngIf='showAddNoteButton'>
          <div class='noteButton'>
            <button (click)='addNote()'>Add note</button>
          </div>
        </div>
      </td>
    </tr>
  </table>

  <app-db-record-list [flower]='flower'
                      [pageLength]='50'></app-db-record-list>

</div>