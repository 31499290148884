<div class='flowerStatistics'
    *ngIf='this.flower && this.fs.searchStats'>
  Unique: {{dbHitsCounter('unique')}},

  WOS: {{dbHitsCounter('wos')}}<span class='date'>{{latestSearch('wos')}}</span>,

  Scopus: {{dbHitsCounter('scopus')}}<span class='date'>{{latestSearch('scopus')}}</span>,

  Scifinder: {{dbHitsCounter('cas')}}<span  class='date'>{{latestSearch('scifinder')}}</span>,

  Pubmed: {{dbHitsCounter('pubmed')}}<span class='date'>{{latestSearch('pubmed')}}</span>
</div>
