<div *ngIf='utils.isAdmin'>

  <app-admin-editors *ngIf='isEditors'></app-admin-editors>


  <div *ngIf='isReprocess'>
    <div>Reprocess all flowers:</div>
    <div>
      <button (click)='suggestReprocess()'>SUGGEST REPROCESS</button>
    </div>
    <div *ngIf='reprocessSTART'> PLEASE WAIT! LISTING GOOGLE DIRECTORIES   </div>
    <div *ngFor='let tpl of rpFlowers'>
      <span [class.isClicked] = 'inClickSet(tpl[1])'>{{tpl[0]}}</span> - 
      <a href='https://combo2.ecomole.com/d/x/{{tpl[1].id}}'
      target = '_blank'
      
      (click)='flClicked(tpl[1])'
      >
        {{tpl[1].mainName}}
      </a>
      <span *ngIf='warnLarge(tpl[1])' style='font-size:150%; margin-left:2em;color:red'>
        Are you sure it needs reprocessing? Very large file which would need further manual reprocessing !!!
      </span>
    </div>
    <hr />
    
     </div>

  <div *ngIf='isBackup'>
    <h1>Backup</h1>

    <div>
      <!-- div>
        <button *ngIf='!bs.backupRunning'
            (click)='bs.startBackup()'>START BACKUP</button>
      </div>
      <div>
        <button *ngIf='!bs.backupRunning'
              (click)='bs.checkBackup()'>CHECK BACKUP</button>
      </div>
      <div>
        <button *ngIf='bs.hasMissingID'
              (click)='bs.startBackup()'>RETRY MISSING FLOWERS</button>
      </div -->

      <div [innerHTML]='bs.backupMessage'>

      </div>

    </div>

  </div>

  <div *ngIf='isReset'>
    <h1>Reset</h1>

    <div>
      <button (click)='resetCriterionCache()'>RESET WEIGHTS CACHE</button>
    </div>

    <!-- div>
      <button *ngIf='!flowerResetPrepared'
          (click)='resetFlowers()'>
    Reset flowers
  </button>
      <span *ngIf='flowerResetPrepared'>
      <button (click)='cancelResetFlowers()'>Cancel Reset</button>
      <button (click)='confirmResetFlowers()'>!!! Reset flowers - No way back !!!</button>
    </span>
    </div>

  </div -->

  <div *ngIf='isUploads'>
    <h1>UPLOADS</h1>

    <div>
        <button (click)='generateUploadedFilesJSON()'>SAVE UPLOADED FILES JSON</button>
      </div>
  </div>
</div>
