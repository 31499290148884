import { Component, OnInit, Input } from '@angular/core';
// import { Flower } from '../flower'
import { PageService } from '../page.service';
import { DbRecord } from '../db-record';
import { Flower } from '../flower';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-pages-box',
  templateUrl: './pages-box.component.html',
  styleUrls: ['./pages-box.component.css']
})
export class PagesBoxComponent implements OnInit {
  // @Input() flower: Flower;
  @Input() dbRecords: DbRecord[];
  @Input() flowers: Flower[];

  constructor(
    private pgs: PageService,
    public utils: UtilsService
  ) { }

  ngOnInit() {}

  get startPageRecords() {
    let lsLen;
    let pgLen;
    if (this.dbRecords) {
      this.pgs.recordsLength = this.dbRecords.length;
      lsLen = this.pgs.recordsLength;
      pgLen = this.pgs.pageLength;
    } else if (this.flowers) {
      this.pgs.flowersLength = this.flowers.length;
      lsLen = this.pgs.flowersLength;
      pgLen = this.pgs.flowersPageLength;
    }

    let ls = [];

    for (let i = 0; i < lsLen; i += pgLen)
      ls.push(i);
    return ls;
  }

}
