import { Component, OnInit, Input } from '@angular/core';
import { DbRecord } from '../db-record'
import { Flower } from '../flower'
import { AbstractRating } from '../abstract-rating'
import { AbstractRatingService } from '../abstract-rating.service'
import { StatusService } from '../status.service'

@Component({
  selector: 'app-abstract-rating-edit',
  templateUrl: './abstract-rating-edit.component.html',
  styleUrls: ['./abstract-rating-edit.component.css']
})
export class AbstractRatingEditComponent implements OnInit {
  @Input() dbRecord: DbRecord
  @Input() flower: Flower
  private _data: Object
  public abstractRating: AbstractRating

  constructor(private ars: AbstractRatingService,
    private stats: StatusService) { }

  ngOnInit() {
    this._data = this.stats.abstractEditData
    this.abstractRating = this.ars.ownerRatings(this.flower)
  }

  get acceptanceLs() {
    return this._data['accept']
  }

  get initialObj() {
    return this._data['initial']
  }

  get rejectLs() {
    return this._data['reject']
  }
}
