import { Component, OnInit, Input } from '@angular/core';
import { DbRecord } from '../db-record'
import { CrossrefService } from '../crossref.service'
import { CrossrefRecord } from '../crossref-record'

@Component({
  selector: 'app-crossref-box',
  templateUrl: './crossref-box.component.html',
  styleUrls: ['./crossref-box.component.css']
})
export class CrossrefBoxComponent implements OnInit {
  @Input() dbRecord: DbRecord
  @Input() type: string
  @Input() issn: string
  crossrefRecordsLs = []
  running = false

  constructor(
    private cs: CrossrefService
  ) { }

  ngOnInit() {
    //console.log(this.dbRecord.data)
  }

  run() {
    this.running = true
    let tpl;
    switch (this.type) {
      case 'doi': tpl = this.cs.doiRun(this.dbRecord); break;
      case 'title': tpl = this.cs.titleRun(this.dbRecord); break;
      case 'issn': tpl = this.cs.issnRun(this.issn); break
    }
    let obs = tpl[0]
    let ls = tpl[1]
    obs.subscribe(x => {
      this.crossrefRecordsLs = ls
      this.running = false
    })
  }

  close() {
    this.crossrefRecordsLs = []
  }

}
