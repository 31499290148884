<div class='detail'
    *ngIf='current'>
  <div *ngIf='isDelete'
      class='deleteWarn'>
    <h1>THE EDITOR WILL BE DELETED</h1>
    <div>
      <button (click)='cancelDelete()'>Cancel</button>
      <button (click)='confirmDelete()'>!!! PERMANENTLY DELETE !!!</button>
    </div>
  </div>
  <table>
    <tr *ngIf='current.id != "__NEW__"'>
      <th>ID</th>
      <td>
        {{current.id}}
      </td>
    </tr>
    <tr>
      <th>Name</th>
      <td>
        <input class='data'
            [(ngModel)]='displayName' />
      </td>
    </tr>
    <tr>
      <th>Email</th>
      <td>
        <input class='data'
            [(ngModel)]='email' />
      </td>
    </tr>
    <tr>
      <th>EFSA</th>
      <td>
        <input type='checkbox'
            [checked]='isEFSAeditor'
            (click)='switchEFSA()' />
      </td>
    </tr>
  </table>
  <div>
    <button (click)='cancel()'>Cancel</button>
    <button (click)='save()'>Save</button>
    <button class='delete'
        (click)='delete()'>Delete</button>
  </div>

</div>

<div class='list'
    *ngIf='!current'>
  <div>
    <app-filter-input (changeEvent)='filter($event)'></app-filter-input>
    <button class='newEditor'
        (click)='newEditor()'>New editor</button>
  </div>

  <table>
    <tr *ngFor='let e of editors' [class.isEFSA]='e.isEFSAeditor'>
      <td>
        <span class='link'
            (click)='edit(e)'>{{e.displayName}}</span>
      </td>
      <td>
        {{e.email}}
      </td>
    </tr>
  </table>
</div>
