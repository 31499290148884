<div class='menu'
    *ngIf='utils.authenticated'>
  <a routerLink="/flowers/list"
      class='first'
      routerLinkActive="current"
      [routerLinkActiveOptions]="{ exact: true }">Flowers</a>

  <a routerLink="/documentation"
      routerLinkActive="current"
      [routerLinkActiveOptions]="{ exact: false }">Documentation</a>

  <a routerLink="/bookmarks"
      routerLinkActive="current"
      [routerLinkActiveOptions]="{ exact: false }">Bookmarks</a>
</div>
