import { DbRecord } from './db-record'
import { FulltextService } from './fulltext.service'

export class Fulltext {
  private _fileStats: Object
  private _isRunPrepared = false

  constructor(
    private fts: FulltextService,
    public dbRecord: DbRecord
  ) {
    this.dbRecord.fulltext = this
    this.fts.unresolvedMap.set(this.fulltextId, this)
  }

  get href() {
    if (!this._fileStats) {
      return ''
    }
    return `https://gae-test.ecomole.com/s/r/combo_PDF/${this._fileStats['filename'].split('/').pop()}`
  }

  get fulltextId() {
    let fid = this.dbRecord.id
    /*  if (fid.startsWith('eid='))
        fid = fid.slice(4) */
    return fid
  }

  finishDelete() {
    this.fts.hasFileMap.delete(this.fulltextId)
    this._fileStats = null
  }

  prepareRun(ls: string[]): void {
    if (this._isRunPrepared)
      return
    this._isRunPrepared = true
    ls.push(this.fulltextId)
  }

  finalizeRun(obj: Object) {
    this.fts.unresolvedMap.delete(this.fulltextId)
    this._fileStats = obj
    if (obj)
      this.fts.hasFileMap.set(this.fulltextId, this)
  }


  get filename() {
    return this._fileStats['metadata']['x-goog-meta-origfn'] || "PDF file"
  }

  get filepath() {
    return this._fileStats['filename']
  }

}
