import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../page.service'

@Component({
  selector: 'app-tag-page',
  templateUrl: './tag-page.component.html',
  styleUrls: ['./tag-page.component.css']
})
export class TagPageComponent implements OnInit {
  @Input() start: number
  @Input() isDbRecord: boolean

  constructor(private pgs: PageService) { }

  ngOnInit() {
  }

  get label() {
    let pgLen
    let totalLen
    if (this.isDbRecord) {
      pgLen = this.pgs.pageLength
      totalLen = this.pgs.recordsLength
    } else {
      pgLen = this.pgs.flowersPageLength
      totalLen = this.pgs.flowersLength
    }
    let end = this.start + pgLen
    if (end > totalLen)
      end = totalLen
    return `${this.start + 1}-${end}`
  }

  switch() {
    this.pgs.currentStart = this.start
  }

  get isSelected() {
    return this.pgs.currentStart == this.start
  }
}
