<button *ngIf='!isExpanded'
    (click)='switch()'>
    Upload search
  </button>

<!-- div *ngIf='isExpanded'
    class='main'>
  <div>
    <button (click)="switch()">Cancel</button>
    <input type="file"
        ng2FileSelect
        [uploader]="uploader" />
  </div>

  <div *ngIf='hasFileSelected'>
    {{searchData.saveFilename}}
    <button (click)='upload()'>Upload</button>
    <div>
      <app-tag-upload-search *ngFor='let s of searches'
          tp='searchTerm'
          [searchData]='searchData'
          [value]='s'></app-tag-upload-search>
    </div>
    <div>
      <app-tag-upload-search *ngFor='let db of databases'
          tp='database'
          [searchData]='searchData'
          [value]='db'></app-tag-upload-search>
    </div>
  </div>

</div -->
