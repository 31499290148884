<div *ngIf='hasFulltextFile && !deleteMode'>
  <a target='_blank'
     [href]='dbRecord.fulltext.href'>
    Open Fulltext
  </a>
  <button (click)='deleteFulltextSwitch()'>
    Delete Fulltext
  </button>
</div>

<div *ngIf='hasFulltextFile && deleteMode'>
  <button (click)='deleteConfirmed()'>DELETE FILE</button>
  <button (click)='deleteFulltextSwitch()'>
    Cancel
  </button>
</div>

<div class='buttons'
     *ngIf='!hasFulltextFile'>
  <button (click)='upload()'>Upload file</button>

  <ng-container *ngIf='isAdmin && fileSuggestion'>
    <span class='doiSuggestion'
          *ngIf='fileSuggestion == "wait"'>retrieving suggestion</span>

    <span class='doiSuggestion'
          *ngIf='fileSuggestion == "pdf_not_found"'>no suggestion</span>


    <span class='doiSuggestion'
          *ngIf='!dbRecord.doi'>no doi</span>

    <ng-container *ngIf='fileSuggestion == "pdf_exists"'>
      <a [href]='doiSuggestionLink'
         class='doiSuggestion'
         (click)='suggestedActivated()'
         target='_blank'>suggested file</a>

      <button mat-raised-button
              style='margin-left: 2em'
              [disabled]='!isUploadSuggestedActivated'
              (click)='uploadSuggested()'>
        Upload suggested file
      </button>
    </ng-container>
  </ng-container>
</div>