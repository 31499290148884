import { Component, OnInit } from '@angular/core';
import { BookmarkService } from '../bookmark.service'

@Component({
  selector: 'app-bookmark-dashboard',
  templateUrl: './bookmark-dashboard.component.html',
  styleUrls: ['./bookmark-dashboard.component.css']
})
export class BookmarkDashboardComponent implements OnInit {

  constructor(private bs: BookmarkService) { }

  ngOnInit() {
  }

  get flowers() {
    let ls = this.bs.flowers()
    return ls
  }

}
