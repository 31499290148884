<div class='message'>


<h1>Unknown email: {{email}}</h1>

<p>
  Only people with authorized emails can access the database.
  </p>

  <p>
    Please contact Krystof to be aded to the database.
  </p>

</div>
