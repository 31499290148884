import { Injectable } from '@angular/core';
import { ArcService } from './arc.service'
import { DbRecord } from './db-record'
import { Flower } from './flower'
import { Criterion } from './criterion'
import { CriterionService } from './criterion.service'

@Injectable()
export class DbRecordService {
  recordsReady = new Set<Flower>()
  constructor(
    private arcs: ArcService,
    private cs: CriterionService
  ) { }

  fromObject(obj: string) {
    let dbr = new DbRecord(this, obj['recordId'])
    dbr.setData(obj)
    return dbr
  }

  get(recordId: string, flower: Flower) {
    return flower._dbRecordsMap.get(recordId)
  }

  create(recordId: string, flower: Flower) {
    return new DbRecord(this, recordId, flower)
  }

  resetCriterionCache() {
    let cacheObs = this.arcs.run({
      fun: 'resetDbRecordsCriterionCache',
      criterion: "criterion_A",
    });
    cacheObs.subscribe((resp) => console.log(resp));
    return cacheObs;
  }

  setMainCriterion(c: Criterion) {
    DbRecord.mainCriterion = c;
  }

  finalizeId(txt) {
    if (!txt)
      return txt;
    let i = txt.indexOf('(');
    if (i > -1) {
      txt = txt.slice(0, i).trim();
    }
    txt = txt.replace(/ /g, '_');
    return txt;
  }

}
