import { Injectable, } from '@angular/core';
import { Router } from '@angular/router'
import { Flower } from './flower'
import { DbRecord } from './db-record'
import { ArcService } from './arc.service'
import { PageService } from './page.service'

@Injectable()
export class PermalinkService {


  constructor(
    private arcs: ArcService,
    private router: Router,
    private pgs: PageService
  ) { }

  createFlowerRecordLink(flower: Flower, dbRecord: DbRecord) {
    return this._createLink({
      tp: "flowerDbRecord",
      flower: flower.id,
      dbRecord: dbRecord.id
    })
  }


  private _createLink(payload: Object) {
    let obs = this.arcs.run({
      fun: 'createPermanentLink',
      payload: payload
    })
    return obs
  }

  resolve(code: string) {
    let obs = this.arcs.run({
      fun: 'resolvePermanentLink',
      code: code.trim()
    })
    obs.subscribe((x) => {
      let params = x[0]
      console.log(135, params)
      if (params['tp'] == "flowerDbRecord")
        this.resolveFlowerDbRecord(params)
    })
    return obs
  }

  resolveFlowerDbRecord(params) {
    this.pgs.targetDbRecordId = params['dbRecord']
    this.router.navigate(['flowers', 'display', params['flower']])
  }

  checkHash() {
    let h = (window.location.hash || '#').slice(1);
    window.location.hash = ""
    if (h)
      this.resolve(h)
  }

}
