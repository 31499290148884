<span *ngIf='!checkStatus'></span>
<div class="main"
    *ngIf='checkStatus'
    [class.isEFSAeditor]='isEFSAeditor'
    [class.accepted]="isAccepted"
    [class.rejected]="isRejected"
    [class.isOwner]="isOwner">
  <div>
    <span class='editor' *ngIf='abstractRating'>
      <app-editor-box [editor]='abstractRating.editor'></app-editor-box>
      {{stamp}} :
    </span>

    <span class='status'>{{status}}</span> /
    <span class='title'>{{title}}</span>
  </div>
  <div class='subtitle'
      *ngIf='subtitle'>
    {{subtitle}}
  </div>
  <div *ngIf='utils.isAdmin' class='removeDiv'>
    <span *ngIf='!removeMode'>
      <button (click)='removeRatingSwitch()'>Admin: remove rating</button>
    </span>
    <span *ngIf='removeMode'>
      <button (click)='removeRatingSwitch()'>Admin: CANCEL remove rating</button>
      <button (click)='removeConfirmed()'>Admin: CONFIRM remove rating</button>
    </span>
  </div>
</div>
