<div class='flowerBox'>
  <span class='order'>{{order}}</span>
  <span class='flowerName'
        (click)='display()'>{{name}}</span>
  <img *ngIf='flower.hasCodedData'
       src='/assets/database.jpeg'
       style='height: 24px; margin-left: 2em'>
  <span class='synonyms'
        *ngIf='synonyms'>{{synonyms}}</span>
  <app-bookmark-switch-box [flower]='flower'></app-bookmark-switch-box>

  <div>
    <div class='white list'
         *ngIf='flower.isWhite'>WHITE LIST</div>

    <div class='yellow list'
         *ngIf='!flower.isWhite &&
     !flower.isNovelFoods && 
     !flower.isInsect &&
     !flower.isAlgae &&
     !flower.isFungi &&
     !flower.isCompound
     '>YELLOW
      LIST</div>

    <div class='novelFoods list'
         *ngIf='flower.isNovelFoods'>NOVEL FOODS LIST</div>

    <div class='insect list'
         *ngIf='flower.isInsect'>INSECT LIST</div>

    <div class='algae list'
         *ngIf='flower.isAlgae'>ALGAE LIST</div>

    <div class='fungi list'
         *ngIf='flower.isFungi'>FUNGI LIST</div>

    <div class='compound list'
         *ngIf='flower.isCompound'>COMPOUND LIST</div>
  </div>

  <app-flower-statistics [flower]='flower'></app-flower-statistics>
  <div *ngIf='!isSearchQueue'>
    <div class='meta'>
      <span class='owner'>
        <b>Owner: </b>
        <app-editor-box [editor]='owner'
                        [flower]='flower'></app-editor-box>
      </span>
      <span class='owner'>
        <b>Assignee: </b>
        <app-editor-box [editor]='assignee'
                        [flower]='flower'
                        [isAssignee]='true'></app-editor-box>
      </span>
      <span class='family'>
        <b>Family: </b>
        <app-family-box [flower]='flower'></app-family-box>
      </span>

    </div>
    <div class='status'>
      <b>Status: </b> <!-- {{status}} -->

      <app-tag-status-edit *ngFor='let s of ss.flowerStatusValues'
                           [flower]='flower'
                           [value]='s'></app-tag-status-edit>

      <a target='_blank'
         [href]='href'>
        <img style='width:24px; margin-left: 1em'
             src='/assets/link.png'>
      </a>
    </div>
  </div>
  <div *ngIf='utils.isAdmin'
       class='admin'>
    <span *ngIf='!isSearchQueue'>
      <button (click)='edit()'>Edit</button>
      <!-- app-upload-search [flower]='flower'></app-upload-search-->
    </span>
    <app-search-queue-button [flower]='flower'></app-search-queue-button>

    <!--app-process-searches [flower]='flower'></app-process-searches-->
  </div>
</div>