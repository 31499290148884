import { Criterion } from './criterion'
import { DbRecord } from './db-record'
import { Flower } from './flower';

export class CriterionItem {
  id: string
  weight: number = 1
  regexp: RegExp
  valuesMap = new Map<string, number>()

  constructor(private criterion: Criterion,
    txt: string,
    weight?: number) {
    txt = txt.trim()
    this.id = txt
    this.regexp = new RegExp(txt, 'i')
    if (weight)
      this.weight = weight
    this.criterion.items.set(this.id, this)
  }

  useWeight(flower: Flower): number {
    if (flower.isNovelFoods) {
      switch (this.regexp.source) {
        case 'allerg': return 1500;
      }
    }
    return this.weight;
  }

  run(dbr: DbRecord, flower: Flower) {
    // const weight = this.useWeight(flower);

    // let x = this.valuesMap.get(dbr.id)
    // if (!isNaN(x))
    //   return x
    // let val = 0
    // if (this.regexp.test(dbr.normalizedTitle)) {
    //   dbr.criterionItemMatches.push(this)
    //   val = 1.000001 * weight
    // }
    // let abs = dbr.normalizedAbstract
    // if (abs && this.regexp.test(abs)) {
    //   if (!val)
    //     dbr.criterionItemMatches.push(this)
    //   val += weight
    // }
    // if (dbr.data['type'] == 'Patent')
    //   val -= 50000
    // this.valuesMap.set(dbr.id, val)
    // return val
  }
}
