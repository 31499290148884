import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { FlowerService } from '../flower.service'
import { DbRecord } from '../db-record'
import { DbRecordService } from '../db-record.service'
import { FulltextService } from '../fulltext.service'
import { PageService } from '../page.service'
import { StatusService } from '../status.service'
import { SortService } from '../sort.service'
import { BookmarkService } from '../bookmark.service'
import { PermalinkService } from '../permalink.service';

@Component({
  selector: 'app-db-record-list',
  templateUrl: './db-record-list.component.html',
  styleUrls: ['./db-record-list.component.css']
})
export class DbRecordListComponent implements OnInit {
  @Input() flower: Flower;
  @Input() pageLength: number;
  isCodedOn = false;

  persistentIdsSet = new Set<number>();

  //private _dbRecordsIds: string[] = [];

  constructor(
    public fs: FlowerService,
    private drs: DbRecordService,
    private fts: FulltextService,
    public pgs: PageService,
    private ss: StatusService,
    private bs: BookmarkService,
    private ps: PermalinkService,
    private sort: SortService
  ) {
  }

  ngOnInit() {
    this.pgs.pageLength = this.pageLength;
    this.pgs.top50switch();
  }

  ngOnDestroy() {
    this.pgs.clear();
  }

  clearPersistentIdsSet() {
    this.persistentIdsSet = new Set<number>();
  }

  switchEFSAratingsOnly() {
    this.fs.showEFSAratingsOnly = !this.fs.showEFSAratingsOnly;
    this.fs.showEFSAratingsExclude = false;
  }

  switchEFSAratingsExclude() {
    this.fs.showEFSAratingsOnly = false;
    this.fs.showEFSAratingsExclude = !this.fs.showEFSAratingsExclude;
  }

  switchExtractAbstractOnly() {
    this.fs.showExtractAbstractOnly = !this.fs.showExtractAbstractOnly;
  }

  get isPersistentIdSearch() {
    return !!this.persistentIdsSet.size;
  }

  filterChange(txt) {
    txt = txt.trim();
    if (!txt) {
      return this.pgs.searchFilter = null;
    }
    this.pgs.searchFilter = new RegExp(txt, 'i');
  }

  authorChange(txt) {
    txt = txt.trim();
    if (!txt) {
      return this.pgs.authorFilter = null;
    }
    this.pgs.authorFilter = new RegExp(txt, 'i');
  }


  persistentIdChange(txt) {
    this.persistentIdsSet = new Set<number>(txt
      .split(/\D+/).filter(x => x).map(x => parseInt(x)));
  }

  trackByDbRecord(index: number, dbRecord: DbRecord) {
    return dbRecord.id;
  }

  get bookmarkEditors() {
    let ls = [];
    this.bs.flowerBookmarkEditors(this.flower).forEach((tpl) => {
      ls.push({
        label: `${tpl[0].displayName} (${tpl[1]})`,
        id: tpl[0].id
      })
    });
    return ls;
  }

  get ready() {

    return this.flower.isReady;
  }

  get languages() {
    let ls = Array.from(this.flower.langSet);
    ls.sort((a, b) => a < b ? -1 : 1);
    return ls.map(x => { return { label: x, id: x }; });
  }

  private _abrLs = ['_accepted', '_rejected', 'not_rated', '_mismatch'];
  get abstractRatingValues() {
    let s = this.flower.abstractRatingsSummary;
    let ls = [];
    this._abrLs.forEach((x) => {
      if (!s[x])
        return;
      switch (x) {
        case '_accepted': ls.push(['accepted', "Accepted", s[x]]); break;
        case '_rejected': ls.push(['rejected', "Rejected", s[x]]); break;
        case 'not_rated': ls.push(['notrated', "Not rated", s[x]]); break;
        case '_mismatch': ls.push(['mismatch', "Mismatch", s[x]]); break;
      }
    });
    return ls;
  }

  private _fullLs = ['process', 'reject', 'undecided', '_mismatch'];
  get fulltextRatingValues() {
    let s = this.flower.fulltextRatingsSummary;
    let ls = [];
    this._fullLs.forEach((x) => {
      if (!s[x])
        return;
      switch (x) {
        case 'process': ls.push([x, "Process", s[x]]); break;
        case 'reject': ls.push([x, "Reject", s[x]]); break;
        case 'undecided': ls.push([x, "Undecided", s[x]]); break;
        case '_mismatch': ls.push(['ft_mismatch', "Mismatch", s[x]]); break;
      }
    });
    return ls;
  }

  persistentIdFilter(ls: DbRecord[]) {
    let finalLs = [];
    ls.forEach(x => {
      if (this.persistentIdsSet.has(this.flower.getPersistentRecordId(x)))
        finalLs.push(x);
    });
    return finalLs;
  }

  filter(ls: DbRecord[]) {
    if (this.fs.showExtractAbstractOnly) {
      ls = ls.filter(x => this.flower.isAbstractExtract(x))
    }

    let bookmarkedRecords = this.bs.bookmarkedRecords(this.flower);
    let hasLang = this.pgs.langInclude.size || this.pgs.langExclude.size;
    if (!this.pgs.currentFilter &&
      !this.pgs.authorFilter &&
      !bookmarkedRecords.size &&
      !this.pgs.searchFilter &&
      !hasLang) {
      return ls;
    }

    if (bookmarkedRecords.size)
      ls = ls.filter((x) => bookmarkedRecords.has(x.id));

    if (
      !this.pgs.searchFilter &&
      !this.pgs.authorFilter &&
      !this.pgs.currentFilter &&
      !hasLang) {
      return ls;
    }
    let finalLs = [];

    if (this.pgs.currentFilter)
      ls.forEach((dbr) => {
        if (this.flower.hasRating(dbr, this.pgs.currentFilter))
          finalLs.push(dbr);
      });
    else
      finalLs = ls;


    if (this.pgs.langInclude.size)
      finalLs = finalLs.filter((x) => this.pgs.langInclude.has(x.lang));

    if (this.pgs.langExclude.size)
      finalLs = finalLs.filter((x) => !this.pgs.langExclude.has(x.lang));

    if (this.pgs.authorFilter) {
      finalLs = finalLs.filter((x) => x.authorHit(this.pgs.authorFilter));
    }

    if (!this.pgs.searchFilter)
      return finalLs;

    return finalLs.filter((x) => x.regexpHit(this.pgs.searchFilter));
  }


  private _dbRecords: DbRecord[];

  private _filterIsRunning = false;
  private _recordsCache;

  get dbRecords() {

    if (this._filterIsRunning)
      return this._recordsCache;
    this._filterIsRunning = true;
    let ls = this.isPersistentIdSearch ?
      this.persistentIdFilter(this.flower._dbRecordsCriterionOrder.slice(0)) :
      this.filter(this.flower.dbRecords);


    if (this.isCodedOn) {
      ls = ls.filter(x => this.flower.getOpinion(x));
    }
    
    this._recordsCache = this.sort.run(ls);

    this._filterIsRunning = false;
    return this._recordsCache;
  }

  get dbRecordsPage() {
    let ls = this.pgs.getPage(this.dbRecords);
    this.fts.checkRecords(ls);
    return ls;
  }

  get sortFields() {
    let ls = [
      { tp: "mainCriterion", name: "Criterion A" },
      { tp: "dateDesc", name: "Date&#8615;" },
      { tp: "dateAsc", name: "Date&#8613;" }
      /*    { tp: "source", name: "Source" } */
    ];
    return ls;
  }

}
