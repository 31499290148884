import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../utils.service'
import { EditorService } from '../editor.service'

@Component({
  selector: 'app-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.css']
})
export class LoginBoxComponent implements OnInit {

  constructor(public utils: UtilsService,
    private es: EditorService) { }

  ngOnInit() {
  }

  get myself() {
    return this.es.currentEditor
  }

  get email() {
    if (!this.utils.email) {
      return '';
    }
    let tpl = this.utils.email.split('@')
    return `<div>${tpl[0]}</div><div>@${tpl[1]}</div>`
  }
}
