import { Component, OnInit, Input } from '@angular/core';
import { CriterionItem } from '../criterion-item'
import { PageService } from '../page.service'
import { Flower} from '../flower';

@Component({
  selector: 'app-tag-criterion-item',
  templateUrl: './tag-criterion-item.component.html',
  styleUrls: ['./tag-criterion-item.component.css']
})
export class TagCriterionItemComponent implements OnInit {
  @Input() criterionItem: CriterionItem;
  @Input() flower: Flower;

  constructor(private pgs: PageService) { }

  ngOnInit() {
  }

  get label() {
    return `${this.criterionItem.regexp.source}<sup>${this.criterionItem.useWeight(this.flower)}</sup>`
  }

  get isSelected() {
    return this.pgs.isOnCriterionItem(this.criterionItem)
  }

  get isGlobalSelected() {
    return this.pgs.isOnAllCriterionMatch
  }

  switch() {
    this.pgs.switchCriterionItem(this.criterionItem)
  }

}
