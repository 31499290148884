import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FilesizePipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if (value > 99999)
      return `${Math.round(value / 100000)/10} MB`;
    if (value > 999)
      return `${Math.round(value / 100)/10} kB`;
    return `${value} B`;
  }

}
