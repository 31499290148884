import { Flower } from './flower'
import { Editor } from './editor'
import { DbRecord } from './db-record'
import { FulltextRatingService } from './fulltext-rating.service'

type Rating = [string, Set<string>]
export type saveRating = [string, string[]]

export class FulltextRating {
  ratings = new Map<string, Rating>()

  constructor(
    private frs: FulltextRatingService,
    public flower: Flower,
    public editor: Editor) {
    this.flower.fulltextRatings.push(this)
  }

  setItemRating(dbRecord: DbRecord, rating: Rating, notSave?: boolean) {
    this.ratings.set(dbRecord.id, rating)
    if (!notSave)
      this.save(dbRecord, rating)
    return rating
  }

  setOtherRating(dbRecordId: string, rating: Rating) { // when record removed from searches, may be useful when it appears again
    this.ratings.set(dbRecordId, rating)
  }

  setItemReason(dbRecord: DbRecord, reason: string) {
    let r = this.getItemRating(dbRecord)
    if (!r)
      r = this.setItemRating(dbRecord, ['undecided', new Set<string>()], true)
    if (!r[1].delete(reason))
      r[1].add(reason)
    this.save(dbRecord, r)
  }

  adminRemove(dbRecord: DbRecord) {
    this.setItemRating(dbRecord, ['undecided', new Set<string>()])
  }

  getItemRating(dbRecord: DbRecord) {
    return this.ratings.get(dbRecord.id)
  }

  save(dbRecord: DbRecord, rating: Rating) {
    let ls: saveRating = [rating[0], Array.from(rating[1].values())]
    this.frs.save(this, dbRecord, ls)
  }

  get ratedItems() {
    let ls = []
    this.ratings.forEach((v, k) => {
      if (v[0] != 'undecided')
        ls.push(k)
    })
    return ls
  }
}
