import { Component, OnInit, Input } from '@angular/core';
// import { FileUploader} from 'ng2-file-upload/ng2-file-upload';
import { Flower } from '../flower'
import { UploadSearchService } from '../upload-search.service'
import { UploadSearchData } from '../upload-search-data'
import { ArcService } from '../arc.service'

@Component({
  selector: 'app-upload-search',
  templateUrl: './upload-search.component.html',
  styleUrls: ['./upload-search.component.css']
})
export class UploadSearchComponent implements OnInit {
  @Input() flower: Flower
  isExpanded = false

  private URL = `${location.protocol}//gae-test.ecomole.com/a2/upload`;
  // private _uploader: FileUploader = new FileUploader({ url: this.URL });
  private _currentFilename: string
  private searchData: UploadSearchData

  constructor(
    private uss: UploadSearchService,
    private arcs: ArcService
  ) { }

  ngOnInit() {
    /*
    this._uploader.onSuccessItem = (...rest) => {
      let gaePath = JSON.parse(rest[1])['pth']
      console.log("gaePath:", gaePath)
      let obs = this.arcs.run({
        fun: 'finalizeSearchDownload',
        tmpPath: gaePath,
        flower: this.flower.id,
        filename: this.searchData.saveFilename
      })
      obs.subscribe((res) => this.switch())
    }
    */
  }

  switch() {
    /*
    if (this.isExpanded) {
      this._currentFilename = undefined
      this.searchData = undefined
      if (this.uploader.queue.length)
        this.uploader.queue[0].remove()
    }

    this.isExpanded = !this.isExpanded
    */
  }

  upload() {
    // this.uploader.queue[0].upload()
  }

  get uploader() {
    
    return /* this._uploader */
  }

  get filename() {
    return // this.uploader.queue[0].file.name
  }

  get hasFileSelected() {
    return /*
    let hasFile = !!this.uploader.queue.length
    if (hasFile && this._currentFilename != this.filename)
      this.prepareData()
    return hasFile
    */
  }

  prepareData() { /*
    this._currentFilename = this.filename
    this.searchData = this.uss.prepareData(this.flower, this.filename)
  */ }

  get searches() {
    return this.searchData.searches
  }

  get databases() {
    return ['WOS', 'Scopus', 'Scifinder', 'Pubmed']
  }

}
