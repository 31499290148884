import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms'
import { FlowerService } from '../flower.service'
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.css']
})
export class FilterInputComponent implements OnInit {
  @Output() changeEvent = new EventEmitter<string>()
  @Input() isPersistentRecordID = false
  valueControl = new FormControl();

  constructor(
    private fs: FlowerService
  ) { }

  ngOnInit() {
    this.valueControl.valueChanges.pipe(
      map((v) => v.trim()),
      debounceTime(400),
      distinctUntilChanged())
      .subscribe((txt) => this.changeEvent.emit(txt))
    if (this.isPersistentRecordID && this.fs.hash && this.fs.hash.charAt(0) === 'R') {
      this.valueControl.setValue(this.fs.hash);
      this.fs.hash = undefined;
    }
  }

}
