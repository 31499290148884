import { Injectable } from '@angular/core';
import { ArcService } from './arc.service';
import { AuthService } from './auth.service'
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

@Injectable()
export class UtilsService {
  uniqueIdentifiersLs: string[];
  
  constructor(private arcs: ArcService,
    private auths: AuthService, 
    private router: Router) {
    arcs.utils = this
  }


  saveExport(data, filename, isRaw?) {
    var hiddenElement = document.createElement('a');
    if (!isRaw) {
      data = JSON.stringify(data);
    }

    hiddenElement.href = URL.createObjectURL(new Blob([data], { type: 'text/plain' }));
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
    hiddenElement.remove()
  }

  exportFlowers(ls, isCSV?) {
    if (isCSV) {
      this.saveExport(ls.map(x => x.csvData).join('\n'),
        `flowers_${ls.length}.csv`, true);
    } else {
      this.saveExport(ls.map(x => x.jsonData),
        `flowers_${ls.length}.json`);
    }
  }

  get uniqueIdentifier() {
    if (!this.uniqueIdentifiersLs || this.uniqueIdentifiersLs.length < 5) {
      let r = this.arcs.run({
        fun: 'generateUniqueIdentifiers',
      });
      r.subscribe((ls) => this.uniqueIdentifiersLs = ls.pop());
      if (!this.uniqueIdentifiersLs)
        return null;
    }
    return this.uniqueIdentifiersLs.pop();
  }

  get email() {
    return this.auths.email;
  }

  get isAdmin() {
    return this.auths && this.auths.isAdmin;
  }

  get isAssigneeBoss() {
    if (!this.auths) {
      return false;
    }
    return (this.auths.email === 'bernardbottex@gmail.com') || this.auths.email === 'slodekwahlj@gmail.com';
  }

  get authenticated() {
    if (!this.auths) {
      return false;
    }
    return this.auths.loggedIn;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isAdmin) { return true; }
    this.router.navigate(['/']);
    return false;
  }

  login() {
    this.auths.login();
  }

  logout() {
    this.auths.logout();
  }
}
