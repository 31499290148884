import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FlowerService } from './flower.service';
import { ArcService } from './arc.service';

@Injectable({
  providedIn: 'root'
})
export class MixinService {

  constructor(
    auths: AuthService,
    flowers: FlowerService,
    arcs: ArcService
  ) { 
    auths.flowers = flowers;
    auths.arcs = arcs;
  }
}
