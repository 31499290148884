import { Component, OnInit } from '@angular/core';
import { PermalinkService } from '../permalink.service'

@Component({
  selector: 'app-permalink-resolve-box',
  templateUrl: './permalink-resolve-box.component.html',
  styleUrls: ['./permalink-resolve-box.component.css']
})
export class PermalinkResolveBoxComponent implements OnInit {
  code: string


  constructor(private ps: PermalinkService) { }

  ngOnInit() {
  }

  run() {
    this.ps.resolve(this.code)
  }

}
