class Author {
  family: string
  given: string

  constructor(obj) {
    this.family = obj['family']
    this.given = obj['given']
  }

  cite() {
    return `${this.family}, ${this.given}`
  }
}

export class CrossrefRecord {
  doi: string
  isbn: string[]
  issn: [string, string][] = []
  authors: Author[]
  title: string
  source: string
  page: string
  volume: string
  issue: string
  year: number
  type: string
  publisher: string

  constructor(obj) {
    //console.log(123, obj)
    this.doi = obj['DOI']
    this.isbn = (obj['ISBN'] || []).map(x => x.split('/').pop())
    if (obj['ISSN']) {
      let issnDct = {}
      obj['issn-type'].forEach(x => issnDct[x['value']] = x['type'])
      obj['ISSN'].forEach(x => this.issn.push([x, issnDct[x]]))
    }


    this.authors = (obj['author'] || []).map(x => new Author(x))
    this.title = obj['title'] && obj['title'][0]
    this.source = obj['container-title'] && obj['container-title'][0]
    this.page = obj['page']
    this.volume = obj['volume']
    this.issue = obj['issue']
    this.year = this.getYear(obj)
    this.type = obj['type']
    this.publisher = obj['publisher']
  }


  get authorsCitation() {
    let ls = []
    this.authors.forEach(x => ls.push(x.cite()))
    return ls.join('; ')
  }

  getYear(obj) {
    let publishedPrint = obj['published-print'] &&
      obj['published-print']['date-parts'][0][0]
    let publishedOnline = obj['published-online'] &&
      obj['published-online']['date-parts'][0][0]

    return publishedOnline || publishedPrint
  }
}
