import { Injectable } from '@angular/core';
import { RecordsCollection } from './records-collection'
import { Flower } from './flower'
import { ArcService } from './arc.service'
import { CriterionService } from './criterion.service'
import { DbRecordService } from './db-record.service'
import { UtilsService } from './utils.service'

@Injectable()
export class RecordsCollectionService {
  _processingMessage: string
  constructor(
    private arcs: ArcService,
    private cs: CriterionService,
    private dbrs: DbRecordService,
    private utils: UtilsService
  ) { }

  newCollection(flower: Flower) {
    return new RecordsCollection(this, flower, this.utils)
  }

  export(rc: RecordsCollection,
    payload: Object,
    filename: string,
    extras?: Object
  ) {
    return this.arcs.gaeSave(`/combo_json/${rc.flower.id}/${filename}`,
      payload,
      extras
    )
  }

  get processingMessage() {
    if (this._processingMessage)
      return this._processingMessage
    return '&nbsp;'
  }

  get criterion() {
    return this.cs.mainCriterion
  }

  dbRecord(obj) {
    return this.dbrs.fromObject(obj)
  }
}
