import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { SortService } from '../sort.service'

@Component({
  selector: 'app-tag-sort',
  templateUrl: './tag-sort.component.html',
  styleUrls: ['./tag-sort.component.css']
})
export class TagSortComponent implements OnInit {
  @Input() flower: Flower
  @Input() tp: string
  @Input() name: string
  @Input() id: string

  constructor(private ss: SortService) { }

  ngOnInit() {
  }

  get number() {
    let n = this.ss.position(this.tp) + 1
    if (n)
      return `${n}. `
    return ''
  }

  get isPrimary() {
    return this.ss.position(this.tp) == 0
  }

  get isSecondary() {
    return this.ss.position(this.tp) > 0

  }

  switch(event) {
    this.ss.switch(this.tp, event.ctrlKey);
  }


}
