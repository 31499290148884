import { Component, OnInit, Input, ElementRef } from '@angular/core'
import { Flower } from '../flower'
import { DbRecord } from '../db-record'

import { PageService } from '../page.service'
import { UtilsService } from '../utils.service'
import { EditorService } from '../editor.service'
import { NoteService } from '../note.service'
import { FlowerService } from '../flower.service';
import { Criterion } from '../criterion'
import { CriterionService } from '../criterion.service'

@Component({
  selector: 'app-db-record-box',
  templateUrl: './db-record-box.component.html',
  styleUrls: ['./db-record-box.component.css']
})
export class DbRecordBoxComponent implements OnInit {
  @Input() dbRecord: DbRecord;
  @Input() flower: Flower;
  @Input() order: number;
  persistentRecordId: number;
  private _showPersistentLink = false;

  displayFullDuplicates = false;
  noteMode = false;
  note = '';

  constructor(
    private fs: FlowerService,
    private elr: ElementRef,
    public utils: UtilsService,
    private es: EditorService,
    private ns: NoteService,
    private pgs: PageService) { }

  ngOnInit() {
    if (this.pgs.targetDbRecordId === this.dbRecord.id) {
      this.jumpTop();
      this.pgs.targetDbRecordId = null;
    }
    this.persistentRecordId = this.flower.getPersistentRecordId(this.dbRecord);
  }

  get opinion() {
    return this.flower.getOpinion(this.dbRecord);
  }

  get opinionText() {
    const o = this.opinion;
    if (!o) {
      return '&mdash;';
    }
    const ls = [];
    Object.keys(o).forEach(k => {
      const v = o[k];
      if (v) {
        ls.push(`<b>${k}:</b> ${v}`);
      }
    });
    return ls.join('; ');
  }

  persistentLink() {
    return `${location.protocol}//${location.host}${location.pathname}#R${this.persistentRecordId}`;
  }


  switchPersistentLink() {
    this._showPersistentLink = !this._showPersistentLink;
  }

  get isPersistentLink() {
    return this._showPersistentLink;
  }

  jumpHome() {
    window.scrollTo(0, 0);
  }

  jumpTop() {
    let el = this.elr.nativeElement;
    window.scrollTo(el.offsetLeft, el.offsetTop);
  }

  jumpNext() {
    let nextSibling = this.elr.nativeElement.nextSibling;
    window.scrollTo(nextSibling.offsetLeft, nextSibling.offsetTop);
  }

  jumpPrev() {
    let prevSibling = this.elr.nativeElement.previousSibling;
    window.scrollTo(prevSibling.offsetLeft, prevSibling.offsetTop);
  }

  /*
  goToDatabase() {
    this.fs.goToDatabase(this.flower, this.dbRecord);
  }
  */

  sendReferenceData() {
    this.fs.sendReferenceData(this.flower, this.dbRecord);
  }

  get criterionOrder() {
    //  if (!this._criterionAorder)
    //    this._criterionAorder = this.flower.dbRecordCriterionOrder(this.dbRecord)
    return this.dbRecord.criterionPosition;
  }

  get criterionName() {
    return  CriterionService.criterionName
  }

  get isEditor() {
    return !!this.es.currentEditor;
  }

  get showAddNoteButton() {
    return !this.ns.getOwnerNote(this.flower, this.dbRecord);
  }

  addNote() {
    this.ns.addNote(this.flower, '', this.dbRecord, true);
  }


  get loggedEmail() {
    return this.utils.email;
  }

  get criterionItemMatches() {
    return this.dbRecord.criterionItemMatches;
  }

  private _acceptedSet = new Set(['must_see', 'must_see_composition', 'if_available']);
  get isAcceptedAbstract() {
    for (let i = 0; i < this.abstractRatings.length; i++)
      if (this._acceptedSet.has(this.abstractRatings[i].getItemRating(this.dbRecord)))
        return true;
    return false;
  }

  get search() {
    let searchLs = [];
    let doi = this.dbRecord.data['doi'];
    if (doi)
      searchLs.push(`<a href="http://scholar.google.com/scholar?q=${encodeURIComponent(doi)}" target="_blank">Scholar for DOI</a>`);
    searchLs.push(`<a href="http://scholar.google.com/scholar?q=${encodeURIComponent(`"${this.rawTitle}"`)}" target="_blank">Scholar for title</a>`);
    if (doi)
      searchLs.push(`<a href="http://google.com/?q=${encodeURIComponent(doi)}" target="_blank">Google for DOI</a>`);
    searchLs.push(`<a href="http://google.com/?q=${encodeURIComponent(`"${this.rawTitle}"`)}" target="_blank">Google for title</a>`);

    if (doi)
      searchLs.push(`<br><br><a href="https://www.researchgate.net/search.Search.html?query=${encodeURIComponent(doi)}" target="_blank">ResearchGate for DOI</a>`);
    searchLs.push(`<a href="https://www.researchgate.net/search.Search.html?query=${encodeURIComponent(`"${this.rawTitle}"`)}" target="_blank">ResearchGate  for title</a>`);

    return searchLs.join(' | ');
  }



  get abstractRatings() {
    return this.flower.abstractRatings; // it will be sorted later to distinquish  correctly
  }

  get fulltextRatings() {
    return this.flower.fulltextRatings; // it will be sorted later to distinquish  correctly
  }

  get criterionSum() {
    return Math.floor(this.dbRecord.mainCriterionValue * -1);
  }

  compoundHits(txt) {
    if (!this.pgs._allCriterionMatchState)
      return txt;
    if (!this.dbRecord.compoundHits)
      return txt;
    this.dbRecord.compoundHits.forEach(rg =>
      txt = txt.replace(rg[0], `<span class="compoundHit type${rg[1]}">$&</span>`));
    return txt;
  }

  flowerNameHits(txt) {
    if (!this.flower._nameRegexps.length) {
      this.flower.names.forEach((x) => {
        if (typeof x === 'string')
          x = [x];
        let tpl = x[0].split(' ');
        tpl[0] = tpl[0].charAt(0) + '\\.';
        this.flower._nameRegexps.push(new RegExp(x[0].replace(' ', '\\s+'), 'ig'));
        this.flower._nameRegexps.push(new RegExp(tpl.join('\\s+'), 'ig'));
      });
    }
    this.flower._nameRegexps.forEach((x) => {
      txt = txt.replace(x, '<span class="flowerNameHit">$&</span>');
    });
    return txt;
  }

  get rawTitle() {
    return (this.dbRecord.data['title'] || '').replace(/[\[\]]/g, ' ');
  }

  get title() {
    return this.compoundHits(this.flowerNameHits
      (this.pgs.criterionHits(this.rawTitle,
        this.dbRecord.criterionItemMatches)));
  }

  get abstract() {
    let abstract = this.dbRecord.data['abstract'];
    if (!abstract)
      return '&mdash;';
    return this.compoundHits(this.flowerNameHits(this.pgs.criterionHits(abstract,
      this.dbRecord.criterionItemMatches)));
  }

  get source() {
    return this.dbRecord.shortCitation;
  }

  get volume() {
    return this.dbRecord.data['volume'];
  }

  get startPage() {
    console.log(this.dbRecord);
    return this.dbRecord.data['startPage'];
  }


  get year() {
    return this.dbRecord.data['year'];
  }

  get doi() {
    let doi = this.dbRecord.data['doi'];
    if (!doi)
      return null;
    return `<a href="https://doi.org/${doi}" target='_blank'>${doi}</a>`;
  }

  get authors() {
    let txt = (this.dbRecord.data['authors'] || []).join('; ');
    if (this.pgs.authorFilter) {
      txt = txt.replace(this.pgs.authorFilter, '<span class="searchHit">$&</span>');
    }
    return txt;
  }

  get notes() {
    let ls = [];
    this.flower.notes.forEach((x) => {
      let fn = x.itemNote(this.dbRecord);
      if (fn && (fn.text.trim() || fn.editor === this.es.currentEditor))
        ls.push(fn);
    });
    return ls;
  }


  // private _duplicatesLs: DbRecord[]
  get duplicates() {
    return [];
    /*
    if (this._duplicatesLs)
      return this._duplicatesLs
    this._duplicatesLs = []
    try {
      this.dbRecord.duplicatesSet.forEach((x) => this._duplicatesLs.push(x))
    } catch (e) { }
    return this._duplicatesLs */
  }

  switchDuplicateDisplay() {
    this.displayFullDuplicates = !this.displayFullDuplicates;
  }
}
