import { Flower } from './flower'

export class UploadSearchData {
  static suffixes = {
    WOS: 'wos',
    Scopus: 'ris',
    Scifinder: 'ris',
    Pubmed: 'txt'
  }

  searches: string[] = []
  currentSearch: string
  currentDatabase: string = "WOS"

  constructor(public flower: Flower, public filename: string) {
    this.flower.searches.forEach((x) => this.searches.push(x.toLowerCase()))
    this.currentSearch = this.searches[0]
    switch (filename.split('.').pop()) {
      case 'ris':
        this.currentDatabase = 'Scopus'; break;
      case 'txt':
        this.currentDatabase = 'Pubmed'; break;
    }
  }

  get saveFilename() {
    let d = new Date()
    let dt = `${d.getDate()}_${d.getMonth()}_${d.getFullYear()}`
    return `${this.currentSearch.replace(/ /g, '_')}-${this.currentDatabase.toLowerCase()}-${dt}.${UploadSearchData.suffixes[this.currentDatabase]}`
  }
}
