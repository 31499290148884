<div class='pagination'>
  <app-tag-misc *ngIf='dbRecords' tp='top50' [checkbox]='true' label='TOP 50 HITS'></app-tag-misc>

  <app-tag-misc *ngIf='dbRecords' tp='top100' [checkbox]='true' label='TOP 100 HITS'></app-tag-misc>

  <app-tag-page *ngFor='let i of startPageRecords' [start]='i' [isDbRecord]='!!dbRecords'>
  </app-tag-page>

  <button *ngIf='utils.isAdmin  && !dbRecords' (click)='utils.exportFlowers(flowers)'>Export flowers</button>
  <button *ngIf='utils.isAdmin && !dbRecords' (click)='utils.exportFlowers(flowers, true)'>Export flowers - CSV</button>
</div>
