import { Component, OnInit, Input } from '@angular/core';
import { Note } from '../note'
import { NoteService } from '../note.service'
import { EditorService } from '../editor.service'
import { UtilsService } from '../utils.service'
import { DbRecord } from '../db-record'

@Component({
  selector: 'app-note-box',
  templateUrl: './note-box.component.html',
  styleUrls: ['./note-box.component.css']
})
export class NoteBoxComponent implements OnInit {
  @Input() note: Note
  @Input() dbRecord: DbRecord

  isOwner: boolean
  isEditMode = false
  isRemoveMode = false
  editText: string

  constructor(
    private es: EditorService,
    private utils: UtilsService,
    private ns: NoteService
  ) {

  }

  ngOnInit() {
    // console.log(4444, this.note)
    this.isOwner = this.note.editor === this.es.getEditor(this.utils.email)
    if (this.note === this.ns.editModeNote) {
      this.ns.editModeNote = null
      this.isEditMode = true
    }
  }

  get isDbRecordNote() {
    return !!this.dbRecord
  }

  get text() {
    return this.note.text.replace(/\n/g, '<br>')
  }

  get date() {
    if (!this.note.date)
      return ""
    return this.note.date.toUTCString()
  }

  get editor() {
    return this.note.editor
  }

  switchEditMode() {
    this.isEditMode = !this.isEditMode
    if (this.isEditMode)
      this.editText = this.note.text
  }

  switchRemoveMode() {
    this.isRemoveMode = !this.isRemoveMode
  }


  save() {
    let obs = this.ns.addNote(this.note.parent.flower, this.editText, this.dbRecord)
    obs.subscribe((x) => {
      this.isEditMode = false
    })
  }

  remove() {
    let obs = this.ns.removeNote(this.note.parent.flower, this.dbRecord)
    obs.subscribe((x) => {
      this.isRemoveMode = false
    })
  }

}
