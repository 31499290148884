import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { CrossrefRecordBoxComponent } from './crossref-record-box/crossref-record-box.component';
import { CrossrefBoxComponent } from './crossref-box/crossref-box.component';
import { SearchQueueButtonComponent } from './search-queue-button/search-queue-button.component';
import { UnknownUserComponent } from './unknown-user/unknown-user.component';
import { AdminEditorsComponent } from './admin-editors/admin-editors.component';
import { PermalinkResolveBoxComponent } from './permalink-resolve-box/permalink-resolve-box.component';
import { PermalinkBoxComponent } from './permalink-box/permalink-box.component';
import { BookmarkSwitchDbRecordBoxComponent } from './bookmark-switch-db-record-box/bookmark-switch-db-record-box.component';
import { ProcessSearchesComponent } from './process-searches/process-searches.component';
import { TagUploadSearchComponent } from './tag-upload-search/tag-upload-search.component';
import { UploadSearchComponent } from './upload-search/upload-search.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { LoginBoxComponent } from './login-box/login-box.component';
import { FilesizePipe } from './filesize.pipe';
import { UploadFulltextComponent } from './upload-fulltext/upload-fulltext.component';
import { TagStatusEditComponent } from './tag-status-edit/tag-status-edit.component';
import { TagFulltextRatingComponent } from './tag-fulltext-rating/tag-fulltext-rating.component';
import { FulltextRatingEditComponent } from './fulltext-rating-edit/fulltext-rating-edit.component';
import { TagAbstractRatingComponent } from './tag-abstract-rating/tag-abstract-rating.component';
import { AbstractRatingEditComponent } from './abstract-rating-edit/abstract-rating-edit.component';
import { FamilyBoxComponent } from './family-box/family-box.component';
import { TagStatusComponent } from './tag-status/tag-status.component';
import { TagEditorComponent } from './tag-editor/tag-editor.component';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { TagMiscComponent } from './tag-misc/tag-misc.component';
import { TagCriterionItemComponent } from './tag-criterion-item/tag-criterion-item.component';
import { TagPageComponent } from './tag-page/tag-page.component';
import { PagesBoxComponent } from './pages-box/pages-box.component';
import { TagSortComponent } from './tag-sort/tag-sort.component';
import { NoteBoxComponent } from './note-box/note-box.component';
import { FulltextRatingBoxComponent } from './fulltext-rating-box/fulltext-rating-box.component';
import { BookmarkSwitchBoxComponent } from './bookmark-switch-box/bookmark-switch-box.component';
import { BookmarkDashboardComponent } from './bookmark-dashboard/bookmark-dashboard.component';
import { EditorBoxComponent } from './editor-box/editor-box.component';
import { AbstractRatingBoxComponent } from './abstract-rating-box/abstract-rating-box.component';
import { FlowerStatisticsComponent } from './flower-statistics/flower-statistics.component';
import { DbRecordListComponent } from './db-record-list/db-record-list.component';
import { DbRecordBoxComponent } from './db-record-box/db-record-box.component';
import { FlowerDisplayComponent } from './flower-display/flower-display.component';
import { FilterInputComponent } from './filter-input/filter-input.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { FlowerBoxComponent } from './flower-box/flower-box.component';
import { SearchEditBoxComponent } from './search-edit-box/search-edit-box.component';
import { FlowerNameEditBoxComponent } from './flower-name-edit-box/flower-name-edit-box.component';
import { FlowerEditComponent } from './flower-edit/flower-edit.component';
import { FlowerListComponent } from './flower-list/flower-list.component';
import { DocumentationDashboardComponent } from './documentation-dashboard/documentation-dashboard.component';
import { FlowerDashboardComponent } from './flower-dashboard/flower-dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './auth.service';
import { FlowerService } from './flower.service';
import { UtilsService } from './utils.service';
import { BookmarkService } from './bookmark.service';
import { ArcService } from './arc.service';
import { EditorService } from './editor.service';
import { FilterService } from './filter.service';
import { StatusService } from './status.service';
import { CriterionService } from './criterion.service';
import { NoteService } from './note.service';
import { PageService } from './page.service';
import { FamilyService } from './family.service';
import { DbRecordService } from './db-record.service';
import { AbstractRatingService } from './abstract-rating.service';
import { FulltextRatingService } from './fulltext-rating.service';
import { FulltextService } from './fulltext.service';
import { UploadSearchService } from './upload-search.service';
import { PermalinkService } from './permalink.service';
import { ParserService } from './parser.service';
import { RecordsCollectionService } from './records-collection.service';
import { SortService } from './sort.service';
import { SearchQueueService } from './search-queue.service';
import { BackupService } from './backup.service';
import { CompoundHitsService } from './compound-hits.service';
import { CrossrefService } from './crossref.service';
import { CallbackComponent } from './callback/callback.component';
import { MixinService } from './mixin.service';

@NgModule({
  declarations: [
    AppComponent,
    FlowerDashboardComponent,
    DocumentationDashboardComponent,
    FlowerListComponent,
    FlowerEditComponent,
    FlowerNameEditBoxComponent,
    SearchEditBoxComponent,
    FlowerBoxComponent,
    AdminDashboardComponent,
    FilterInputComponent,
    FlowerDisplayComponent,
    DbRecordBoxComponent,
    DbRecordListComponent,
    FlowerStatisticsComponent,
    AbstractRatingBoxComponent,
    EditorBoxComponent,
    BookmarkDashboardComponent,
    BookmarkSwitchBoxComponent,
    FulltextRatingBoxComponent,
    NoteBoxComponent,
    TagSortComponent,
    PagesBoxComponent,
    TagPageComponent,
    TagCriterionItemComponent,
    TagMiscComponent,
    TagFilterComponent,
    TagEditorComponent,
    TagStatusComponent,
    FamilyBoxComponent,
    AbstractRatingEditComponent,
    TagAbstractRatingComponent,
    FulltextRatingEditComponent,
    TagFulltextRatingComponent,
    TagStatusEditComponent,
    UploadFulltextComponent,
    FilesizePipe,
    LoginBoxComponent,
    AdminPageComponent,
    MainMenuComponent,
    UploadSearchComponent,
    TagUploadSearchComponent,
    ProcessSearchesComponent,
    BookmarkSwitchDbRecordBoxComponent,
    PermalinkBoxComponent,
    PermalinkResolveBoxComponent,
    AdminEditorsComponent,
    UnknownUserComponent,
    SearchQueueButtonComponent,
    CrossrefBoxComponent,
    CrossrefRecordBoxComponent,
    CallbackComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    AuthService,
    FlowerService,
    UtilsService,
    BookmarkService,
    ArcService,
    EditorService,
    FilterService,
    StatusService,
    CriterionService,
    NoteService,
    PageService,
    FamilyService,
    DbRecordService,
    AbstractRatingService,
    FulltextRatingService,
    FulltextService,
    UploadSearchService,
    PermalinkService,
    ParserService,
    RecordsCollectionService,
    SortService,
    SearchQueueService,
    BackupService,
    CompoundHitsService,
    CrossrefService,
    MixinService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
