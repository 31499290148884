<div class='main'>
  <h1>Combo Documentation Library</h1>
  <h2>Chemicals of concern</h2>
  <div>
    <a target="_blank"
      href="https://docs.google.com/spreadsheets/d/1BjW26Qw-3i9VqptIiUy3g0c1kV3-6bO0yxy-uNKPwIw/edit#gid=1662066119">List
      of substances/groups of concern</a>
  </div>
  <div>
    <a target="_blank"
      href="https://docs.google.com/spreadsheets/d/1eztI6LVTnB4W5PwA2HgmZ9h-cTiVetmX9W7E5S9I7g8/edit#gid=0">List of
      substances/groups of no concern</a>
  </div>
  <div>
    <a target="_blank"
      href="https://docs.google.com/spreadsheets/d/1cQZTWWiwu-7rwhXjRxBmBz89sAUVwCHFOP1M_IkfX1Q/edit#gid=0">Newly
      identified chemicals of concern to be added to EFSA database (PARAM)</a>
  </div>
  <h2>Yellow list assignment</h2>
  <div>
    <a target="_blank"
      href="https://docs.google.com/spreadsheets/d/1SM3lXNiHEC44PTKNl6zq65QJ8a6derHmnCTGi38hI58/edit#gid=416617110">No
      info plants + Yellow species assignment</a>
  </div>
  <h2>Guidance document and Q&amp;A</h2>
  <div>
    <a target="_blank"
      href="https://docs.google.com/document/d/1z2IZceraMM1mwLG4YgxEuOO-a1JzMF27oTyp485_Nws/edit">Guidance</a>
  </div>
  <div>
    <a target="_blank"
      href="https://docs.google.com/spreadsheets/d/1I9KwfdnZRzWBaTfdILlXpYUeQy3j2Uu-DFPlhreO9CY/edit#gid=785501206">Combo
      Q&amp;A</a>
  </div>

  <h2>Data Checks (plant names and substances)</h2>

  <div>
    <a target="_blank"
      href="https://drive.google.com/drive/u/0/folders/1xTRqvhMJroE7gizIpZp_-gy5fj_yusrI">Plant names vs. MTX</a>
  </div>

  <div>
    <a target="_blank"
      href="https://drive.google.com/drive/u/0/folders/1paiO8rj_tlDZNyIHFrkYi0Txa9UhF0v7">Substance names vs. PARAM</a>
  </div>

  <h2>Prague</h2>

  <div>
    <a target="_blank"
      href="https://docs.google.com/spreadsheets/d/1fGRPyFemBVEN6PJTFokGAcebpx1Pq99lGl-ezus3uWg/edit#gid=589032276">White list assignment -
      Prague</a>
  </div>
</div>
