<span>
  <span *ngIf='!isEditMode'>
    <span *ngIf='editor' class='editor'>
        {{editor.displayName}}
    </span>
<span *ngIf='!editor'>&mdash;</span>
</span>
<span *ngIf='flower && (utils.isAdmin ||
     (utils.isAssigneeBoss && isAssignee))'>
    <button
       *ngIf='!isEditMode'
       (click)='switchEdit()'>Edit</button>
          <br *ngIf='isEditMode'/>
         <span *ngIf='isEditMode'>
           <span
           *ngFor='let e of editors'
           class='choice'
           >
           <label>
             <input name='editor'
             (click)='assignFlower(e)'
             type='radio'
             [checked] = 'e === editor'
             >
              {{e.displayName}}
           </label>
           </span>
<span *ngIf='isEditMode'>
             <br/>
             <button
                 (click)='switchEdit()'>Cancel</button>
                 <button
                     (click)='assignFlower()'>
                     <span *ngIf='isAssignee'>Remove assignee</span>
                     <span *ngIf='!isAssignee'>Remove owner</span>
                   </button>
                 <br/>
           </span>

</span>

</span>

</span>
