import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../page.service'

@Component({
  selector: 'app-tag-status',
  templateUrl: './tag-status.component.html',
  styleUrls: ['./tag-status.component.css']
})
export class TagStatusComponent implements OnInit {
  @Input() value: string
  constructor(private pgs: PageService) { }

  ngOnInit() {
  }

  switch() {
    this.pgs.setFlowerStatus(this.value)
  }

  get isSelected() {
    return this.pgs.isFlowerStatusFilter(this.value)
  }

  get label() {
    switch (this.value) {
      case 'Prague': return 'For extraction';
      case 'Postponed': return 'Final check';
      default: return this.value;
    }
  }
}
