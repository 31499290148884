<h1 *ngIf='!fs.initFinished'>
  DOWNLOADING FLOWERS &hellip;
</h1>

<div *ngIf='fs.initFinished'>
  <table>
    <tr>
      <th>Name filter:</th>
      <td>
        <div class='names filter'>
          <app-filter-input (changeEvent)='filterChange($event)'></app-filter-input>
          <app-tag-misc tp='sortFlowersByUnique'></app-tag-misc>
        </div>
      </td>
    </tr>
    <tr>
      <th>Owners:</th>
      <td>
        <div class='owners filter'>
          <app-tag-editor *ngFor='let owner of es.ownersLs; let i = index;'
                          tp='ownerFilter'
                          [i]='i'
                          [editor]='owner'></app-tag-editor>
        </div>
      </td>
    </tr>
    <tr>
      <th>Assignees:</th>
      <td>
        <div class='owners filter'>
          <app-tag-editor *ngFor='let assignee of es.assigneesLs; let i = index;'
                          tp='assigneeFilter'
                          [i]='i'
                          [editor]='assignee'></app-tag-editor>
        </div>
      </td>
    </tr>
    <tr>
      <th>Status:</th>
      <td>
        <div class='statusFilters filter'>
          <b></b>
          <app-tag-status *ngFor='let s of statuses'
                          [value]='s'></app-tag-status>
        </div>
      </td>
    </tr>
    <tr>
      <th>Families:</th>
      <td>
        <div class='families filter'>
          <span *ngIf='showFamilies'>
            <button (click)='switchShowFamilies()'>Hide families</button>
            <app-tag-misc *ngFor='let tpl of families'
                          tp='familyFilter'
                          [value]='tpl[0]'
                          [label]='tpl[1]'>
            </app-tag-misc>
            <button (click)='switchShowFamilies()'>Hide families</button>
          </span>
          <span *ngIf='!showFamilies'>
            <button (click)='switchShowFamilies()'>Show families</button>
            <span *ngIf='familyFilterValue'>
              Family: {{familyFilterValue}}
              <button (click)='clearFamily()'>clear</button>
            </span>
          </span>
        </div>

      </td>
    </tr>
    <tr>
      <th>Coded</th>
      <td>
        <div>
          <label>
            <input type='radio'
                   name='codedList'
                   value='all'
                   [(ngModel)]='codedCheck'>
            all
          </label>

          <label>
            <input type='radio'
                   name='codedList'
                   value='yes'
                   [(ngModel)]='codedCheck'>
            yes
          </label>

          <label>
            <input type='radio'
                   name='codedList'
                   value='no'
                   [(ngModel)]='codedCheck'>
            no
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <th>Lists</th>
      <td>
        <div>
          <label>
            <input type='radio'
                   name='rblist'
                   value='all'
                   [(ngModel)]='listCheck'>
            all
          </label>

          <label>
            <input type='radio'
                   name='rblist'
                   value='white'
                   [(ngModel)]='listCheck'>
            white
          </label>

          <label>
            <input type='radio'
                   name='rblist'
                   value='yellow'
                   [(ngModel)]='listCheck'>
            yellow
          </label>

          <label>
            <input type='radio'
                   name='rblist'
                   value='novelFoods'
                   [(ngModel)]='listCheck'>
            novel foods
          </label>
        </div>
        <div>
          <label>
            <input type='radio'
                   name='rblist'
                   value='insect'
                   [(ngModel)]='listCheck'>
            insect
          </label>

          <label>
            <input type='radio'
                   name='rblist'
                   value='algae'
                   [(ngModel)]='listCheck'>
            algae
          </label>

          <label>
            <input type='radio'
                   name='rblist'
                   value='fungi'
                   [(ngModel)]='listCheck'>
            fungi
          </label>

          <label>
            <input type='radio'
                   name='rblist'
                   value='compound'
                   [(ngModel)]='listCheck'>
            compound
          </label>
        </div>
      </td>
    </tr>
    <tr *ngIf='utils.isAdmin'>
      <th>DB check:</th>
      <td>
        <label>
          <input type='checkbox'
                 [checked]='isNoSearch("scopus")'
                 (click)='switchNoSearch("scopus")'>
          No Scopus
        </label>
        &nbsp;
        &nbsp;
        <label>
          <input type='checkbox'
                 [checked]='isNoSearch("cas")'
                 (click)='switchNoSearch("cas")'>
          No Scifinder
        </label>

        &nbsp;
        &nbsp;

        <label>
          <input type='checkbox'
                 [(ngModel)]='isNoOwner'>
          No Owner
        </label>

        &nbsp;
        &nbsp;
      </td>
    </tr>
  </table>




  <app-pages-box [flowers]='filteredList'></app-pages-box>

  <app-flower-box *ngFor='let fl of list; let i = index'
                  [flower]='fl'
                  [order]='i+1+pgs.currentStart'>
  </app-flower-box>

  <app-pages-box [flowers]='filteredList'></app-pages-box>
</div>