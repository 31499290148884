<span>
  <span *ngIf='!isEditMode'>{{label}}</span>
  <span  *ngIf='utils.isAdmin'>
    <button
       *ngIf='!isEditMode'
       (click)='switchEdit()'>Edit family</button>
       <span *ngIf='isEditMode'>
         <input [(ngModel)]='newFamily'/>
         <button (click)='switchEdit()'>Cancel</button>
         <button (click)='submitFamily()'>Submit</button>
       </span>
 </span>

</span>
