import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { BookmarkService } from '../bookmark.service'
import { DbRecord } from '../db-record'

@Component({
  selector: 'app-bookmark-switch-db-record-box',
  templateUrl: './bookmark-switch-db-record-box.component.html',
  styleUrls: ['./bookmark-switch-db-record-box.component.css']
})
export class BookmarkSwitchDbRecordBoxComponent implements OnInit {
  @Input() flower: Flower
  @Input() dbRecord: DbRecord

  constructor(private bs: BookmarkService) { }

  ngOnInit() {
  }

  get isSelected() {
    return this.bs.hasDbRecord(this.flower, this.dbRecord)
  }
  
  switch() {
    this.bs.switchDbRecord(this.flower, this.dbRecord)
  }

}
