import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { StatusService } from '../status.service'

@Component({
  selector: 'app-tag-status-edit',
  templateUrl: './tag-status-edit.component.html',
  styleUrls: ['./tag-status-edit.component.css']
})
export class TagStatusEditComponent implements OnInit {
  @Input() value: string
  @Input() flower: Flower

  constructor(private ss: StatusService) { }

  ngOnInit() {
  }

  switch() {
    this.ss.setFlowerStatus(this.flower, this.value)
  }

  get isSelected() {
    return this.ss.flowerStatus(this.flower) == this.value
  }

  get label() {
    switch (this.value) {
      case 'Prague': return 'For extraction';
      case 'Postponed': return 'Final check';
      default: return this.value;
    }
  }
}
