import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { FamilyService } from '../family.service'
import { UtilsService } from '../utils.service'


@Component({
  selector: 'app-family-box',
  templateUrl: './family-box.component.html',
  styleUrls: ['./family-box.component.css']
})
export class FamilyBoxComponent implements OnInit {
  @Input() flower: Flower
  isEditMode = false
  newFamily = ''

  constructor(
    private fmls: FamilyService,
    public utils: UtilsService
  ) { }

  ngOnInit() {

  }

  get label() {
    return this.fmls.familyForFlower(this.flower) || '???'
  }

  switchEdit() {
    this.isEditMode = !this.isEditMode
    if (this.isEditMode)
      this.newFamily = this.fmls.familyForFlower(this.flower) || ''
  }

  submitFamily() {
    let obs = this.fmls.setFamily(this.flower, this.newFamily.trim())
    obs.subscribe(resp => this.switchEdit())
  }

}
