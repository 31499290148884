<div *ngIf='flower'>
  <h1 *ngIf='isNewFlower'>New flower</h1>
  <h1 *ngIf='!isNewFlower'>Edit flower: {{flower.mainName[0]}}</h1>

  <div class='delete'>
    <button *ngIf='!deleteFlowerPrepared'
        (click)='deleteFlower()'>Delete flower</button>
    <span *ngIf='deleteFlowerPrepared'>
    <button (click)='cancelDelete()'>Cancel Delete</button>
    <button (click)='confirmDelete()'>!!! Delete flower - No way back !!!</button>
  </span>
  </div>

  <table>
    <tr>
      <th>isWhiteList</th>
      <td>
        <input type='checkbox' [(ngModel)]='isWhite'/>
      </td>
    </tr>
    <tr>
      <th>isNovelFoodsList</th>
      <td>
        <input type='checkbox' [(ngModel)]='isNovelFoods'/>
      </td>
    </tr>
    <tr>
      <th>isInsectList</th>
      <td>
        <input type='checkbox' [(ngModel)]='isInsect'/>
      </td>
    </tr>
    <tr>
      <th>isAlgaeList</th>
      <td>
        <input type='checkbox' [(ngModel)]='isAlgae'/>
      </td>
    </tr>
    <tr>
      <th>isFungiList</th>
      <td>
        <input type='checkbox' [(ngModel)]='isFungi'/>
      </td>
    </tr>
    <tr>
      <th>isCompoundList</th>
      <td>
        <input type='checkbox' [(ngModel)]='isCompound'/>
      </td>
    </tr>
    <tr>
      <th>Names:</th>
      <td class='names'>
        <div *ngFor='let obj of names; let i = index'>
          <app-flower-name-edit-box [obj]='obj'
              ></app-flower-name-edit-box>
          <button (click)='asSearch(i)'>as search</button>
        </div>
        <div>
          <button (click)='newSynonym()'>New synonym</button>
        </div>
      </td>
    </tr>
    <tr>
      <th>Searches:</th>
      <td class='names'>
        <div *ngFor='let obj of searches'>
          <app-search-edit-box [obj]='obj'
              ></app-search-edit-box>
        </div>
        <div>
          <button (click)='newSearch()'>New search</button>
        </div>
      </td>
    </tr>
    <tr>
      <th>Comment:</th>
      <td>
        <textarea [(ngModel)]='comment'></textarea>
      </td>
    </tr>
  </table>
  <div>
    <button (click) = 'cancel()'>Cancel</button>
    <button (click)='save()'>Save flower</button>
  </div>
  <div *ngIf='fs.isSavingFlower' class='savingFlower'>
    SAVING FLOWER
  </div>
</div>
