import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { DbRecord } from '../db-record'
import { PermalinkService } from '../permalink.service'


@Component({
  selector: 'app-permalink-box',
  templateUrl: './permalink-box.component.html',
  styleUrls: ['./permalink-box.component.css']
})
export class PermalinkBoxComponent implements OnInit {
  @Input() dbRecord: DbRecord
  @Input() flower: Flower
  permalink: string

  constructor(private ps: PermalinkService) { }

  ngOnInit() {
  }

  createLink() {
    this.ps.createFlowerRecordLink(this.flower, this.dbRecord).subscribe(
      (x) => {
        this.permalink = x
      })
  }

  get permalinkHREF() {
    return `${location.protocol}//${location.host}/#${this.permalink}`
  }

}
