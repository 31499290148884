import { Component, OnInit, Input } from '@angular/core';
import { Editor } from '../editor'
import { Flower } from '../flower'
import { UtilsService } from '../utils.service'
import { EditorService } from '../editor.service'

@Component({
  selector: 'app-editor-box',
  templateUrl: './editor-box.component.html',
  styleUrls: ['./editor-box.component.css']
})
export class EditorBoxComponent implements OnInit {
  @Input() editor: Editor
  @Input() flower: Flower
  @Input() isAssignee: boolean
  isEditMode = false


  constructor(
    public utils: UtilsService,
    private es: EditorService
  ) { }

  ngOnInit() {
    //console.log(this.editor)
  }

  switchEdit() {
    this.isEditMode = !this.isEditMode
  }

  get editors() {
    return this.es.list.filter(e => this.isAssignee ? e.isEFSAeditor : !e.isEFSAeditor)
  }

  assignFlower(e?: Editor) {
      let obs = this.es.assignFlower(this.flower, e, this.isAssignee)
      obs.subscribe(x => this.isEditMode = false)
  }

}
