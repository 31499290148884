import { Injectable } from '@angular/core';
import { Flower } from './flower'
import { FlowerService } from './flower.service'
import { ArcService } from './arc.service'
import { DbRecord } from './db-record'
import { EditorService } from './editor.service'
import { Editor } from './editor'

@Injectable()
export class BookmarkService {
  private _flowers = new Set<string>()
  private _dbRecords = new Map<string, Set<string>>()
  private _dbRecordsOthers = new Map<Editor, Map<string, Set<string>>>()
  private _dbRecordSelectedEditor: string;
  fs: FlowerService

  constructor(
    private arcs: ArcService,
    private es: EditorService
  ) { }

  init(ls: string[]) {
    this._flowers = new Set<string>(ls)
  }

  switchSelectedEditor(val: string) {
    if (this._dbRecordSelectedEditor == val)
      this._dbRecordSelectedEditor = null
    else
      this._dbRecordSelectedEditor = val
  }

  isSelectedEditor(val: string) {
    return this._dbRecordSelectedEditor == val
  }

  bookmarkedRecords(flower: Flower) {
    if (!this._dbRecordSelectedEditor)
      return new Set<string>()
    if (this.es.currentEditor && this.es.currentEditor.id == this._dbRecordSelectedEditor)
      return this._dbRecords.get(flower.id)
    return this._dbRecordsOthers
      .get(this.es.getEditor(this._dbRecordSelectedEditor))
      .get(flower.id)
  }

  initDbRecords(flower: Flower, ls) {
    ls.forEach((tpl) => {
      let e = this.es.getEditor(tpl[0])
      if (!e)
        return
      if (e === this.es.currentEditor)
        this._dbRecords.set(flower.id, new Set<string>(tpl[1]))
      else {
        let m = this._dbRecordsOthers.get(e)
        if (!m) {
          m = new Map<string, Set<string>>()
          this._dbRecordsOthers.set(e, m)
        }
        m.set(flower.id, new Set<string>(tpl[1]))
      }
    })
  }

  flowerBookmarkEditors(flower: Flower) {
    let ls = []
    let myBookmarks = this._dbRecords.get(flower.id)
    if (myBookmarks && myBookmarks.size)
      ls.push([this.es.currentEditor, myBookmarks.size])
    this._dbRecordsOthers.forEach((m, e) => {
      let ebs = m.get(flower.id)
      if (ebs && ebs.size)
        ls.push([e, ebs.size])
    })
    return ls
  }

  has(flower: Flower) {
    return this._flowers.has(flower.id)
  }

  hasDbRecord(flower: Flower, dbRecord: DbRecord) {
    let sss = this._dbRecords.get(flower.id)
    if (!sss)
      return false
    return sss.has(dbRecord.id)
  }

  switch(flower: Flower) {
    let a = this._flowers.delete(flower.id)
    if (!a)
      this._flowers.add(flower.id)
    this.save()
  }

  switchDbRecord(flower: Flower, dbRecord: DbRecord) {
    let sss = this._dbRecords.get(flower.id)
    if (!sss) {
      sss = new Set<string>()
      this._dbRecords.set(flower.id, sss)
      sss.add(dbRecord.id)
    } else {
      let a = sss.delete(dbRecord.id)
      if (!a)
        sss.add(dbRecord.id)
    }
    this.saveDbRecord(flower)
  }


  flowers() {
    let ls = []
    this._flowers.forEach((x) => {
      let f = this.fs.get(x)
      if (f)
        ls.push(f)
    })
    return ls
  }

  saveDbRecord(flower: Flower) {
    let sss = this._dbRecords.get(flower.id)
    let ls = []
    this._dbRecords.get(flower.id).forEach((x) => ls.push(x))
    let obs = this.arcs.run({
      fun: 'saveDbRecordBookmarks',
      flower: flower.id,
      editor: this.es.currentEditor.id,
      payload: ls
    })
    obs.subscribe(
      (x) => console.log("DbRecordBookmarks saved", x)
    )
  }

  save() {
    let ls = []
    this._flowers.forEach((x) => ls.push(x))
    let obs = this.arcs.run({
      fun: 'saveBookmarks',
      payload: ls
    })

    obs.subscribe(
      (x) => console.log("Bookmarks saved", x)
    )
  }

}
