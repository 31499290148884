import {EditorService} from './editor.service'

export class Editor {
  static editorsMap = new Map<string, Editor>()

  id: string;
  email: string
  displayName: string
  isAbstractRater: boolean
  isDetailsFiller: boolean
  isFulltextRater: boolean
  isFulltextUploader: boolean
  isWorker: boolean
  isEFSAeditor: boolean

  constructor(private es: EditorService,
    dct: Object) {
    this.id = dct['id']
    this.displayName = dct['displayName']
    this.email = dct['email']
    this.isAbstractRater = dct['isAbstractRater']
    this.isDetailsFiller = dct['isDetailsFiller']
    this.isFulltextRater = dct['isFulltextRater']
    this.isFulltextUploader = dct['isFulltextUploader']
    this.isWorker = dct['isWorker']
    this.isEFSAeditor = dct['isEFSAeditor'] || false
    this.es.switchEFSAeditor(this)
    Editor.editorsMap.set(this.id, this)
  }


  change(obj: Object) {
    this.email = obj['email']
    if (this.id == '__NEW__') {
      Editor.editorsMap.delete(this.id)
      this.id = this.email
      Editor.editorsMap.set(this.id, this)
    }
    this.displayName = obj['displayName']
    this.isEFSAeditor = obj['isEFSAeditor']
    this.es.switchEFSAeditor(this)
    this.es.save(this)
  }

  delete() {
    Editor.editorsMap.delete(this.id)
    if (this.id != '__NEW__')
      this.es.delete(this)
  }

  get saveParams() {
    return {
      id:this.id,
      displayName:this.displayName,
      email: this.email,
      isEFSAeditor: this.isEFSAeditor,
      isAbstractRater: true,
      isDetailsFiller: true,
      isFulltextRater: true,
      isFulltextUploader: true,
      isWorker: true
    }
  }

}
