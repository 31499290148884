// tslint:disable:curly
import { FlowerService } from './flower.service';
import { DbRecord } from './db-record';
import { AbstractRating } from './abstract-rating';
import { FulltextRating } from './fulltext-rating';
import { FlowerNotes } from './flower-notes';
import { Editor } from './editor';


export class Flower {
  public static flowersMap = new Map<string, Flower>();
  keepReferencesSet = new Set<string>();
  fs: FlowerService;
  id: string;
  isWhite: boolean;
  isNovelFoods: boolean;
  isInsect: boolean;
  isAlgae: boolean;
  isFungi: boolean;
  isCompound: boolean;
  opinions = new Map<string, Object>();
  // family: string
  names: Array<Array<string>>;
  _nameRegexps: RegExp[] = [];
  searches: string[];
  comment: string;
  _dbRecordsMap = new Map<string, DbRecord>();
  _dbRecordsCriterionOrder: DbRecord[] = [];

  langSet = new Set<string>();
  _persistentRecordIds = new Map<string, number>();
  private _lastPersistentRecordId: number;
  private _abstractExtractIdsSet: Set<string>;

  dbHitsCounter: Object = {};
  latestSearchDates: Object = {};
  searchDates: Array<Array<string>>;

  abstractRatings: AbstractRating[] = [];
  fulltextRatings: FulltextRating[] = [];
  notes: FlowerNotes[] = [];
  // private _lastDbRecordsHash = '';
  dbRecordCriterionCache: any[][];
  isReady = false;
  combo1id: string;
  hash: string;


  constructor(fs: FlowerService, dct: Object) {
    this.fs = fs;
    this.id = dct['id'];

    let f = Flower.flowersMap.get(this.id);
    if (f)
      return f;

    this.isWhite = !!dct['isWhite'];
    this.isNovelFoods = !!dct['isNovelFoods'];
    this.isInsect = !!dct['isInsect'];
    this.isAlgae = !!dct['isAlgae'];
    this.isFungi = !!dct['isFungi'];
    this.isCompound = !!dct['isCompound'];
    this.combo1id = dct['combo1Id'];
    // this.family = dct['family']
    this.names = dct['names'] || [];
    this.searches = dct['searches'] || [];
    if (!this.names.length)
      this.searches.forEach(x => this.names.push([x, '']));
    this.comment = dct['comment'] || '';
    Flower.flowersMap.set(this.id, this);

    // this.updateStats()
  }

  get hasCodedData() {
    return this.fs.botanicalStatus[this.id] || false;
  }

  getOpinion(dbr: DbRecord) {
    return this.opinions.get(dbr.id);
  }

  initKeepReferences(abstracts, fulltexts) {
    [abstracts || {}, fulltexts || {}].forEach(y => {
      Object.keys(y).forEach(k => {
        if (k.startsWith('_')) {
          return;
        }
        Object.keys(y[k]).forEach(rid => this.keepReferencesSet.add(rid));
      });
    });
  }

  initPersistentRecordIds(obj) {
    this._lastPersistentRecordId = 0;
    Object.keys(obj || {}).forEach(k => {
      let i = obj[k];
      this._persistentRecordIds.set(k, i);
      if (i > this._lastPersistentRecordId)
        this._lastPersistentRecordId = i;
    });
    // console.log('INIT PERSISTENT', this._persistentRecordIds);
  }

  switchAbstractExtract(dbRecord: DbRecord) {
    this.fs.updateAbstractExtract(this, {
      fun: 'updateAbstractExtract',
      flower: this.id,
      recordId: dbRecord.id,
      isDelete: this._abstractExtractIdsSet.has(dbRecord.id)
    });
  }

  isAbstractExtract(dbRecord: DbRecord) {
    return this._abstractExtractIdsSet.has(dbRecord.id);
  }

  get countAbstractExtract() {
    return this._abstractExtractIdsSet.size;
  }

  initAbstractExtractIds(obj) {
    this._abstractExtractIdsSet = new Set<string>(obj);
  }

  setPersistentRecordId(dbRecord: DbRecord) {
    this._persistentRecordIds.set(dbRecord.id, ++this._lastPersistentRecordId);
  }

  getPersistentRecordId(dbRecord: DbRecord) {
    return this._persistentRecordIds.get(dbRecord.id);
    /*  if (i) return i
      this._dbRecordsCriterionOrder.forEach(dbr => {
        if (!this._recordIds.has(dbRecord.id))
          this._recordIds.set(dbRecord.id, ++this._lastRecordId)
      })
      this.fs.saveDbRecordInnerIds(this)
      return this._recordIds.get(dbRecord.id) */
  }

  get family() {
    return this.fs.getFamily(this);
  }

  get uniqueRecordsCounter() {
    try {
      return this.fs.searchStats[this.id]['unique'];
    } catch (e) {
      return 0;
    }
  }

  get inList() {
    let inList = 'yellow';
    if (this.isWhite) {
      inList = 'white';
    } else if (this.isNovelFoods) {
      inList = 'novelFoods';
    } else if (this.isInsect) {
      inList = 'insect';
    } else if (this.isAlgae) {
      inList = 'algae';
    } else if (this.isFungi) {
      inList = 'fungi';
    } else if (this.isCompound) {
      inList = 'compound';
    }
    return inList;
  }

  sendToSimek() {

    let simekData = {
      type: 'flowerData',
      id: this.id,
      combo1id: this.combo1id,
      names: this.names,
      efsaAbstractRatings: this.efsaAbstractRatings,
      efsaFulltextRatings: this.efsaFulltextRatings,
      status: this.fs.stats.flowerStatus(this),
      inList: this.inList,
      family: this.family
    };

    console.log(simekData);
    return simekData;
  }


  get navigateTuple() {
    let name;
    try {
      name = this.mainName.join(' ');
    } catch (e) { name = this.mainName.toString(); }
    return ['/d',
      name.replace(/[^A-Za-z ]+/g, '').replace(/ +/g, '_'),
      this.id];
  }

  getEditorNotes(editor: Editor) {
    for (let i = 0; i < this.notes.length; i++) {
      let n = this.notes[i];
      if (n.editor === editor)
        return n;
    }
    return null;
  }

  private _abstractAcceptedSet = new Set<string>(['must_see', 'must_see_composition', 'if_available']);
  isAbstractAcceptedRecord(dbr: DbRecord): boolean {
    for (let i = 0; i < this.abstractRatings.length; i++)
      if (this._abstractAcceptedSet.has(this.abstractRatings[i].getItemRating(dbr)))
        return true;
    return false;
  }


  private _abstractRatingValuesSet(dbr: DbRecord) {
    let s = new Set<string>();
    let hasEFSAeditor = false;
    this.abstractRatings.forEach((ar) => {
      let isEFSAeditor = ar.editor && ar.editor.isEFSAeditor;
      if (this.fs.showEFSAratingsOnly && !isEFSAeditor)
        return;

      let r = ar.getItemRating(dbr);
      if (r && r !== 'not_rated') {
        if (isEFSAeditor && this.fs.showEFSAratingsExclude) {
          hasEFSAeditor = true;
          return;
        }
        s.add(r);
      }
    });
    if (hasEFSAeditor)
      return new Set<string>();
    return s;
  }

  private _fulltextRatingValuesSet(dbr: DbRecord) {
    let s = new Set<string>();
    let hasEFSAeditor = false;
    this.fulltextRatings.forEach((fr) => {
      let isEFSAeditor = fr.editor && fr.editor.isEFSAeditor;
      if (this.fs.showEFSAratingsOnly && !isEFSAeditor)
        return;
      let r = fr.getItemRating(dbr);
      if (r && r[0] !== 'undecided') {
        if (isEFSAeditor && this.fs.showEFSAratingsExclude) {
          hasEFSAeditor = true;
          return;
        }
        s.add(r[0]);
      }

    });
    if (hasEFSAeditor)
      return new Set<string>();
    return s;
  }

  hasRating(dbr: DbRecord, tag: string) {
    let s = this._fulltextRatingValuesSet(dbr);
    let abs = this._abstractRatingValuesSet(dbr);
    let isAcceptedAbstract = false;
    abs.forEach((x) => {
      if (this._abstractAcceptedSet.has(x))
        isAcceptedAbstract = true;
    });

    if (isAcceptedAbstract) {
      if (tag === 'process' || tag === 'accept')
        return s.has('accept') || s.has('process');
      if (tag === 'reject')
        return s.has('reject');
      if (tag === 'undecided')
        return s.has('undecided');
      if (tag === 'ft_mismatch')
        return s.size > 1;
    }

    if (tag === 'mismatch')
      return abs.size > 1;
    if (tag === 'rejected') {
      abs.delete('not_rated');
      this._abstractAcceptedSet.forEach((x) => abs.delete(x));
      return !!abs.size;
    }
    if (tag === 'accepted')
      return isAcceptedAbstract;

    if (tag === 'notrated') {
      return abs.has('not_rated') || !abs.size;
    }
    return false;
  }


  get efsaAbstractRatings() {
    let efsaRatings = [];
    this.abstractRatings.forEach((ar) => {
      if (ar.editor && ar.editor.isEFSAeditor)
        efsaRatings.push(ar);
    });

    if (!efsaRatings.length)
      return { efsaEditors: 0 };

    let ratings = {};

    efsaRatings.forEach(ar => {
      ar._ratings.forEach((v, recordId) => {
        let r = ratings[recordId];
        if (!r) {
          r = new Set<string>();
          ratings[recordId] = r;
        }
        r.add(v);
      });
    });

    let counter = {
      efsaEditors: efsaRatings.length,
      accepted: 0,
      rejected: 0,
      acceptedReasons: {},
      rejectedReasons: {}
    };

    Object.keys(ratings).forEach(k => {
      let isAccepted = false;
      let acceptSet = new Set<string>();
      let rejectSet = new Set<string>();
      ratings[k].forEach(v => {
        if (v === 'not_rated')
          return;
        if (this._abstractAcceptedSet.has(v)) {
          isAccepted = true;
          acceptSet.add(v);
        } else
          rejectSet.add(v);
      });
      if (isAccepted) {
        counter['accepted'] += 1;
        counter['acceptedReasons'][k] = Array.from(acceptSet);
      } else {
        counter['rejected'] += 1;
        counter['rejectedReasons'][k] = Array.from(rejectSet);
      }
    });

    return counter;
  }

  get efsaFulltextRatings() {
    let efsaRatings = [];
    this.fulltextRatings.forEach((fr) => {
      if (fr.editor && fr.editor.isEFSAeditor)
        efsaRatings.push(fr);
    });

    if (!efsaRatings.length)
      return { efsaEditors: 0 };

    let ratings = {};

    efsaRatings.forEach(fr => {
      fr.ratings.forEach((v, recordId) => {
        let r = ratings[recordId];
        if (!r) {
          r = new Set<string>();
          ratings[recordId] = r;
        }
        r.add(v);
      });
    });

    let counter = {
      efsaEditors: efsaRatings.length,
      accepted: 0,
      rejected: 0,
      acceptedReasons: {},
      rejectedReasons: {}
    };

    Object.keys(ratings).forEach(k => {
      let isAccepted = false;
      let acceptSet = new Set<string>();
      let rejectSet = new Set<string>();
      ratings[k].forEach(tpl => {
        if (!tpl[1].size)
          return;

        if (tpl[0] === 'process') {
          isAccepted = true;
          tpl[1].forEach(v => acceptSet.add(v));
        } else if (tpl[0] === 'reject')
          tpl[1].forEach(v => rejectSet.add(v));
      });
      if (isAccepted) {
        counter['accepted'] += 1;
        counter['acceptedReasons'][k] = Array.from(acceptSet);
      } else {
        counter['rejected'] += 1;
        counter['rejectedReasons'][k] = Array.from(rejectSet);
      }
    });
    return counter;
  }

  get abstractRatingsSummary() {
    let counter = { _accepted: 0, _rejected: 0, _mismatch: 0 };
    this.fs.stats.abstractIds.forEach((x) => counter[x] = 0);
    this._dbRecordsCriterionOrder.forEach((dbr) => {
      let s = this._abstractRatingValuesSet(dbr);

      if (!s.size)
        counter['not_rated'] += 1;
      else {
        let hasAccepted = false;
        let hasRejected = false;
        s.forEach((x) => {
          if (x)
            counter[x] += 1;
          if (this._abstractAcceptedSet.has(x)) {
            counter['_accepted'] += 1;
            hasAccepted = true;
          } else if (x === 'not_rated')
            counter['not_rated'] += 1;
          else {
            counter['_rejected'] += 1;
            hasRejected = true;
          }
        });
        if (hasAccepted && hasRejected)
          counter['_mismatch'] += 1;
      }
    });
    return counter;
  }

  get fulltextRatingsSummary() {
    let counter = { process: 0, undecided: 0, reject: 0, _mismatch: 0 };

    this._dbRecordsCriterionOrder.forEach((dbr) => {
      if (!this.isAbstractAcceptedRecord(dbr))
        return;
      let s = this._fulltextRatingValuesSet(dbr);

      if (!s.size)
        counter['undecided'] += 1;
      else {
        s.forEach((x) => {
          if (x === 'accept')
            x = 'process';
          counter[x] += 1;
        });
        if (s.size > 1)
          counter['_mismatch'] += 1;
      }
    });
    return counter;
  }

  get mainName(): string[] {
    return this.names[0];
  }

  nameTest(rg: RegExp) {
    if (!rg)
      return true;
    for (let nm of this.names)
      if (rg.test(nm[0]))
        return true;
    return false;
  }

  finalizeDelete() {
    Flower.flowersMap.delete(this.id);
    this.fs.clearFlowerList();
  }

  save(isNewFlower?: boolean) {
    return this.fs.save(this, !!isNewFlower);
  }

  get csvData() {
    const owner = this.fs.getOwner(this);
    return `"${this.names[0]}", "${this.fs.stats.flowerStatus(this)}", "${owner && owner.displayName}"`;
  }


  get jsonData() {
    return {
      id: this.id,
      family: this.family,
      names: this.names,
      // searches: this.searches,
      // comment: this.comment,
      // combo1Id: this.combo1id,
      isWhite: this.isWhite,
      isNovelFoods: this.isNovelFoods,
      isInsect: this.isInsect,
      isAlgae: this.isAlgae,
      isFungi: this.isFungi,
      isCompound: this.isCompound
    };
  }

  get saveParams() {
    return {
      id: this.id,
      // family: this.family,
      names: this.names,
      searches: this.searches,
      comment: this.comment,
      combo1Id: this.combo1id,
      isWhite: this.isWhite,
      isNovelFoods: this.isNovelFoods,
      isInsect: this.isInsect,
      isAlgae: this.isAlgae,
      isFungi: this.isFungi,
      isCompound: this.isCompound
    };
  }

  get initStarted() {
    return this.fs._initStartedSet.has(this.id);
  }

  get allDbRecords() {
    return this._dbRecordsCriterionOrder.slice(0);
  }

  get dbRecords() {
    if (this.fs.pgs.isTop50)
      return this._dbRecordsCriterionOrder.slice(0, 50);
    else if (this.fs.pgs.isTop100)
      return this._dbRecordsCriterionOrder.slice(0, 100);
    return this._dbRecordsCriterionOrder.slice(0);
  }

}
