<div *ngIf='!ready'>Preparing records &hellip;</div>

<div *ngIf='ready'>
  <table class='menuTable'>
    <tr>
      <th>Sort:</th>
      <td>
        <app-tag-sort [flower]='flower'
          *ngFor="let x of sortFields"
          [tp]="x['tp']"
          [id]="x['id']"
          [name]="x['name']"></app-tag-sort>
        <!-- span class='smallNote'>Ctrl - secondary sort</span -->
      </td>
    </tr>
    <tr>
      <th>
        EFSA
      </th>
      <td>
        <div>
          <label>
            <input type='checkbox'
              (click)='switchEFSAratingsOnly()'
              [checked]='fs.showEFSAratingsOnly' />
            restrict to EFSA ratings
          </label> ||
          <label>
            <input type='checkbox'
              (click)='switchEFSAratingsExclude()'
              [checked]='fs.showEFSAratingsExclude' />
            without EFSA ratings
          </label>
        </div>
        <div>
          <label>
            <input type='checkbox'
              (click)='switchExtractAbstractOnly()'
              [checked]='fs.showExtractAbstractOnly' />
            data extracted from abstract
            ( {{flower.countAbstractExtract}} )
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <th>
        Abstract state:
      </th>
      <td>
        <!-- ({{tpl[2]}}) -->
        <app-tag-filter *ngFor="let tpl of abstractRatingValues"
          label="{{tpl[1]}}"
          tp='abstract'
          [value]="tpl[0]">
        </app-tag-filter>
      </td>
    </tr>
    <tr *ngIf='fulltextRatingValues.length'>
      <th>
        Fulltext state:
      </th>
      <td>
        <!--  ({{tpl[2]}}) -->
        <app-tag-filter *ngFor="let tpl of fulltextRatingValues"
          label="{{tpl[1]}}"
          tp='fulltext'
          [value]="tpl[0]">
        </app-tag-filter>
      </td>
    </tr>
    <tr>
      <th>Languages:</th>
      <td>
        <div>
          <app-tag-misc *ngFor="let obj of languages"
            [label]='obj.label'
            tp='lang'
            [value]='obj.id'></app-tag-misc>
          <span class='smallNote'>Ctrl - excludes</span>
        </div>

      </td>
    </tr>
    <tr>
      <th>Bookmarked:</th>
      <td>
        <div>
          <app-tag-misc *ngFor="let obj of bookmarkEditors"
            [label]='obj.label'
            tp='dbRecordBookmark'
            [value]='obj.id'></app-tag-misc>
        </div>

      </td>
    </tr>
    <tr>
      <th>Search:</th>
      <td>
        <app-filter-input (changeEvent)='filterChange($event)'></app-filter-input>
      </td>
    </tr>
    <tr>
      <th>Author:</th>
      <td>
        <app-filter-input (changeEvent)='authorChange($event)'></app-filter-input>
      </td>
    </tr>
    <tr>
      <th>Record IDS:</th>
      <td id='idChangeTD'>
        <app-filter-input [isPersistentRecordID]='true'
          (changeEvent)='persistentIdChange($event)'></app-filter-input>

        <button [class.withCoded]='isCodedOn'
          class='coded'
          (click)='isCodedOn =! isCodedOn'>
          Coded only</button>
      </td>
    </tr>
  </table>

  <!--[flower]='flower' -->
  <app-pages-box [dbRecords]='dbRecords'></app-pages-box>

  <app-db-record-box *ngFor='let dbr of dbRecordsPage; let i = index; trackBy:trackByDbRecord'
    [dbRecord]='dbr'
    [flower]='flower'
    [order]='i+1+pgs.currentStart'>
  </app-db-record-box>
  <app-pages-box [dbRecords]='dbRecords'></app-pages-box>
</div>
