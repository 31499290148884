<div *ngIf='!checkStatus && isOwner'
    class='main isOwner'>
  <span class='editor'>
    <app-editor-box [editor]='fulltextRating.editor'></app-editor-box>:
    &mdash;
  </span>
</div>
<div *ngIf='checkStatus'
    class='main'
    [class.isEFSAeditor]='isEFSAeditor'
    [class.accepted]="isAccepted"
    [class.rejected]="isRejected"
    [class.isOwner]="isOwner">
  <span class='editor'>
    <app-editor-box [editor]='fulltextRating.editor'></app-editor-box>
    {{stamp}}:
</span>
  <span class='status'>{{status}}</span> /
  <span class='reason'
      *ngFor='let r of reasons; let i = index'>
      <span *ngIf='i'>, </span> {{r['title']}}
  </span>
  <div *ngIf='utils.isAdmin' class='removeDiv'>
    <span *ngIf='!removeMode'>
      <button (click)='removeRatingSwitch()'>Admin: remove rating</button>
    </span>
    <span *ngIf='removeMode'>
      <button (click)='removeRatingSwitch()'>Admin: CANCEL remove rating</button>
      <button (click)='removeConfirmed()'>Admin: CONFIRM remove rating</button>
    </span>
  </div>
</div>
