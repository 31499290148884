import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DbRecord } from '../db-record'
// import { FileUploader} from 'ng2-file-upload/ng2-file-upload';
import { ArcService } from '../arc.service'
import { FulltextService, DoiSuggestStatus } from '../fulltext.service'
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-upload-fulltext',
  templateUrl: './upload-fulltext.component.html',
  styleUrls: ['./upload-fulltext.component.css']
})
export class UploadFulltextComponent implements OnInit, OnDestroy {
  @Input() dbRecord: DbRecord
  public deleteMode = false
  private _fileSuggestion: DoiSuggestStatus
  private _doiSuggestionSubscription
  isUploadSuggestedActivated = false


  private URL = `${location.protocol}//gae-test.ecomole.com/a2/upload`;

  constructor(
    private arcs: ArcService,
    private fts: FulltextService,
    private http: HttpClient,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    /*
    this._uploader.onSuccessItem = (...rest) => {
      let gaePath = JSON.parse(rest[1])['pth']
      let obs = this.arcs.run({
        fun: 'finalizeFulltextDownload',
        tmpPath: gaePath,
        dbRecord: this.dbRecord.id
      })
      obs.subscribe(
        (res) => this.fts.uploadFinished(this.dbRecord, res[0])
      )
    }
    */
    this._doiSuggestionSubscription = this.fts.
      doiSuggestionChange$.subscribe(_ => {
        this._fileSuggestion = undefined
      })
  }

  ngOnDestroy() {
    this._doiSuggestionSubscription.unsubscribe();
  }

  get isAdmin() {
    return this.utils.isAdmin
  }

  suggestedActivated() {
    this.isUploadSuggestedActivated = true
  }

  uploadSuggested() {
    const nm = this.doiSuggestionLink.split('/').pop()
    this.http.get(this.doiSuggestionLink, {
      responseType: 'blob'
    }).subscribe(resp => {
      const f = new File([resp], nm, { type: "application/pdf", });
      this._performUpload(f)
    })

  }

  private _performUpload(f) {
    const reader = new FileReader();
    reader.onload = x => {
      const payload = reader.result.toString();

      let obs = this.arcs.run({
        fun: 'fulltextUpload',
        payload: payload,
        dbRecord: this.dbRecord.id
      })
      obs.subscribe(
        (res) => this.fts.uploadFinished(this.dbRecord, res[0])
      )
    };
    reader.readAsDataURL(f)
  }

  upload() {
    const hiddenElement = document.createElement('input');

    hiddenElement.type = 'file';
    hiddenElement.onchange = (e) => {
      const inp = e['path'] && e['path'][0] || e['originalTarget'];
      const f = inp.files[0];
      this._performUpload(f)
    }
    hiddenElement.click();
  }

  get doiSuggestionLink() {
    return this.fts.doiSuggestionLink(this.dbRecord)
  }

  get fileSuggestion() {
    if (!this._fileSuggestion) {
      this._fileSuggestion = this.fts.getFileSuggestion(this.dbRecord)
    }
    return this._fileSuggestion
  }

  get hasFulltextFile() {
    return this.fts.hasFile(this.dbRecord)
  }

  deleteFulltextSwitch() {
    this.deleteMode = !this.deleteMode
  }

  deleteConfirmed() {
    let obs = this.fts.deleteFile(this.dbRecord)
    obs.subscribe((resp) => this.deleteMode = false)
  }

}
