import { Injectable } from '@angular/core';
import { Flower } from './flower';
import { AbstractRating} from './abstract-rating';
import { EditorService } from './editor.service';
import { DbRecordService } from './db-record.service';
import { UtilsService } from './utils.service';
import { DbRecord } from './db-record';
import { ArcService } from './arc.service';

@Injectable()
export class AbstractRatingService {
  stamps: Object;


  constructor(
    private arcs: ArcService,
    private es: EditorService,
    private dbrs: DbRecordService,
    private utils: UtilsService
  ) { }


  initFlower(flower: Flower, obj: Object, stamps: Object) {
    if (!obj) {
      return;
    }

    Object.keys(obj).forEach((k) => {
      if (k.charAt(0) === '_') {
        return;
      }
      let editor = this.es.getEditor(k);
      let ar = new AbstractRating(this, flower, editor);
      let data = obj[k];

      Object.keys(data).forEach((x) => {
        let r = this.dbrs.get(x, flower);
        if (r) {
          ar.setItemRating(r, data[x], true);
        } else {
          ar.setOtherRating(x, data[x]);
        }
      });
    });
    this.stamps = stamps;
  }

  ownerRatings(flower: Flower) {
    for (let i = 0; i < flower.abstractRatings.length; i++) {
      if (flower.abstractRatings[i].editor && flower.abstractRatings[i].editor.email === this.utils.email) {
        return flower.abstractRatings[i];
      }
    }
    return new AbstractRating(this, flower, this.es.getEditor(this.utils.email));
  }

  save(ar: AbstractRating, dbRecord: DbRecord, rating: string) {
    let obs = this.arcs.run({
      fun: 'saveAbstractRatingChange',
      flower: ar.flower.id,
      dbRecord: dbRecord.id,
      rating: rating,
      editor: ar.editor.id
    });
    obs.subscribe((x) => x[0]);
  }

}
