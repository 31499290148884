<div *ngIf='utils.authenticated'>
  <div class='editor'>
    <app-editor-box *ngIf='myself'
        [editor]='myself'></app-editor-box>
    <span *ngIf='!myself'
        [innerHTML]='email'></span>
  </div>
  <button class='logout'
      (click)="utils.logout()">Log Out</button>
</div>

<div *ngIf='!utils.authenticated'>
  <button (click)="utils.login()">Log In</button>
</div>
