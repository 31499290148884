import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { Observable, } from 'rxjs';
// import './rxjs-operators';

@Injectable()
export class ArcService {
  serviceUrl: string;
  userNs: string;
  app = 'combo2';
  utils: UtilsService
  constructor(
    private http: HttpClient
  ) {
    this.serviceUrl = `${location.protocol}//gae-test.ecomole.com/a2`
  }

  fulltextSuggestDoiList$(doiLs: string[]) {
    let url = 'https://janhorak.info:5001/api/doilist'
    return this.http.post(url, {dois: doiLs});
  }

  // getBotanicStatusFromSimek() {
  //   let url = 'https://combodb.ecomole.com/api/botanic-status/'
  //   return this.http.get(url);
  // }

  getColorsFromSimek() {
    let url = 'https://combodb.ecomole.com/api/colours'
    return this.http.get(url);
  }

  sendToSimek(data) {
    let url = 'https://combodb.ecomole.com/api/v2'
    console.log("SEND TO:" + url + '; data in window.simekData')
    window['simekData'] = data;

    let obs = this.http.post(url,
      JSON.stringify(data))
    return obs
  }

  communicate(type: string, payload: Object): Observable<any> {
    // this.userNs = JSON.parse(localStorage.getItem('auth_profile'))['user_id']

    return this.http.post(this.serviceUrl,
      JSON.stringify({
        payload: payload,
        type: type,
        app: this.app,
        u: this.userNs,
        email: this.utils.email
      }));
  }


  gaeDownload(pth) {
    if (!this.userNs)
      this.userNs = JSON.parse(localStorage.getItem('auth_profile'))['userId']
    return this.http.post(this.serviceUrl,
      JSON.stringify({
        type: 'download',
        pth: pth,
        app: this.app,
        u: this.userNs,
        email: this.utils.email
      }))
  }


  gaeSave(pth, payload, extras?) {
    let s = new Subject<any>()
    let formData = new FormData();
    let f = new File([JSON.stringify(payload)], pth)
    formData.append('f', f)
    formData.append('type', 'upload')
    formData.append('app', this.app)
    formData.append('u', this.userNs)
    formData.append('email', this.utils.email)
    if (extras) {
      formData.append('extras', JSON.stringify(extras))
    }
    var request = new XMLHttpRequest();
    request.open("POST", this.serviceUrl);
    request.send(formData);
    request.onreadystatechange = e => {
      if (request.readyState == XMLHttpRequest.DONE)
        if (request.status == 200) {
          s.next(request)
          s.complete()
        }

    }
    return s
  }

  run(payload: Object) {
    return this.communicate('run', [payload])
  }

  save(payload: Object) {
    if (payload['edge'])
      payload['edge'] = `${this.app}::${payload['edge']}`
    return this.communicate('saveKey', [payload])
  }

  getEdge(payload: string) {
    payload = `${this.app}::${payload}`
    return this.communicate('getEdge', [payload])
  }

  getKeys(payload: string[]) {
    return this.communicate('getKeys', payload)
  }

  getKey(payload: string) {
    return this.getKeys([payload])
  }

  resetEdge(payload: string) {
    payload = `${this.app}::${payload}`
    return this.communicate('resetEdge', [payload])
  }
}
