import { Injectable } from '@angular/core';
import { Criterion } from './criterion'


@Injectable()
export class CriterionService {
  static criterionName = 'criterion_A'


  constructor() { }

  create(id: string, data: Object) {
    new Criterion(id, data);
  }

  get mainCriterion() {
    return Criterion.criterionsMap.get(CriterionService.criterionName)
  }

}
