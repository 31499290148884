import { Component, OnInit, Input } from '@angular/core';
import { FulltextRating } from '../fulltext-rating';
import { DbRecord } from '../db-record';
import { StatusService } from '../status.service';
import { UtilsService } from '../utils.service';
import { FulltextRatingService } from '../fulltext-rating.service';

@Component({
  selector: 'app-fulltext-rating-box',
  templateUrl: './fulltext-rating-box.component.html',
  styleUrls: ['./fulltext-rating-box.component.css']
})
export class FulltextRatingBoxComponent implements OnInit {
  @Input() fulltextRating: FulltextRating;
  @Input() dbRecord: DbRecord;
  mainStatus: string;
  reasons = [];
  isOwner: boolean;
  removeMode = false;
  date: String;

  constructor(
    private ss: StatusService,
    public utils: UtilsService,
    private frs: FulltextRatingService
  ) { }

  ngOnInit() {
    this.isOwner = !!this.fulltextRating.editor && this.utils.email === this.fulltextRating.editor.email;
    const stmp = this.frs.stamps && this.frs.stamps[this.dbRecord.id];
    if (stmp) {
      const dt = stmp[this.fulltextRating.editor.email];
      if (dt) {
        this.date = dt.split('T')[0];
      }
    }
  }

  get stamp() {
    return this.date ? ` [${this.date}]` : '';
  }

  get isEFSAeditor() {
    return !!this.fulltextRating.editor && this.fulltextRating.editor.isEFSAeditor;
  }

  removeRatingSwitch() {
    this.removeMode = !this.removeMode;
  }

  removeConfirmed() {
    this.fulltextRating.adminRemove(this.dbRecord);
  }

  get checkStatus() {
    let obj = this.ss
      .getFulltextObject(this.fulltextRating.getItemRating(this.dbRecord));

    if (obj && obj['mainStatus']) {
      this.mainStatus = obj['mainStatus']['title'];
      this.reasons = obj['reasons'];
      if (this.mainStatus === 'undecided' && !this.reasons.length)
        return false;
      this.reasons.sort((a, b) => a.title < b.title ? -1 : 1);
      return true;
    }
    this.mainStatus = '';
    this.reasons = [];
    return false;
  }


  get status() {
    if (this.isRejected)
      return 'REJECT';
    if (this.isAccepted)
      return 'PROCESS';
    if (this.isUndecided)
      return 'UNDECIDED';
    return '???';
  }

  get isAccepted() {
    return this.mainStatus === 'process';
  }

  get isRejected() {
    return this.mainStatus === 'reject';
  }

  get isUndecided() {
    return this.mainStatus === 'undecided';
  }

}
