// tslint:disable:curly
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FlowerService } from './flower.service';
import { Flower } from './flower';
import { ExportItem } from './export-item';
import { ArcService } from './arc.service';
import { Observable } from 'rxjs';
import { map, retry, merge } from 'rxjs/operators';
import { RecordsCollectionService } from './records-collection.service';
import { RecordsCollection } from './records-collection';

@Injectable()
export class ParserService {
  isProcessing = false;
  isRunning = false;
  isExporting = false;

  isDownloadingList = false;
  isOldSearchFiles = false;
  processingCounter: number;

  isAutoMode = false;
  fs: FlowerService;

  private _downloadObservers = [];

  constructor(
    private arcs: ArcService,
    private rcs: RecordsCollectionService,
    private router: Router
  ) {

  }

  get processingMessage() {
    if (this.processingCounter > 0)
      return `${this.processingCounter} files remaining`;
    if (this.isRunning)
      return 'Finalization.';
    if (this.isDownloadingList)
      return 'Listing search directory.';
    if (this.isOldSearchFiles)
      return 'Search files older than JSON ones.';
    return null;
  }

  downloadFile(fn: string, flower: Flower, rc: RecordsCollection): Observable<any> {
    //  let s = new Subject<any>()
    let obs = this.arcs.run({
      fun: 'downloadSearchFile',
      isDownload: true,
      flower: flower.id,
      fn: fn
    });
    return obs.pipe(retry(5));
  }

  // merge caused timeouts when more files

  subscribeDownload(rc: RecordsCollection, saveOffline?) {
    if (this._downloadObservers.length) {
      let obj = this._downloadObservers.pop();
      console.log('Subscribe download', obj['fn']);
      obj['obs'].subscribe((resp) => {
        console.log('Finish download:', obj['fn']);
        rc.addFile(this.parse_conductor(resp));
        rc.addDate(obj['fn']);
        this.processingCounter--;
        this.subscribeDownload(rc, saveOffline);
      },
        (err) => console.log('ERROR', err)
      );
      return;
    }
    this.isRunning = true;
    rc.run();
    this.isRunning = false;

    this.isExporting = true;
    let s = rc.export(saveOffline);
    this.isExporting = false;
    this.isProcessing = false;

    if (this.isAutoMode &&
      this._processPipelineLs &&
      this._processPipelineLs.length
    ) {
      s.subscribe((resp) => {
        this._processPipelineLs.shift();
        this.processFlowerPipeline(this._processPipelineLs);
      });
    }
  }

  refreshStats(flowerId) {
    let obs = this.arcs.run({
      fun: 'refreshFlowerStats',
      flower: flowerId
    });
    obs.subscribe(resp => resp);
  }


  processSearches(flower: Flower, saveOffline?) {
    this.isProcessing = true;
    this.isDownloadingList = true;
    let jsonDate;
    let searchDate;
    let fnLs = [];

    let obsJson = this.arcs.run({
      fun: 'listFlowerJsonFiles',
      flower: flower.id
    }).pipe(map((resp) => {
      let s = new Set(['criterions.json', 'mainRecords.json', 'recordsStats.json']);
      resp[0].forEach(x => {
        let fn = x.filename.split('/').pop();
        if (!s.has(fn))
          return;
        if (x.st_ctime > jsonDate)
          jsonDate = x.st_ctime;
        s.delete(fn);
      });
      if (s.size !== 0)
        jsonDate = null; // missing file
    }));

    let obs = obsJson.pipe(merge(this.arcs.run({
      fun: 'listFlowerSearchFiles',
      flower: flower.id
    }))).pipe(map(resp => {
      resp[0].forEach((x) => {
        if (x['filename'].endsWith('.ciw') ||
          x['filename'].endsWith('.txt') ||
          x['filename'].endsWith('.ris')) {
          fnLs.push(x['filename'].split('/').pop());
          if (x.st_ctime > searchDate)
            searchDate = x.st_ctime;
        }
      });
    }));

    obs.subscribe((resp) => {
      console.log(searchDate, jsonDate);
      if (searchDate < jsonDate) {
        this.isDownloadingList = false;
        this.isOldSearchFiles = true;
        console.log('SEARCH FILES older than JSON Files');
        this._processPipelineLs.shift();
        this.processFlowerPipeline(this._processPipelineLs);
        return;
      }

      let rc = this.rcs.newCollection(flower);

      this.processingCounter = fnLs.length;
      fnLs.forEach(x => {
        let o = this.downloadFile(x, flower, rc);
        this._downloadObservers.push({ obs: o, fn: x, rc: rc });
      });
      this.subscribeDownload(rc, saveOffline);
      this.isDownloadingList = false;
    });
  }

  processCurrentFlowerSearches(saveOffline?) {
    console.log('Process current flower searches', saveOffline);
    this.processSearches(this.fs.currentFlower, saveOffline);
  }


  parse_pubmed_export(arg: Object): Object[] {
    console.log('PUBMED PARSING');
    let results: Object[] = [];
    let txt = arg['txt'];
    let ei;
    let tag;
    let doiTest = /\[doi\]/;
    let eiArg = { tp: 'pubmed', searchCode: arg['searchCode'] };
    for (let ln of txt.split('\n')) {
      let txt = ln.slice(6).trim();
      if (!txt)
        continue;
      let newTag = ln.slice(0, 4).trim();
      if (newTag)
        tag = newTag;
      switch (tag) { // https://www.nlm.nih.gov/bsd/mms/medlineelements.html
        case 'AB': ei.abstract = txt; break;
        case 'AD': ei.address = txt; break;
        case 'AID':
          if (doiTest.test(txt))
            ei.doi = txt.split('[')[0].trim();
          else
            ei.keyword = txt;
          break;
        case 'AU': ei.author = txt; break;
        case 'CI': ei.misc = txt; break;
        case 'CIN': ei.keyword = txt; break;
        case 'CRDT': ei.otherDate = txt; break;
        case 'DA': ei.otherDate = txt; break;
        case 'DCOM': ei.otherDate = txt; break;
        case 'DP': ei.year = txt; break;
        case 'DEP': ei.year = txt; break;
        case 'EDAT': ei.misc = txt; break;
        case 'EIN': ei.misc = txt; break;
        case 'FAU': ei.fullAuthor = txt; break;
        case 'GR': ei.keyword = txt; break;
        case 'IP': ei.issue = txt; break;
        case 'IS': ei.issn = txt; break;
        case 'JID': ei.keyword = txt; break;
        case 'JT': ei.source = txt; break;
        case 'LA': ei.lang = txt; break;
        case 'LID':
          if (doiTest.test(txt))
            ei.doi = txt.split('[')[0].trim();
          else
            ei.keyword = txt;
          break;
        case 'LR': ei.otherDate = txt; break;
        case 'MH': ei.keyword = txt; break;
        case 'MHDA': ei.misc = txt; break;
        case 'MID': ei.keyword = txt; break;
        case 'NLM': ei.keyword = txt; break;
        case 'OID': ei.keyword = txt; break;
        case 'OT': ei.keyword = txt; break;
        case 'OTO': ei.misc = txt; break;
        case 'OWN': ei.misc = txt; break;
        case 'PG': ei.startPage = txt; break;
        case 'PL': ei.misc = txt; break;
        case 'PHST': ei.misc = txt; break;
        case 'PMC': ei.keyword = txt; break;
        case 'PMID': if (ei) ei.finalize(results);
          ei = new ExportItem(eiArg);
          ei.recordId = txt;
          break;
        case 'PT': ei.type = txt; break;
        case 'PST': ei.type = txt; break;
        case 'RF': ei.misc = txt; break;
        case 'RN': ei.keyword = txt; break;
        case 'SO': ei.misc = txt; break;
        case 'SB': ei.misc = txt; break;
        case 'STAT': ei.misc = txt; break;
        case 'TA': ei.abbrevSource = txt; break;
        case 'TI': ei.title = txt; break;
        case 'TT': ei.title = txt; break;
        case 'VI': ei.volume = txt; break;
        case 'VTI': ei.volume = txt; break;
        // default: console.log(tag, txt)
      }
    }
    ei.finalize(results);
    console.log('pubmed results len', results.length);
    return results;
  }

  parse_wos_export(arg: Object): Object[] {
    console.log('WOS');
    let results: Object[] = [];
    let txt = arg['txt'];
    let eiArg = { tp: 'wos', searchCode: arg['searchCode'] };
    let ei = new ExportItem(eiArg);
    let tag;
    for (let ln of txt.split('\n')) { // http://images.webofknowledge.com/WOKRS49B3/help/WOK/hft_wos.html
      if (!ln)
        continue;
      let newTag = ln.slice(0, 2).trim();
      if (newTag)
        tag = newTag;
      let txt = ln.slice(3).trim();

      switch (tag) {
        case 'AB': ei.abstract = txt; break;
        case 'AF': ei.fullAuthor = txt; break;
        case 'AR': ei.keyword = txt; break;
        case 'AU': ei.author = txt; break;
        case 'BE': ei.author = txt; break;
        case 'BP': ei.startPage = txt; break;
        case 'BN': ei.isbn = txt; break;
        case 'C1': ei.address = txt; break;
        case 'CT': ei.conference = txt; break;
        case 'CY': ei.conference = txt; break;
        case 'CL': ei.conference = txt; break;
        case 'DE': ei.keyword = txt; break;
        case 'DC': ei.misc = txt; break;
        case 'DI': ei.doi = txt; break;
        case 'DT': ei.type = txt; break;
        case 'EF': break;
        case 'EI': ei.issn = txt; break;
        case 'EM': ei.misc = txt; break;
        case 'EP': ei.endPage = txt; break;
        case 'FU': ei.misc = txt; break;
        case 'FN': ei.misc = txt; break;
        case 'FX': ei.misc = txt; break;
        case 'GA': ei.misc = txt; break;
        case 'GP': ei.misc = txt; break;
        case 'HO': ei.address = txt; break;
        case 'ID': ei.keyword = txt; break;
        case 'IS': ei.issue = txt; break;
        case 'JI': ei.abbrevSource = txt; break;
        case 'J9': ei.abbrevSource = txt; break;
        case 'LA': ei.lang = txt; break;
        case 'NR': ei.misc = txt; break;
        case 'OI': ei.misc = txt; break;
        case 'PA': ei.misc = txt; break;
        case 'PD': ei.otherDate = txt; break;
        case 'PG': ei.misc = txt; break;
        case 'PI': ei.misc = txt; break;
        case 'PM': ei.misc = txt; break;
        case 'PN':
          ei.publicationNumber = txt;
          ei.type = 'patent';
          break;
        case 'PT': ei.type = txt; break;
        case 'PU': ei.misc = txt; break;
        case 'PY': ei.year = txt; break;
        case 'RI': ei.misc = txt; break;
        case 'RP': ei.address = txt; break;
        case 'SI': ei.misc = txt; break;
        case 'SC': ei.misc = txt; break;
        case 'SE': ei.misc = txt; break;
        case 'SN': ei.issn = txt; break;
        case 'SO': ei.source = txt; break;
        case 'SP': ei.misc = txt; break;
        case 'TC': ei.misc = txt; break;
        case 'TI': ei.title = txt; break;
        case 'UT': ei.recordId = txt; break;
        case 'U1': ei.misc = txt; break;
        case 'U2': ei.misc = txt; break;
        case 'VL': ei.volume = txt; break;
        case 'VR': ei.misc = txt; break;
        case 'WC': ei.misc = txt; break;
        case 'Z9': ei.misc = txt; break;
        case 'ER': ei.finalize(results); ei = new ExportItem(eiArg); break;
        // default: console.log(tag, txt)
      }
    }
    console.log('wos results len', results.length);
    return results;
  }

  parse_manual_export(arg: Object): Object[] {
    console.log('MANUAL PARSING');

    return this.parse_scopus_export(arg, true);
  }

  parse_scopus_export(arg: Object, isManual?: boolean): Object[] {
    console.log('SCOPUS PARSING');
    let results: Object[] = [];
    let txt = arg['txt'];
    let eiArg = { tp: isManual ? 'manual' : 'scopus', searchCode: arg['searchCode'] };
    let ei = new ExportItem(eiArg);
    let cTest = /C\d/;
    for (let ln of txt.split('\n')) {
      ln = ln.trim();
      if (!ln)
        continue;
      let txt = ln.slice(6);
      let tag = ln.slice(0, 2);
      switch (tag) { // https://en.wikipedia.org/wiki/RIS_(file_format)
        case 'AB': ei.abstract = txt; break;
        case 'AD': ei.address = txt; break;
        case 'AU': ei.author = txt; break;
        case 'A2': ei.author = txt; break;
        case 'CY': ei.address = txt; break;
        case 'DB': ei.dbName = txt; break;
        case 'DO': ei.doi = txt; break;
        case 'EP': ei.endPage = txt; break;
        case 'IS': ei.issue = txt; break;
        case 'J2': ei.abbrevSource = txt; break;
        case 'KW': ei.keyword = txt; break;
        case 'LA': ei.lang = txt; break;
        case 'M3': ei.workType = txt; break;
        case 'N1': ei.misc = txt; break;
        case 'PB': ei.publisher = txt; break;
        case 'PY': ei.year = txt; break;
        case 'SN': ei.issn = txt; break;
        case 'SP': ei.startPage = txt; break;
        case 'ST': ei.title = txt; break;
        case 'T2': ei.source = txt; break;
        case 'TI': ei.title = txt; break;
        case 'TY': ei.type = txt; break;
        case 'UR': ei.recordId = txt; break;
        case 'VL': ei.volume = txt; break;
        case 'ER': ei.finalize(results); ei = new ExportItem(eiArg); break;
        default:
          if (cTest.test(tag)) {
            ei.misc = txt;
            break;
          }
        //  console.log(tag, txt)
      }
    }
    console.log('scopus results len', results.length);
    return results;
  }

  parse_cas_export(arg: Object): Object[] {
    console.log('CAS');
    let results: Object[] = [];
    let eiArg = { tp: 'cas', searchCode: arg['searchCode'] };
    let ei = new ExportItem(eiArg);
    let txt = arg['txt'];
    for (let ln of txt.split('\n')) {
      ln = ln.trim();
      if (!ln)
        continue;
      let txt = ln.slice(6);
      let tag = ln.slice(0, 2);
      switch (tag) {
        // case "AD": ei.address = txt; break;
        case 'AU': ei.author = txt; break;
        // case "DB": ei.dbName = txt; break;
        // case "DO": ei.doi = txt; break;
        case 'EP': ei.endPage = txt; break;
        case 'IS': ei.issue = txt; break;
        // case "J2": ei.abbrevSource = txt; break;
        // case "KW": ei.keyword = txt; break;
        case 'JF': ei.source = txt; break;
        case 'LA': ei.lang = txt; break;
        // case "M3": ei.workType = txt; break;
        case 'N1': ei.recordId = txt; break;
        case 'N2': ei.abstract = txt; break;
        // case "N1": ei.misc = txt; break;
        // case "PB": ei.publisher = txt; break;
        case 'PY': ei.year = txt; break;
        // case "SN": ei.issn = txt; break;
        case 'SP': ei.startPage = txt; break;
        // case "ST": ei.title = txt; break;
        case 'T1': ei.title = txt; break;
        case 'TY': ei.type = txt; break;
        case 'VL': ei.volume = txt; break;
        case 'ER': ei.finalize(results); ei = new ExportItem(eiArg); break;
        // default: console.log(tag, txt)
        //   if (cTest.test(tag)) {
        //     ei.misc = txt;
        //     break;
      }
    }
    console.log('cas results len', results.length);
    return results;
  }

  parse_conductor(txt: string): Object[] {
    txt = txt.trim();
    let arg = { txt: txt };

    if (txt.startsWith('FN Thomson') || txt.startsWith('FN Clarivate')
      || txt.startsWith('FN BIBLIO'))
      return this.parse_wos_export(arg);
    if (txt.startsWith('PMID-'))
      return this.parse_pubmed_export(arg);
    let lead = txt.slice(0, 10000);
    if (lead.indexOf('?eid=2-s2') > -1)
      return this.parse_scopus_export(arg);
    if (lead.indexOf('N1  -') > -1)
      return this.parse_cas_export(arg);
    if (lead.indexOf('UR  - manual_') > -1)
      return this.parse_manual_export(arg);
    /*  if (lead.indexOf('manual_') > -1)
        return this.parse_manual_export(arg) */
    console.log('FORMAT NOT RECOGNIZED', `|||${lead}|||`);
    return null;
  }

  private _processPipelineLs;
  processFlowerPipeline(ls?) {
    if (!ls)
      ls = JSON.parse(localStorage.getItem('processingPipeline'));
    else
      localStorage.setItem('processingPipeline', JSON.stringify(ls));

    if (!ls.length) {
      this.isAutoMode = false;
      this._processPipelineLs = null;
      return console.log('processing finish');
    }
    this.isAutoMode = true;
    this._processPipelineLs = ls;
    this.router.navigate(['flowers', 'display', ls[0]]);
  }


}
