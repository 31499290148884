<div class='dbRecord' id='dbRecord{{order}}'>
  <div>
    <span class='order'>{{order}}</span>
    <span (click)='switchPersistentLink()' class='persistentRecordId'>R{{persistentRecordId}}</span>
    <app-bookmark-switch-db-record-box [flower]='flower' [dbRecord]='dbRecord'></app-bookmark-switch-db-record-box>

    <span class='jump' (click)='jumpHome()'>⟰ HOME</span>
    <span class='jump' (click)='jumpTop()'>▲ TOP</span>
    <span class='jump' (click)='jumpPrev()'>◄ PREV</span>
    <span class='jump' (click)='jumpNext()'>NEXT ►</span>

    <span class='dbRecordId' *ngIf='utils.isAdmin'>{{dbRecord.id}}</span>
    <span class='database'>{{dbRecord.database}}</span> //
    <span class='lang'>{{dbRecord.lang}}</span>

    <!-- app-permalink-box tp="flowerRecordId"
        [flower]='flower'
        [dbRecord]="dbRecord"></app-permalink-box -->

    <!-- button (click)='goToDatabase()'>Go to Database</button -->

  </div>
  <div *ngIf='isPersistentLink' class='persistentLink'>
    {{persistentLink()}}
  </div>

  <table *ngIf='dbRecord.data'>
    <tr>
      <th>Coded data:</th>
      <td [innerHTML]='opinionText' [class.emphasis]='opinion'></td>
    </tr>
    <tr>
      <th>Criterions:</th>
      <td>
        <app-tag-criterion-item *ngFor='let itm of criterionItemMatches' 
        [flower] = 'flower'
        [criterionItem] ='itm'></app-tag-criterion-item>
      </td>
    </tr>
    <tr>
      <th>Criterions' summary</th>
      <td>
        <app-tag-misc tp="allCriterionMatch">
        </app-tag-misc>
        // Sum: {{criterionSum}} // Criterion: {{criterionName}}
      </td>
    </tr>
    <tr>
      <th>Notes:</th>
      <td>
        <app-note-box *ngFor='let n of notes' [dbRecord]='dbRecord' [note]='n'></app-note-box>
        <div *ngIf='showAddNoteButton'>
          <div class='noteButton'>
            <button (click)='addNote()'>Add note</button>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>Authors:</th>
      <td [innerHTML]='authors'>
      </td>
    </tr>
    <tr>
      <th>Source:</th>
      <td>
        {{source}}.
        <div *ngIf='doi' class='doi'>
          DOI:
          <span [innerHTML]='doi'></span>
        </div>
      </td>
    </tr>
    <tr>
      <th>Title:</th>
      <td [innerHTML]='title' class='articleTitle'></td>
    </tr>
    <tr>
      <th>Abstract:</th>
      <td [innerHTML]='abstract' class='articleAbstract'></td>
    </tr>
    <tr>
      <th>Search:</th>
      <td [innerHTML]='search'>
      </td>
    </tr>
    <tr>
      <th>Crossref:</th>
      <td>
        <app-crossref-box *ngIf='doi' [dbRecord]='dbRecord' type='doi'></app-crossref-box>
        <app-crossref-box [dbRecord]='dbRecord' type='title'></app-crossref-box>
        <!--app-crossref-box *ngIf='dbRecord.data["issn"]'
            [dbRecord]='dbRecord'
            type='issn'
            [issn]='dbRecord.data["issn"]'></app-crossref-box-->
      </td>
    </tr>
    <tr>
      <th>Abstract rating:</th>
      <td>
        <div class='extractAbstract'>
          <label >
            <input 
            type='checkbox'
            (click)='flower.switchAbstractExtract(dbRecord)'
            [checked] = 'flower.isAbstractExtract(dbRecord)'> Data extraction from abstract only</label>
        </div>
        <app-abstract-rating-box *ngFor='let ar of abstractRatings' [abstractRating]='ar' [dbRecord]='dbRecord'>
        </app-abstract-rating-box>

        <app-abstract-rating-edit *ngIf="isEditor" [flower]='flower' [dbRecord]='dbRecord'></app-abstract-rating-edit>
      </td>
    </tr>
    <tr>
      <th>Full text rating:</th>
      <td>
        <div *ngIf='isAcceptedAbstract'>
          <app-fulltext-rating-box *ngFor='let fr of fulltextRatings' [fulltextRating]='fr' [dbRecord]='dbRecord'>
          </app-fulltext-rating-box>

          <app-fulltext-rating-edit *ngIf="isEditor" [flower]='flower' [dbRecord]='dbRecord'></app-fulltext-rating-edit>
        </div>

        <div *ngIf='!isAcceptedAbstract' class='notAcceptedAbstract'>
          WITHOUT ACCEPTED ABSTRACT
        </div>
        <div class='sendReferenceData'>
          <button (click)='sendReferenceData()'>Send reference data</button>
        </div>
      </td>
    </tr>
    <tr>
      <th>Full text download:</th>
      <td>
        <app-upload-fulltext [dbRecord]='dbRecord'></app-upload-fulltext>
      </td>
    </tr>

    <!--tr>
      <th>Year</th>
      <td>
        {{year}}
      </td>
    </tr>
    <tr>
      <th>DOI</th>
      <td [innerHTML]='doi'></td>
    </tr-->


    <tr *ngIf='duplicates.length'>
      <th>Duplicates:
        <div>
          <button (click)='switchDuplicateDisplay()'>
            <span *ngIf='!displayFullDuplicates'>
              Expand
            </span>
            <span *ngIf='displayFullDuplicates'>
              Collapse
            </span>
          </button>
        </div>
      </th>
      <td *ngIf='!displayFullDuplicates'>
        <div *ngFor='let dbr of duplicates' [innerHTML]='dbr.shortCitation'>
        </div>

      </td>
      <td *ngIf='displayFullDuplicates'>
        <div *ngFor='let dbr of duplicates' [innerHTML]='dbr.longCitation'>
        </div>
      </td>
    </tr>


  </table>

</div>
