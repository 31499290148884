import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../page.service'
import { BookmarkService } from '../bookmark.service'

@Component({
  selector: 'app-tag-misc',
  templateUrl: './tag-misc.component.html',
  styleUrls: ['./tag-misc.component.css']
})
export class TagMiscComponent implements OnInit {

  @Input() tp: string;
  @Input() value: string;
  @Input() label: string;
  @Input() checkbox: boolean;

  constructor(
    private pgs: PageService,
    private bs: BookmarkService
  ) { }

  ngOnInit() {
  }

  get isDbRecordBookmarkEditor() {
    return this.tp == 'dbRecordBookmark'
  }

  get isTop50() {
    return this.tp == 'top50'
  }

  get isTop100() {
    return this.tp == 'top100'
  }

  get taglabel() {
    if (this.label)
      return this.label
    switch (this.tp) {
      case 'allCriterionMatch':
        return "Match all criterions";
      case 'sortFlowersByUnique':
        return "Sort by unique count";
      case 'familyFilter':
        return this.value;
      default: return "MISC BUTTON"
    }
  }

  switch(event) {
    switch (this.tp) {
      case 'allCriterionMatch':
        return this.pgs.switchAllCriterionMatch();
      case 'sortFlowersByUnique':
        return this.pgs.switchSortFlowersByUnique();
      case 'familyFilter':
        return this.pgs.switchFamilyFilter(this.value);
      case 'dbRecordBookmark':
        return this.bs.switchSelectedEditor(this.value)
      case 'top50':
        return this.pgs.top50switch()
      case 'top100':
        return this.pgs.top100switch()
      case 'lang':
        return this.pgs.langSwitch(this.value, event.ctrlKey)
    }
  }

  get isFamily() {
    return this.tp == 'familyFilter'
  }

  get isExcluded() {
    return this.pgs.isLangExcluded(this.value)
  }

  get isSelected() {
    switch (this.tp) {
      case 'allCriterionMatch':
        return this.pgs.isOnAllCriterionMatch
      case 'sortFlowersByUnique':
        return this.pgs.isOnSortFlowersByUnique;
      case 'familyFilter':
        return this.pgs.isCurrentFamilyFilter(this.value);
      case 'dbRecordBookmark':
        return this.bs.isSelectedEditor(this.value)
      case 'top50':
        return this.pgs.isTop50
      case 'top100':
        return this.pgs.isTop100
      case 'lang':
        return this.pgs.isLangSelected(this.value)
    }
  }
}
