import { RecordsCollectionService } from './records-collection.service'
import { Flower } from './flower'
import { Subject, Observable } from 'rxjs'
import { retry } from 'rxjs/operators'
import { UtilsService } from './utils.service'


export class RecordsCollection {
  keysMap = new Map<string, Set<string>>()
  recordsMap = new Map<string, Object>()
  mainSet = new Set<string>()
  duplicatesSet = new Set<string>()
  lastDates = {}

  constructor(
    private rcs: RecordsCollectionService,
    public flower: Flower,
    private utils: UtilsService
  ) {
  }

  addFile(ls) {
    ls.forEach(x => {
      this.recordsMap.set(x['recordId'], x)
      let keys = x.keys || []
      keys.forEach(k => {
        let s = this.keysMap.get(k)
        if (!s) {
          s = new Set<string>()
          this.keysMap.set(k, s)
        }
        s.add(x['recordId'])
      })
    })
  }

  addDate(fn: string) {
    fn = fn.split('.')[0]
    fn = fn.split('@')[0]
    let tpl = fn.split('-');
    let dt = tpl.pop();
    let tp = tpl.pop();
    let dtTpl = dt.split('_');
    let dtTplNmb = dtTpl.map(x => parseInt(x, 10));
    if (dtTplNmb[0] > 2000)
      dtTplNmb.reverse();
    let d = new Date(dtTplNmb[2], dtTplNmb[1] - 1, dtTplNmb[0]);
    if (!this.lastDates[tp] || this.lastDates[tp] < d)
      this.lastDates[tp] = d;
  }

  // tslint:disable-next-line:member-ordering
  private _dbOrder = { manual: 0, wos: 1, scopus: 2, cas: 3, pubmed: 4 };

  run() {
    this.keysMap.forEach(s => {
      let ls = [];
      const keepSet = new Set<string>();

      s.forEach(recordId => {
        if (this.flower.keepReferencesSet.has(recordId)) {
          keepSet.add(recordId);
        }
      });

      s.forEach(recordId => {
        if (!this.duplicatesSet.has(recordId)) {
          ls.push(this.recordsMap.get(recordId));
        }
      });


      if (!ls.length) {
        return;
      }


      ls.sort((a, b) =>
        this._dbOrder[a['_sourceDb']] < this._dbOrder[b['_sourceDb']] ? -1 : 1);

      ls.forEach(x => {
        if (!x['_duplicates']) {
          x['_duplicates'] = new Set<string>();
          delete x['searchCode']
        }

        ls.forEach(y => {
          if (x !== y) {
            x['_duplicates'].add(y['recordId'])
          }
        })
      });

      if (keepSet.size) {
        keepSet.forEach(x => this.mainSet.add(x));
        ls.forEach(x => {
          if (!keepSet.has(x['recordId'])) {
            this.duplicatesSet.add(x['recordId']);
          }
        });
        return;
      }


      let mainObj = ls.shift();
      this.mainSet.add(mainObj['recordId']);


      ls.forEach(x =>
        this.duplicatesSet.add(x['recordId']));
    });
    this.duplicatesSet.forEach(k => this.mainSet.delete(k));
    /*
        this.flower.abstractRatings.forEach(r =>
          r.ratedItems.forEach(rid => {
            if (this.duplicatesSet.has(rid))
              this.mainSet.add(rid);
          }));
    
        this.flower.fulltextRatings.forEach(r =>
          r.ratedItems.forEach(rid => {
            if (this.duplicatesSet.has(rid))
              this.mainSet.add(rid);
          }));
          */
  }

  private _exportObject(s: Set<string>) {
    let obj = {};
    s.forEach(recordId => {
      let dct = this.recordsMap.get(recordId);
      dct['_duplicates'] = Array.from(dct['_duplicates']);
      obj[recordId] = dct;
    });
    return obj;
  }

  private _stat(obj) {
    let dct = {};
    Object.keys(obj).forEach(k => {
      let o = obj[k];
      let s = o['_sourceDb'];
      dct[s] = (dct[s] || 0) + 1;
    });
    return dct;
  }

  private _criterionsObj(obj) {
    let ls = [];
    let c = this.rcs.criterion;
    let hits = {};
    Object.keys(obj).forEach(k => {
      let dbr = this.rcs.dbRecord(obj[k]);
      let w = c.run(dbr, this.flower);
      ls.push([dbr.id, w, dbr.year]);
      dbr.criterionItemMatches.forEach(cr => {
        let dbrLs = hits[cr.id];
        if (!dbrLs) {
          dbrLs = [];
          hits[cr.id] = dbrLs;
        }
        dbrLs.push(dbr.id);
      });
    });
    ls.sort((a, b) => {
      if (a[1] < b[1])
        return -1;
      if (a[1] > b[1])
        return 1;
      a[2] > b[2] ? -1 : 1;
    });
    ls.forEach(tpl => tpl.pop());
    return { weights: ls, hits: hits };
  }

  export(saveOffline?) {
    let s = new Subject<any>();
    let mainObj = this._exportObject(this.mainSet);
    let duplObj = this._exportObject(this.duplicatesSet);

    let statObj = {};
    let criterionsObj = this._criterionsObj(mainObj);

    let mainStat = this._stat(mainObj);


    let duplStat = this._stat(duplObj);
    let uniqueCounter = 0;

    Object.keys(mainStat).forEach(k => uniqueCounter += mainStat[k]);
    let totalCounter = uniqueCounter;
    Object.keys(duplStat).forEach(k => totalCounter += duplStat[k]);
    mainStat['unique'] = uniqueCounter;
    mainStat['total'] = totalCounter;
    mainStat['duplicates'] = duplStat;
    mainStat['flowerId'] = this.flower.id;

    let dtObj = {};
    mainStat['dates'] = dtObj;
    Object.keys(this.lastDates).forEach(k => {
      let d = this.lastDates[k];
      dtObj[k] = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    });


    Object.keys(mainObj).forEach(k => {
      let v = mainObj[k];
      delete v['keys'];
      delete v['_duplicates'];
      // console.log(v)
    });

    this.rcs._processingMessage = 'SAVING mainRecords';
    if (saveOffline) {
      this.utils.saveExport(mainObj, 'mainRecords.json');
      this.utils.saveExport(duplObj, 'duplRecords.json');
      this.utils.saveExport(mainStat, 'recordsStats.json');
      this.utils.saveExport(criterionsObj, 'criterions.json');
      return;
    }

    let o1 = this.rcs.export(this, mainObj, 'mainRecords.json').pipe(retry(3));
    o1.subscribe((resp) => {
      this.rcs._processingMessage = 'SAVING duplRecords';
      let o2 = this.rcs.export(this, duplObj, 'duplRecords.json').pipe(retry(3));
      o2.subscribe((resp) => {
        this.rcs._processingMessage = 'SAVING stats';
        let o3 = this.rcs.export(this, criterionsObj, 'criterions.json').pipe(retry(3));
        let o4 = this.rcs.export(this,
          mainStat,
          'recordsStats.json',
          { isFlowerStat: true }).pipe(retry(3));
 /*       let o5 = o3.merge(o4);
        o5 = o5.last();  */
        const o5 = new Observable();
        o5.subscribe((resp) => {
          this.rcs._processingMessage = null;
          s.next(true);
          s.complete();
        });
      });
    });

    return s;
  }



}
