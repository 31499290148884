import { Injectable } from '@angular/core';
import { DbRecord } from './db-record'

@Injectable()
export class CompoundHitsService {
  regexpLs: [RegExp, number][] = []

  constructor() { }

  init(ls) {
    ls.forEach((obj: Object) => {
      let nm = obj['extended_name'] || obj['name']
      if (!nm)
        return
      this.regexpLs.push([new RegExp(nm
        .replace(/\+/g, '\\+')
        .replace(/\s+/g, '\\s+'), 'ig'), obj['colour'] == 'Green' ? 1 : 2])
      //this.compound2typeMap.set(tpl[0], tpl[1])
    })
  }

  prepareDbRecords(ls: DbRecord[]) {
    ls.forEach(x => {
      this.regexpLs.forEach(rg => {
        if (x.regexpHit(rg[0]))
          x.compoundHits.push(rg)
      })
    })
  }

}
