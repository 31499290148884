import { Injectable } from '@angular/core';
import { FlowerService } from './flower.service'
import { UtilsService } from './utils.service'

@Injectable()
export class SearchQueueService {
  queue: Set<string>

  constructor(private fs: FlowerService,
    private utils: UtilsService
  ) {
    let ls = []
    try {
      ls = JSON.parse(localStorage.getItem('searchQueue'))
    } catch (e) { }
    this.queue = new Set<string>(ls)
  }

  isOn(tag: string) {
    return this.queue.has(tag)
  }

  switch(tag: string) {
    if (!this.queue.delete(tag))
      this.queue.add(tag)
    this.localStore()

  }

  clear() {
    this.queue.clear()
    this.localStore()
  }

  localStore() {
    localStorage.setItem('searchQueue',
      JSON.stringify(Array.from(this.queue)))
  }

  get queuedFlowers() {
    let ls = []
    this.queue.forEach(x => {
      ls.push(this.fs.get(x))
    })
    ls.sort((a, b) => a.mainName[0] < b.mainName[0] ? -1 : 1)
    return ls
  }

  generate() {
    let sss = new Set<string>()
    this.queuedFlowers.forEach(f =>
      f.searches.forEach(t => sss.add(t)))
    let ls = Array.from(sss)
    ls.sort((a,b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)

    let payload = {
      id: this.utils.uniqueIdentifier,
      quoteAllSearches: true,
      date: (new Date()).toISOString(),
      db: ['wos', 'scopus', 'scifinder', 'pubmed'],
      searches: ls
    }

    let hiddenElement = document.createElement('a');

    hiddenElement.href = 'data:attachment/text,' + encodeURI(JSON.stringify(payload));
    hiddenElement.target = '_blank';
    hiddenElement.download = `batchSearch-${payload['id']}.json`;
    hiddenElement.click();
    hiddenElement.remove()
  }
}
