import { Component, OnInit, Input } from '@angular/core';
import { DbRecord } from '../db-record'
import { StatusService } from '../status.service'
import { AbstractRating } from '../abstract-rating'

@Component({
  selector: 'app-tag-abstract-rating',
  templateUrl: './tag-abstract-rating.component.html',
  styleUrls: ['./tag-abstract-rating.component.css']
})
export class TagAbstractRatingComponent implements OnInit {
  @Input() dbRecord: DbRecord
  @Input() label: string
  @Input() value: string
  @Input() tp: number // -1 => reject; 0 => initial; 1 => accept
  @Input() abstractRating: AbstractRating

  constructor(private ss: StatusService) { }

  ngOnInit() {
  }

  get isSelected() {
    let ar = this.abstractRating.getItemRating(this.dbRecord)
    if (!ar)
      return this.value == 'not_rated'
    return ar == this.value
  }

  switch() {
    this.abstractRating.setItemRating(this.dbRecord, this.value)
  }

}
