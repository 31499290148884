export class ExportItem {
  private _rawType: string[] = []
  private _rawTitle: string[] = []
  private _rawAbstract: string[] = []
  private _rawKeyword: string[] = []
  private _rawAuthor: string[] = []
  private _rawFullAuthor: string[] = []
  private _rawLang: string[] = []
  private _rawMisc: string[] = []
  private _rawVolume: string
  private _rawIssue: string
  private _rawStartPage: string
  private _rawEndPage: string
  private _rawYear: string[] = []
  private _rawOtherDate: string[] = []
  private _rawDOI: string[] = []
  private _rawISSN: string[] = []
  private _rawISBN: string[] = []
  private _rawAddress: string[] = []
  private _rawSource: string[] = []
  private _rawConference: string[] = []
  private _rawAbbrevSource: string[] = []
  private _rawPublisher: string[] = []
  private _rawWorkType: string[] = []
  private _rawRecordID: string
  private _rawDbName: string
  private _applicationNumber: string
  private _priorityNumber: string
  private _publicationNumber: string
  private _ipc: string
  private _cpc: string
  private dbType: string
  private searchCode: string

  constructor(arg: Object) {
    this.dbType = arg['tp']
  }


  set recordId(txt: string) {
    this._rawRecordID = txt
  }

  set dbName(txt: string) {
    this._rawDbName = txt
  }

  set volume(txt: string) {
    this._rawVolume = txt
  }

  set issue(txt: string) {
    this._rawIssue = txt
  }

  set startPage(txt: string) {
    this._rawStartPage = txt
  }

  set endPage(txt: string) {
    this._rawEndPage = txt
  }

  set year(txt: string) {
    this._rawYear.push(txt)
  }

  set otherDate(txt: string) {
    this._rawOtherDate.push(txt)
  }

  set conference(txt: string) {
    this._rawConference.push(txt)
  }

  set workType(txt: string) {
    this._rawWorkType.push(txt)
  }

  set source(txt: string) {
    this._rawSource.push(txt)
  }

  set abbrevSource(txt: string) {
    this._rawAbbrevSource.push(txt)
  }

  set publisher(txt: string) {
    this._rawPublisher.push(txt)
  }

  set doi(txt: string) {
    this._rawDOI.push(txt)
  }

  set address(txt: string) {
    this._rawAddress.push(txt)
  }

  set issn(txt: string) {
    this._rawISSN.push(txt)
  }

  set isbn(txt: string) {
    this._rawISBN.push(txt)
  }

  set title(txt: string) {
    this._rawTitle.push(txt)
  }

  set abstract(txt: string) {
    this._rawAbstract.push(txt)
  }

  set keyword(txt: string) {
    this._rawKeyword.push(txt)
  }

  set author(txt: string) {
    this._rawAuthor.push(txt)
  }

  set fullAuthor(txt: string) {
    this._rawFullAuthor.push(txt)
  }

  set lang(txt: string) {
    this._rawLang.push(txt)
  }

  set misc(txt: string) {
    this._rawMisc.push(txt)
  }

  set type(txt: string) {
    this._rawType.push(txt)
  }

  set applicationNumber(txt: string) {
    this._applicationNumber = txt
    this.recordId = txt
  }

  set priorityNumber(txt: string) {
    this._priorityNumber = txt
  }

  set publicationNumber(txt: string) {
    this._publicationNumber = txt
  }

  set ipc(txt: string) {
    this._ipc = txt
  }

  set cpc(txt: string) {
    this._cpc = txt
  }

  get ipc() {
    if (!this._ipc)
      return null
    return this._ipc.replace(/ /g, '')
  }

  get cpc() {
    if (!this._cpc)
      return null
    return this._cpc.replace(/ /g, '')
  }

  get priorityNumber() {
    return this._priorityNumber
  }

  get applicationNumber() {
    return this._applicationNumber
  }

  get publicationNumber() {
    return this._publicationNumber
  }

  get title() {
    let title = this._rawTitle[0]
    if (this._rawTitle.length > 1)
      title += ` [${this._rawTitle.slice(1).join(' ')}]`
    if (!title)
      return ''
    return title.replace(/\s+/g, ' ').trim()
  }

  get abstract() {
    return this._rawAbstract.join(' ').replace(/\s+/g, ' ').trim()
  }

  get authors() {
    let ls = []
    this._rawFullAuthor.forEach((x) => ls.push(x))
    if (!ls.length)
      this._rawAuthor.forEach((x) => ls.push(x))
    return ls
  }

  stringOrList(ls: string[]): (string | string[]) {
    if (!ls.length)
      return null
    return ls.length == 1 ? ls[0] : ls
  }

  get dois() {
    return this.stringOrList(this._rawDOI)
  }

  get langs() {
    let ls = []
    this._rawLang.forEach((x) => {
      let lang = x.slice(0, 3).toLowerCase()
      ls.push(lang)
    })
    return this.stringOrList(ls)
  }

  get volume() {
    return this._rawVolume
  }

  get issue() {
    return this._rawIssue
  }

  get startPage() {
    return this._rawStartPage
  }

  get endPage() {
    return this._rawEndPage
  }

  get issns(): (string | string[]) {
    let ls = []
    this._rawISSN.forEach((x) => {
      x = x.replace(/[^0-9xX]/g, '')
      ls.push(`${x.slice(0, 4)}-${x.slice(4)}`)
    })
    return this.stringOrList(ls)
  }

  get source() {
    if (this._rawSource.length)
      return this._rawSource.join(' ')
    return this._rawAbbrevSource.join(' ')
  }

  get year() {
    let y = this._rawYear[0]
    let fy = ''
    if (y)
      fy = y.slice(0, 4)
    if ((/\d{4}/).test(fy))
      return fy
    //console.log("YEAR PROBLEM", this._rawYear)
    //console.log("OTHER DATES", this._rawOtherDate)
    return null
  }

  get recordId() {
    if (this.dbType == 'scopus')
      return this._rawRecordID.split('?').pop().split('&')[0]
    return this._rawRecordID
  }

  get _sourceDb() {
    return this.dbType
  }

  export() {
    let dct = {}
    let ls = [
      'recordId', '_sourceDb',
      'title', 'abstract', 'authors',
      'doi', 'issn', 'lang',
      'source', 'volume', 'issue',
      'startPage', 'endPage',
      'applicationNumber', 'priorityNumber', 'publicationNumber',
      'ipc', 'cpc'
    ]

    ls.forEach((x) => {
      try {
        let v = this[x] || this[x + "s"]
        if (v) {
          if (v.length)
            dct[x] = v
        }
      }
      catch (e) {
        console.log("!!!!!!", e)
        console.log(x, this)
      }

    })
    
    if (this.year)
      dct['year'] = parseInt(this.year)

    if (this._sourceDb == 'espacenet')
      dct['priorityDate'] = this._rawOtherDate[0]
    dct['searchCode'] = this.searchCode

    this.duplicateKeys(dct)

    return dct
    //console.log(dct)
  }

  duplicateKeys(dct) {

    let title = (dct['title'] || '').toLowerCase().replace(/[^a-z0-9]/g, '').slice(0, 75)
    let startPage = dct['startPage']
    let source = (dct['source'] || '').toLowerCase().replace(/\&/g, 'and').replace(/[^a-z]/g, '')
    let year = dct['year']
    let ls = [this.recordId]

    if (dct['doi'])
      ls.push(dct['doi'])

    if (dct['volume'] && year && startPage)
      ls.push(`${startPage}-${dct['volume']}-${dct['issue'] || '_'}-${year}`)

    if (title && startPage)
      ls.push(`${startPage}-${title}`)

    if (year && startPage && dct['authors'] && dct['authors'].length) {
      try {
        let auth = dct['authors'][0].toLowerCase().replace(/[-']/g, '').replace(/[^a-z]/g, '|').split('|')[0]
        ls.push(`${auth}-${dct['volume'] || '_'}-${dct['authors'].length}-${year}-${startPage}`)
      } catch (e) {
        console.log("EEE", e)
      }
    }

    if (year && source && startPage)
      ls.push(`${source}-${year}-${dct['volume'] || '_'}-${startPage}`)

    dct['keys'] = ls

  }

  finalize(ls: Object[]) {
    if (!this._rawEndPage && this._rawStartPage) {
      let tpl = this._rawStartPage.split('-')
      if (tpl.length == 2) {
        this._rawStartPage = tpl[0].trim()
        this._rawEndPage = tpl[1].trim()
      }
    }

    ls.push(this.export())
  }
}
