import { Injectable } from '@angular/core';
import { Flower } from './flower'
import { UploadSearchData } from './upload-search-data'


@Injectable()
export class UploadSearchService {
  flowerDataMap = new Map<string, UploadSearchData>()

  constructor() { }

  prepareData(flower: Flower, filename: string) {
    let usd = new UploadSearchData(flower, filename)
    return usd
  }

}
