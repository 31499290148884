import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-search-edit-box',
  templateUrl: './search-edit-box.component.html',
  styleUrls: ['./search-edit-box.component.css']
})
export class SearchEditBoxComponent implements OnInit {
  @Input() obj: Object

  constructor() { }

  ngOnInit() {
    //console.log(123, this.obj)
  }

  get searchTerm() {
    return this.obj['searchTerm']
  }


  set searchTerm(txt) {
    this.obj['searchTerm'] = txt.trim()
  }



}
