<div class='adminDashboard'>
  <div class='main'
       *ngIf='isAdmin'>


    <div class='adminTitle'>Admin</div>
    <div *ngIf='!fs.currentFlower'>
      <div>
        <a routerLink="/flowers/edit/__new__"
           routerLinkActive="current">New Flower</a>
      </div>

      <div><a routerLink="/admin/editors">Editors</a></div>
      <div><a routerLink="/admin/backup">Backup</a></div>
    </div>

    <div *ngIf='fs.currentFlower'>
      <div><a
           href='https://console.cloud.google.com/storage/browser/ecomole-test.appspot.com/combo_search/{{fs.currentFlower.id}}/?project=ecomole-test'
           target='_blank'>Search files at GAE</a></div>

      <div>
        <div><a
             href='https://console.cloud.google.com/storage/browser/ecomole-test.appspot.com/combo_json/{{fs.currentFlower.id}}/?project=ecomole-test'
             target='_blank'>JSON files at GAE</a></div>

        <div>
          <button id='rmDatasetButton'
                  (click)='ratingManagerDataset()'>RM dataset</button>
        </div>

        <div>
          <button id='rmSupportButton'
                  (click)='ratingManagerSupport()'>RM support</button>
        </div>

        <div>
          <button id='rmFilesButton'
                  (click)='ratingManagerFiles()'>RM files</button>
        </div>




        <div>
          <button (click)='fs.sendToSimek()'>Poslat Šimkovi</button>
        </div>

      </div>
    </div>

    <div *ngIf='hasExtension'
         style='border-top: thick double white;
           margin-top: 1ex;
           padding-top: 1ex;
      '>
      <h4>EXTENSION</h4>
      <div>Flowers: {{extensionTotalFlowers}}</div>
      <div>S-cache: {{supportCache}}</div>
      <div *ngIf='!fs.currentFlower'>
        <div>
          <button (click)='clearCache()'>Clear cache</button>
        </div>
        <div>
          <button (click)='setFlowers()'>Set flowers</button>
        </div>
        <div>
          <button (click)='run("support")'>Run support</button>
        </div>
      </div>
    </div>
  </div>
</div>