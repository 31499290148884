import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../utils.service'
import { FlowerService } from '../flower.service'
import { ParserService } from '../parser.service'
import { RecordsCollectionService } from '../records-collection.service'
import { FamilyService } from '../family.service';
import { StatusService } from '../status.service';
import { ArcService } from '../arc.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  saveOffline = false
  hasExtension = false
  extensionTotalFlowers;
  supportCache;

  constructor(
    private router: Router,
    private utils: UtilsService,
    public fs: FlowerService,
    public ps: ParserService,
    private fmls: FamilyService,
    public rcs: RecordsCollectionService,
    private ss: StatusService,
    private arcs: ArcService,
  ) {
    window.addEventListener('message', e => {
      const params = e['data']
      if (!params['action']) {
        return
      }
      switch (params['action']) {
        case 'navigate':
          const fl = this.fs.get(params['flowerId'])
          if (fl) {
            return this.router.navigate(fl.navigateTuple)
          }
          return this.router.navigate(['flowers', 'list'])
        case 'pingExtensionReply':
          this.hasExtension = true;
          this.extensionTotalFlowers = params['totalFlowers']
          this.supportCache = params['supportCache']
          break;
        case 'getSupportRM':
          window.postMessage({
            action: 'supportRM',
            payload: this.ratingManagerSupport(true)
          }, "*")
          break;
      }
    });
    console.log('ADDED Event Listener')
  }

  ngOnInit() {
    window.postMessage({ action: 'pingExtension' }, "*");
  }

  clearCache() {
    window.postMessage({ action: 'clearCache' }, "*");
  }

  setFlowers() {
    const payload = this.fs.currentFlowerList.map(x => {
      return { flowerId: x.id, path: x.navigateTuple.join('/') }
    })
    window.postMessage({
      action: 'setFlowers',
      payload: payload
    }, "*");
  }

  run(tp) {
    const types = [tp]
    window.postMessage({
      action: 'run',
      types: types
    }, "*");
  }

  get isAdmin() {
    return this.utils.isAdmin;
  }

  private _save(fn: string, data: any) {
    const hidElId = '_saveDialog';
    const el = document.getElementById(hidElId);
    if (el) {
      el.remove();
    }
    const hiddenElement = document.createElement('a');
    hiddenElement.id = hidElId;
    data = JSON.stringify(data);
    const type = 'application/json';

    hiddenElement.href = URL.createObjectURL(new Blob([
      data
    ], { type: type }));
    hiddenElement.target = '_blank';
    hiddenElement.download = fn;
    hiddenElement.click();
    hiddenElement.remove();
  }

  itemNotes(dbr) {
    const flower = this.fs.currentFlower
    const ls = [];
    flower.notes.forEach((x) => {
      let fn = x.itemNote(dbr);
      if (fn)
        ls.push({
          date: fn.date,
          text: fn.text,
          editor: fn.editor && fn.editor.email
        })
    });
    return ls
  }

  abstractRatings(dbr) {
    const flower = this.fs.currentFlower
    const ls = []
    flower.abstractRatings.forEach(r => {
      const rVal = r.getItemRating(dbr)
      if (rVal) {
        const s = (this.ss.getAbstractObject(rVal)||{})['acceptance']
        ls.push({
          author: r.editor.id,
          isEFSA: r.editor.isEFSAeditor,
          reason: rVal,
          status: s === 1 ? 'accept' : 'reject'
        })
      }
    })
    return ls
  }

  fulltextRatings(dbr) {
    const flower = this.fs.currentFlower
    const ls = []
    flower.fulltextRatings.forEach(r => {
      const rVal = r.getItemRating(dbr)
      if (rVal) {
        const obj = this.ss.getFulltextObject(rVal)
        const ms = obj['mainStatus']['value']
        const reasons = obj['reasons'].map(x => x['value'])

        ls.push({
          author: r.editor.id,
          isEFSA: r.editor.isEFSAeditor,
          status: ms,
          reasons: reasons
        })
      }
    })
    return ls
  }



  get itemsManagerSupport() {
    const items = []
    const flower = this.fs.currentFlower

    flower.allDbRecords.forEach(dbr => {
      const dct = {
        id: dbr.id,
        notes: this.itemNotes(dbr),
        abstractRatings: this.abstractRatings(dbr),
        fulltextRatings: this.fulltextRatings(dbr),
      }
      items.push(dct)
    })
    return items
  }

  get abstractsTree() {
    const baseId = `${this.fs.currentFlower.id}-abs`
    const dct = {
      id: baseId,
      title: 'Combo abstracts',
      type: 'rating',
      tree: this.ss.abstractTree(baseId)
    }
    return dct
  }

  get fulltextTree() {
    const baseId = `${this.fs.currentFlower.id}-full`
    const dct = {
      id: baseId,
      title: 'Combo fulltexts',
      type: 'rating',
      tree: this.ss.fulltextTree(baseId)
    }
    return dct
  }

  private _checkFiles(records, ls, step, i) {
    const flower = this.fs.currentFlower
    const ids = records.slice(i, i + step)

    if (ids.length) {
      console.log(i)
      let obs = this.arcs.run({
        fun: 'checkFulltexts',
        ids: ids
      })

      obs.subscribe(resp => {
        resp = resp[0];
        for (let k of Object.keys(resp)) {
          if (resp[k]) {
            ls.push({ itemId: k, filename: resp[k] })
          }
        }
        this._checkFiles(records, ls, step, i + step)
      })
    } else {
      this._save(`files-${flower.mainName[0].replace(/\s+/g, '_')}-${flower.id}`, ls);
    }
  }

  ratingManagerFiles() {
    const flower = this.fs.currentFlower
    const dbRecords = flower.allDbRecords.map(x => x.id)
    const step = 50;
    const files = []

    this._checkFiles(dbRecords, files, 50, 0)

    /* const outDct = {
      flowerId: flower.id,
      flowerNames: flower.names,
      flowerNotes: this.flowerNotes,
      flowerFamily: this.fmls.familyForFlower(flower),
      itemLs: this.itemsManagerSupport,
      abstractsTree: this.abstractsTree,
      fulltextTree: this.fulltextTree
    }
    this._save(`support-${flower.mainName[0].replace(/\s+/g, '_')}-${flower.id}`, outDct); */
  }

  ratingManagerSupport(isMessage?) {
    const flower = this.fs.currentFlower
    const outDct = {
      flowerId: flower.id,
      flowerNames: flower.names,
      flowerNotes: this.flowerNotes,
      flowerFamily: this.fmls.familyForFlower(flower),
      itemLs: this.itemsManagerSupport,
      abstractsTree: this.abstractsTree,
      fulltextTree: this.fulltextTree
    }
    if (isMessage) {
      return outDct
    }
    this._save(`support-${flower.mainName[0].replace(/\s+/g, '_')}-${flower.id}`, outDct);
  }

  ratingManagerDataset() {
    const flower = this.fs.currentFlower
    const itemLs = []
    flower.allDbRecords.forEach(dbr => itemLs.push(dbr.ratingMangerData))
    const stats = this.fs.searchStats[flower.id]
    const dates = stats['dates'] || {}
    const duplicates = stats['duplicates'] || {}

    const tpl = ['ecomole_items_v1', {
      type: 'dataset',
      title: flower.mainName.join(' '),
      dbMeta: {
        wos: {
          "lastDate": dates['wos'],
          "count": stats['wos'] + duplicates['wos']
        },
        scopus: {
          "lastDate": dates['scopus'],
          "count": stats['scopus'] + duplicates['scopus']
        },
        pubmed: {
          "lastDate": dates['pubmed'],
          "count": stats['pubmed'] + duplicates['pubmed']
        },
        scifinder: {
          "lastDate": dates['scifinder'],
          "count": stats['cas'] + duplicates['cas']
        },
      },
      id: flower.id,
      itemLs: itemLs
    }]
    this._save(`dataset-${flower.mainName[0].replace(/\s+/g, '_')}-${flower.id}`, tpl);
  }

  get flowerNotes() {
    const flower = this.fs.currentFlower
    let ls = [];
    flower.notes.forEach((x) => {
      let fn = x.flowerNote;
      if (fn) {
        ls.push({
          date: fn.date,
          text: fn.text,
          editor: fn.editor && fn.editor.email
        });
      }
    });
    return ls;
  }

}
