import { Injectable } from '@angular/core';
import { DbRecord } from './db-record'

@Injectable()
export class SortService {
  items = []

  constructor() { }

  run(ls: DbRecord[]): DbRecord[] {
    if (!this.items.length)
      this.items=['mainCriterion']

    ls.sort((a, b) => {
      let aTuple = a.sortTuple(this.items)
      let bTuple = b.sortTuple(this.items)

      for (var i = 0; i < this.items.length; i++)
        if (aTuple[i] < bTuple[i])
          return -1
        else if (aTuple[i] > bTuple[i])
          return 1

      return a.criterionPosition < b.criterionPosition ? -1 : 1;
    })
    return ls
  }



  switch(tp: string, forSecondary?: boolean): void {
    if (forSecondary) {
      let i = this.items.indexOf(tp)
      if (i > -1)
        this.items.splice(i, 1)
      else
        this.items.push(tp)
    } else if (!this.items.length || this.items[0] != tp)
      this.items = [tp]
    else
      this.items = []
  }

  position(tp: string): number {
    return this.items.indexOf(tp)
  }

}
