import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../utils.service'

@Component({
  selector: 'app-unknown-user',
  templateUrl: './unknown-user.component.html',
  styleUrls: ['./unknown-user.component.css']
})
export class UnknownUserComponent implements OnInit {

  constructor(
    private utils: UtilsService
  ) { }

  ngOnInit() {
  }

  get email() {
    return this.utils.email
  }

}
