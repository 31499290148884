<span class='tag'
    *ngIf='isMain'
    [class.selected]="isSelected"
    (click)='switch()'>
  {{label}}
</span>

<label [class.checked]='isSelected'
    *ngIf='!isMain'>

  <input type='checkbox'
  [checked]='isSelected'
  (click)='switch()'>
  {{label}}
</label>
