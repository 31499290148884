// tslint:disable:curly

import { Component, OnInit } from '@angular/core';
import { FlowerService } from '../flower.service';
import { EditorService } from '../editor.service';
import { PageService } from '../page.service';
import { StatusService } from '../status.service';
import { FamilyService } from '../family.service';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-flower-list',
  templateUrl: './flower-list.component.html',
  styleUrls: ['./flower-list.component.css']
})
export class FlowerListComponent implements OnInit {
  nameFilter: RegExp;
  showFamilies = false;
  listCheck = 'all';
  codedCheck = 'all';
  private _uniqueSortedLs = null;
  public isNoOwner = false;

  private _noSearch = new Map<string, boolean>();


  constructor(public fs: FlowerService,
    public pgs: PageService,
    private ss: StatusService,
    public es: EditorService,
    private fmls: FamilyService,
    public utils: UtilsService,
  ) { }

  ngOnInit() {
  }

  switchNoSearch(db) {
    this._noSearch.set(db, !this._noSearch.get(db));
  }

  switchShowFamilies() {
    this.showFamilies = !this.showFamilies;
  }

  filterChange(txt) {
    if (!txt) {
      this.nameFilter = null;
    }
    txt = txt.split(' ').slice(0, 2).join(' ');
    this.nameFilter = new RegExp(txt, 'i');
    this.pgs.currentStart = 0;
  }

  get families() {
    let ls = [];
    this.fmls.families.forEach((x) => ls.push([x, `${x} (${this.fmls.flowerCount(x)})`]));
    return ls;
  }



  get filteredList() {
    let ls = this.fs.list;

    ls = ls.filter((fl) =>
      fl.nameTest(this.nameFilter));

    if (this.pgs.isOwnerFilterOn)
      ls = ls.filter((x) => this.pgs.isOwnerFilter(this.es.getOwner(x)));
    if (this.pgs.isAssigneeFilterOn)
      ls = ls.filter((x) => this.pgs.isAssigneeFilter(this.es.getAssignee(x)));

    if (this.pgs.isFlowerStatusFilterOn)
      ls = ls.filter((x) => this.pgs.isFlowerStatusFilter(this.ss.flowerStatus(x)));
    if (this.pgs.isOnFamilyFilter)
      ls = ls.filter((x) => this.pgs.isCurrentFamilyFilter(this.fmls.familyForFlower(x)));

    //  console.log(433, this.fs.searchStats)

    if (this.codedCheck === 'yes')
      ls = ls.filter(f => f.hasCodedData);
    else if (this.codedCheck === 'no')
      ls = ls.filter(f => !f.hasCodedData);

    if (this.listCheck === 'white')
      ls = ls.filter(f => f.isWhite);
    else if (this.listCheck === 'novelFoods')
      ls = ls.filter(f => f.isNovelFoods);
    else if (this.listCheck === 'yellow')
      ls = ls.filter(f => !f.isWhite &&
        !f.isNovelFoods &&
        !f.isInsect &&
        !f.isAlgae &&
        !f.isFungi &&
        !f.isCompound);
    else if (this.listCheck === 'insect')
      ls = ls.filter(f => f.isInsect);
    else if (this.listCheck === 'algae')
      ls = ls.filter(f => f.isAlgae);
    else if (this.listCheck === 'fungi')
      ls = ls.filter(f => f.isFungi);
    else if (this.listCheck === 'compound')
      ls = ls.filter(f => f.isCompound);

    if (this.isNoSearch('scopus'))
      ls = ls.filter(f => {
        let s = this.fs.searchStats[f.id];
        if (!s)
          return true;
        if (s['scopus'])
          return false;
        if (s['duplicates'] && s['duplicates']['scopus'])
          return false;
        return true;
      });

    if (this.isNoSearch('cas'))
      ls = ls.filter(f => {
        let s = this.fs.searchStats[f.id];
        if (!s)
          return true;
        if (s['cas'])
          return false;
        if (s['duplicates'] && s['duplicates']['cas'])
          return false;
        return true;
      });

    if (this.isNoOwner)
      ls = ls.filter(f => !this.es.getOwner(f));

    this.fs.currentFlowerList = ls;

    if (this.pgs.isOnSortFlowersByUnique) {
      const sortedLs = ls.slice()
      let dct = {};
      sortedLs.forEach(x => dct[x.id] = x.uniqueRecordsCounter);
      sortedLs.sort((a, b) => dct[a.id] < dct[b.id] ? -1 : 1);
      return sortedLs
    } else {
      return ls;
    }

  }


  isNoSearch(db) {
    return !!this._noSearch.get(db);
  }

  clearFamily() {
    this.pgs.clearFamily();
  }

  get familyFilterValue() {
    return this.pgs.familyFilterValue;
  }

  get list() {
    return this.pgs.getFlowersPage(this.filteredList);
  }


  get statuses() {
    return this.ss.flowerStatusValues;
  }
}
