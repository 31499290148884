import { Component, OnInit, Input } from '@angular/core';
import { AbstractRating } from '../abstract-rating'
import { DbRecord } from '../db-record'
import { StatusService } from '../status.service'
import { UtilsService } from '../utils.service'
import { AbstractRatingService } from '../abstract-rating.service';
// import {Flower} from '../flower'

@Component({
  selector: 'app-abstract-rating-box',
  templateUrl: './abstract-rating-box.component.html',
  styleUrls: ['./abstract-rating-box.component.css']
})
export class AbstractRatingBoxComponent implements OnInit {
  @Input() abstractRating: AbstractRating
  @Input() dbRecord: DbRecord
  title: string
  subtitle: string
  _status: number
  isOwner: boolean
  removeMode = false
  date: String;
  

  constructor(
    private ss: StatusService,
    public utils: UtilsService,
    private ars: AbstractRatingService
  ) { }

  ngOnInit() {
    this.isOwner = !!this.abstractRating.editor && this.utils.email === this.abstractRating.editor.email;
    const stmp = this.ars.stamps && this.ars.stamps[this.dbRecord.id];
    if (stmp) {
      const dt = stmp[this.abstractRating.editor.email];
      if (dt) {
        this.date = dt.split('T')[0];
      }
    }
  }

  get stamp() {
      return this.date ? ` [${this.date}]` : '';
  }

  get isEFSAeditor() {
    return !!this.abstractRating.editor && this.abstractRating.editor.isEFSAeditor
  }

  removeRatingSwitch() {
    this.removeMode = !this.removeMode
  }

  removeConfirmed() {
    this.abstractRating.adminRemove(this.dbRecord)
  }

  get checkStatus() {
    let obj = this.ss
      .getAbstractObject(this.abstractRating.getItemRating(this.dbRecord))
    if (obj && obj['value'] !== 'not_rated') {
      this.title = obj['title']
      this.subtitle = obj['subtitle']
      this._status = obj['acceptance']
      return true
    }
    this.title = ''
    this.subtitle = ''
    return false
  }

  get status() {
    if (this.isRejected)
      return "REJECTED"
    if (this.isAccepted)
      return "ACCEPTED"
    return "???"
  }

  get isAccepted() {
    return this._status === 1
  }

  get isRejected() {
    return this._status === -1
  }

}
