import { Injectable } from '@angular/core';
import { ArcService } from './arc.service'
import { FlowerService } from './flower.service'
import { UtilsService } from './utils.service';

@Injectable()
export class BackupService {
  backupRunning = false
  backupMessage = ''
  flowerSet: Set<string>
  hasMissingID = false
  private _backupLs
  private _backupStep = 50
  private _backupData

  constructor(
    private arcs: ArcService,
    private fs: FlowerService,
    private utils: UtilsService
  ) {
    /*
    let storage = localStorage.getItem('backupFlowerLs')
    this.flowerSet = new Set<string>(storage ?
      JSON.parse(storage) :
      this.fs.list.map(x => x.id))
      */
  }

  startBackup() {
    this._backupLs = this.fs.list.map(x => x.id)
    this._backupData = {}

    let obs = this.arcs.run({
      fun: "getComboBackup",
    })
    obs.subscribe(resp => {
      this._backupData['_comboData'] = resp[0]
      this.runBackup()
    })
    let gaedir = '/combo_json/_combo'

    let obs2 = this.arcs.gaeDownload(`${gaedir}/flowerList2.json`)
    obs2.subscribe(resp => {
      this._backupData['_flowers'] = resp
      let obs3 = this.arcs.gaeDownload(`${gaedir}/families.json`)
      obs3.subscribe(resp => this._backupData['_families'] = resp)
    })
  }

  runBackup() {
    if (!this._backupLs.length) {
      this.utils.saveExport(this._backupData,
        `backup_${(new Date()).toISOString().split('T')[0]}.json`)
      return this.backupMessage = "BACKUP FINISHED"
    }

    else
      this.backupMessage = `Remains flowers: ${this._backupLs.length}`

    let i = this._backupLs.length - this._backupStep
    if (i < 0)
      i = 0
    let obs = this.arcs.run({
      fun: "getFlowersBackup",
      flowers: JSON.stringify(this._backupLs.splice(i))
    })
    obs.subscribe(resp => {
      resp[0].forEach(tpl => {
        this._backupData[tpl[0]] = tpl[1]
      });
      this.runBackup()
    })

  }

  /*
    get backupDir() {
      let d = new Date()
      return `combo2backup-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    }
    */
  /*
    checkBackup() {
      this.backupRunning = true
      this.backupMessage = "CHECKING BACKUP"
      let obs = this.arcs.run({
        fun: "checkBackup",
        backupDir: this.backupDir
      })
      obs.subscribe((resp) => {
        let hasComboData = false
        let allFlowersSet = new Set<string>(this.fs.list.map(x => x.id))
        let flowerCounter = 0
        resp[0].forEach(x => {
          let code = x['filename'].split('/').pop().split('.')[0]
          if (code == 'comboData')
            hasComboData = true
          else {
            if (allFlowersSet.delete(code))
              flowerCounter++
          }
        })
        this.backupMessage = `
        <div>Combo data present: ${hasComboData}</div>
        <div>Total flowers: ${this.fs.list.length}</div>
        <div>Flowers with backup: ${flowerCounter}</div>
        <div>Missing flowers: ${allFlowersSet.size}</div>
        `
        console.log("COMBO DATA CHECK:", hasComboData)
        console.log("ALL FLOWERS:", this.fs.list.length)
        console.log("FLOWERS: ", flowerCounter)
        console.log("MISSING ID", allFlowersSet)
        this.hasMissingID = !!allFlowersSet.size
        if (this.hasMissingID)
          this.flowerSet = allFlowersSet
      })
    }

    startBackup() {
      this.backupRunning = true
      this.backupMessage = "STARTED"
      let obs = this.arcs.run({
        fun: "backupComboData",
        backupDir: this.backupDir
      })
      obs.subscribe((x) => {
        this.backupFlower()
      })
    }

    backupFlower() {
      let ls = Array.from(this.flowerSet)
      this.backupMessage = "REMAINS FLOWERS " + ls.length

      let flowerId = ls.pop()
      if (!flowerId) {
        console.log("FINISH")
        this.backupMessage = "BACKUP FINISHED"
        this.backupRunning = false
        return
      }


      let obs = this.arcs.run({
        fun: "backupFlower",
        backupDir: this.backupDir,
        flower: flowerId
      })
      obs.subscribe((x) => {
        this.flowerSet.delete(flowerId)
        localStorage.setItem('backupFlowerLs', JSON.stringify(ls))
        this.backupFlower()
      })
    }
  */
}
