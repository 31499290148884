import { Injectable } from '@angular/core';
import { DbRecord } from './db-record'
import { CrossrefRecord } from './crossref-record'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CrossrefService {

  constructor(
    private http: HttpClient
  ) { }

  doiRun(dbRecord: DbRecord) {
    let obs = this.http.get(`https://api.crossref.org/works/${dbRecord.data['doi']}`)
    let ls = []
    obs.subscribe(resp => {
      ls.push(new CrossrefRecord(resp['message']))
      console.log(ls)
    })

    return [obs, ls]
  }

  titleRun(dbRecord: DbRecord) {
    let ls = []
    let title = dbRecord.data['title']
      .replace(/[^A-Za-z0-9]/g, ' ').trim().toLowerCase()
      .replace(/\s+/g, '+')
    let obs = this.http.get(`https://api.crossref.org/works?query=${title}&rows=10`)
    obs.subscribe(resp =>
      resp['message']['items'].forEach(itm => {
        ls.push(new CrossrefRecord(itm))
      }))
      return [obs, ls]
  }

  issnRun(issn: string) {
    let ls = []
    let obs = this.http.get(`https://api.crossref.org/journals/${issn}`)
    obs.subscribe(resp => {
      console.log(888, resp['message'])
    })
    return [obs, ls]
  }


}
