import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Flower } from '../flower'
import { FlowerService } from '../flower.service'
import { UtilsService } from '../utils.service'

@Component({
  selector: 'app-flower-edit',
  templateUrl: './flower-edit.component.html',
  styleUrls: ['./flower-edit.component.css']
})
export class FlowerEditComponent implements OnInit {
  flower: Flower
  comment: string
  names: Object[] = [];
  searches: Object[] = [];
  isNewFlower: boolean;
  isWhite: boolean;
  isNovelFoods: boolean;
  isInsect: boolean;
  isAlgae: boolean;
  isFungi: boolean;
  isCompound: boolean;
  deleteFlowerPrepared = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public fs: FlowerService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let flowerId = params['id'];
      if (flowerId === '__new__') {
        this.flower = this.fs.newFlower();
        this.isNewFlower = true;
      } else
        this.flower = this.fs.get(flowerId);
      if (!this.flower)
        return this.router.navigate(['flowers', 'list']);
      this.flower.names.map((nm) => this.names.push({ name: nm[0], describedBy: nm[1] }));
      this.flower.searches.map((st) => this.searches.push({ searchTerm: st }));
      this.comment = this.flower.comment;
      this.isWhite = this.flower.isWhite;
      this.isNovelFoods = this.flower.isNovelFoods;
      this.isInsect = this.flower.isInsect;
      this.isAlgae = this.flower.isAlgae;
      this.isFungi = this.flower.isFungi;
      this.isCompound = this.flower.isCompound;
    });
  }

  deleteFlower() {
    this.deleteFlowerPrepared = true;
  }

  confirmDelete() {
    let obs = this.fs.deleteFlower(this.flower);
    obs.subscribe((x) => this.deleteFlowerPrepared = false);
    this.router.navigate(['/flowers/list']);
  }

  cancel() {
    this.router.navigate(['/flowers/list']);
  }

  cancelDelete() {
    this.deleteFlowerPrepared = false;
  }

  newSynonym() {
    this.names.push({ name: '' });
  }

  newSearch(text?: string) {
    this.searches.push({ searchTerm: text || '' });
  }

  asSearch(position: number) {
    this.newSearch(this.fs.normalize_search(this.names[position]['name']));
  }

  save() {
    this.flower.names = [];
    this.names.map((obj) => obj['name'].trim() &&
      this.flower.names.push([obj['name'], obj['describedBy']]));

    this.flower.searches = [];
    this.searches.map((obj) => obj['searchTerm'] && obj['searchTerm'].trim() &&
      this.flower.searches.push(obj['searchTerm']));

    this.flower.comment = this.comment.trim();
    this.flower.isWhite = this.isWhite;
    this.flower.isNovelFoods = this.isNovelFoods;
    this.flower.isInsect = this.isInsect;
    this.flower.isAlgae = this.isAlgae;
    this.flower.isFungi = this.isFungi;
    this.flower.isCompound = this.isCompound;
    let obs = this.flower.save(this.isNewFlower);
    obs.subscribe((x) => {
      this.isNewFlower = false;
    });
  }

}
