import { Injectable } from '@angular/core';
import { DbRecord } from './db-record'
import { Fulltext } from './fulltext'
import { ArcService } from './arc.service'
import { Observable, Subject, timer } from 'rxjs';
import { FlowerService } from './flower.service';

export type DoiSuggestStatus = 'pdf_exists' | 'pdf_not_found' |
  'wait' | 'no_doi'
@Injectable({
  providedIn: 'root'
})
export class FulltextService {
  public unresolvedMap = new Map<string, Fulltext>()
  public hasFileMap = new Map<string, Fulltext>()
  public fs: FlowerService

  public doiSuggestionChange$
  private _doiSuggestions = new Map<string, DoiSuggestStatus>()
  private _doiSuggestionQueue = new Set<string>()
  private _doiSuggestionLinks = new Map<string, string>()

  constructor(private arcs: ArcService) {
    this.doiSuggestionChange$ = new Subject()
    const doiSuggestionTimer$ = timer(5000, 10000)
    doiSuggestionTimer$.subscribe(_ => {
      if (this._doiSuggestionQueue.size) {
        this._updateDoiSuggestions()
      }
    })
  }

  doiSuggestionLink(dbRecord: DbRecord) {
    return this._doiSuggestionLinks.get(dbRecord.doi)
  }

  private _updateDoiSuggestions() {
    const ls = Array.from(this._doiSuggestionQueue)
    this._doiSuggestionQueue.clear()
    let doiService$ = this.arcs.fulltextSuggestDoiList$(ls)
    doiService$.subscribe(resp => {
      const dois = resp['dois']
      dois['pdf_exists'].forEach(x => {
        this._doiSuggestions.set(x, 'pdf_exists')
        let y = x
        const repl: Object = resp['replace']
        Object.keys(repl).forEach(k => {
          y = y.replace(new RegExp(`\\${k}`, 'g'),
            repl[k])
        })
        this._doiSuggestionLinks.set(x,
          `${resp['base']}${y}.pdf`)
      })
      dois['pdf_not_found'].forEach(x => {
        this._doiSuggestions.set(x, 'pdf_not_found')
      })
      dois['pdf_not_checked'].forEach(x =>
        this._doiSuggestionQueue.add(x))
      this.doiSuggestionChange$.next()
    })
  }

  getFileSuggestion(dbRecord: DbRecord): DoiSuggestStatus {
    if (dbRecord.doi) {
      return this._doiSuggestions
        .get(dbRecord.doi)
    } else {
      return 'no_doi'
    }
  }

  hasFile(dbRecord: DbRecord) {
    const state = this.hasFileMap.has(dbRecord.id)
    const doi = dbRecord.doi
    if (!state && doi && !this._doiSuggestions.has(doi)) {
      this._doiSuggestions.set(doi, 'wait')
      this._doiSuggestionQueue.add(doi)
    }
    return state
  }

  createFulltext(dbRecord: DbRecord) {
    new Fulltext(this, dbRecord)
  }

  deleteFile(dbRecord: DbRecord): Observable<any> {
    let obs = this.arcs.run({
      fun: 'deleteFulltext',
      dbRecord: dbRecord.id
    })
    obs.subscribe((obj) => {
      dbRecord.fulltext.finishDelete()
    })
    return obs
  }

  checkRecords(ls: DbRecord[]) {
    ls.forEach((dbr) => {
      if (!dbr.fulltext)
        this.createFulltext(dbr)
    })
    this.runUnresolved()
  }

  uploadFinished(dbRecord: DbRecord, obj: Object) {
    dbRecord.fulltext.finalizeRun(obj['stat'])
  }

  runUnresolved() {
    if (!this.unresolvedMap.size)
      return
    let ls = []
    this.unresolvedMap.forEach((x) => x.prepareRun(ls))

    if (!ls.length)
      return
    let obs = this.arcs.run({
      fun: 'checkFulltexts',
      ids: ls
    })
    obs.subscribe((ls) => {
      let resp = ls[0]
      Object.keys(resp).forEach((k) => {
        let ft = this.unresolvedMap.get(k)
        if (ft) {
          ft.finalizeRun(resp[k])
        }
      })
    })
  }
}
