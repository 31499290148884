import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { SearchQueueService } from '../search-queue.service'

@Component({
  selector: 'app-search-queue-button',
  templateUrl: './search-queue-button.component.html',
  styleUrls: ['./search-queue-button.component.css']
})
export class SearchQueueButtonComponent implements OnInit {
  @Input() flower: Flower

  constructor(
    private sq: SearchQueueService
  ) { }

  get isOn() {
    return this.sq.isOn(this.flower.id)
  }

  switch() {
    this.sq.switch(this.flower.id)
  }

  ngOnInit() {
  }

}
