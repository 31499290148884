import { Injectable } from '@angular/core';
import { Flower } from './flower'
import { DbRecord } from './db-record'
import { EditorService } from './editor.service'
import { FlowerNotes } from './flower-notes'
import { ArcService } from './arc.service'
import { UtilsService } from './utils.service'
import { Note } from './note'

@Injectable()
export class NoteService {
  editModeNote: Note


  constructor(
    private arcs: ArcService,
    private utils: UtilsService,
    private es: EditorService) { }

  initFlower(flower: Flower, obj: Object) {
    if (!obj)
      return
    Object.keys(obj).forEach((k) => {
      if (k.charAt(0) == '_')
        return
      let editor = this.es.getEditor(k)
      let fn = new FlowerNotes(flower, editor)
      let data = obj[k]

      Object.keys(data).forEach((x) => {
        let text;
        let dt;
        if (typeof data[x] == 'string') {
          text = data[x]
          dt = null
        } else {
          text = data[x][0]
          dt = new Date(data[x][1])
        }

        fn.addNote(x, text, dt)

      })
    })

  }

  addNote(flower: Flower,
    text: string,
    dbRecord?: DbRecord,
    editMode?: boolean) {

    let fn = this.getOwnerFlowerNotes(flower)
    if (!fn)
      fn = new FlowerNotes(flower, this.es.currentEditor)
    let n;
    if (dbRecord)
      n = fn.addNote(dbRecord.id, text, new Date())
    else
      n = fn.addNote('_flower', text, new Date())
    if (editMode)
      this.editModeNote = n
    return this.save(flower)
  }


  removeNote(flower: Flower, dbRecord?: DbRecord) {
    let fn = this.getOwnerFlowerNotes(flower)
    if (dbRecord)
      fn.removeNote(dbRecord.id)
    else
      fn.removeNote('_flower')
    return this.save(flower)
  }


  save(flower: Flower) {
    let fn = this.getOwnerFlowerNotes(flower)
    let obs = this.arcs.run({
      fun: 'saveNotes',
      editor: fn.editor.email,
      flower: fn.flower.id,
      notes: fn.saveParams
    })
    obs.subscribe((x) => x)
    return obs
  }

  getOwnerFlowerNotes(flower: Flower) {
    for (let i = 0; i < flower.notes.length; i++)
      if (flower.notes[i].editor && flower.notes[i].editor.email == this.utils.email)
        return flower.notes[i]
    return null
  }

  getOwnerNote(flower: Flower, dbRecord?: DbRecord) {
    let fn = this.getOwnerFlowerNotes(flower)
    if (!fn)
      return null
    if (!dbRecord)
      return fn.flowerNote
    return fn.itemNote(dbRecord)
  }

}
