<div *ngIf='!(isEditMode || isRemoveMode)'
    class='viewNote'>
  <div class='editor'>
    <app-editor-box [editor]='note.editor'></app-editor-box>
    <span class='date'>{{date}}</span>
  </div>

  <div [innerHTML]='text'></div>

  <div *ngIf='isOwner'>
    <button (click)='switchEditMode()'>Edit note</button>
    <button (click)='switchRemoveMode()'>Remove note</button>
  </div>
</div>


<div *ngIf='isEditMode'
    class='editNote'>
  <textarea [(ngModel)]='editText'
      [class.dbRecordNote]='isDbRecordNote'></textarea>
  <div *ngIf='isOwner'>
    <button (click)='switchEditMode()'>Cancel</button>
    <button (click)='save()'>Save note</button>
  </div>
</div>

<div *ngIf='isRemoveMode'
    class='removeNote'>
  <h1>Note will be removed:</h1>
  <div [innerHTML]='text'>
  </div>
  <div *ngIf='isOwner'>
    <button (click)='switchRemoveMode()'>Cancel</button>
    <button (click)='remove()'>Remove note</button>
  </div>
</div>
