import { Flower } from './flower';
import { Editor } from './editor';
import { DbRecord } from './db-record';
import { AbstractRatingService } from './abstract-rating.service';

export class AbstractRating {
  private _ratings = new Map<string, string>();

  constructor(
    private ars: AbstractRatingService,
    public flower: Flower,
    public editor: Editor) {
    this.flower.abstractRatings.push(this);
  }

  setItemRating(dbRecord: DbRecord, rating: string, notSave?: boolean) {
    this._ratings.set(dbRecord.id, rating);
    if (!notSave) {
      this.ars.save(this, dbRecord, rating);
    }
  }

  setOtherRating(dbRecordId: string, rating: string) { // when record removed from searches, may be useful when it appears again
    this._ratings.set(dbRecordId, rating);
  }

  getItemRating(dbRecord: DbRecord) {
    let r = this._ratings.get(dbRecord.id);
    return r;
  }

  adminRemove(dbRecord: DbRecord) {
    this.setItemRating(dbRecord, 'not_rated');
  }

  get ratedItems() {
    let ls = [];
    this._ratings.forEach((v, k) => {
      if (v !== 'not_rated') {
        ls.push(k);
      }
    });
    return ls;
  }
}
