import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { UtilsService } from './utils.service';
import { DocumentationDashboardComponent } from './documentation-dashboard/documentation-dashboard.component';
import { BookmarkDashboardComponent } from './bookmark-dashboard/bookmark-dashboard.component';
import { FlowerDisplayComponent } from './flower-display/flower-display.component';
import { FlowerEditComponent } from './flower-edit/flower-edit.component';
import { FlowerListComponent } from './flower-list/flower-list.component';
import { FlowerDashboardComponent } from './flower-dashboard/flower-dashboard.component';
import { AuthService } from './auth.service';
import { UnknownUserComponent } from './unknown-user/unknown-user.component';
import { CallbackComponent } from './callback/callback.component';


const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'flowers',
    // canActivate: [AuthService],
    component: FlowerDashboardComponent,
    children: [
      {
        path: 'list',
        component: FlowerListComponent
      },  {
        path: 'edit/:id',
        canActivate: [UtilsService],
        component: FlowerEditComponent
      }
    ]
  },
  {
    path: 'd/:name/:id',
    component: FlowerDisplayComponent
  },
  {
    path: 'bookmarks',
    component: BookmarkDashboardComponent,
  },
  {
    path: 'documentation',
    component: DocumentationDashboardComponent,
  },
  {
    path: 'admin',
    canActivate: [UtilsService],
    children: [
      {
        component: AdminPageComponent,
        path: ':tp'
      }
    ]
  },
  {
    path: 'unknown-user',
    component: UnknownUserComponent,
  },
  {
    path: '**',
    redirectTo: 'flowers/list',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
