<div class='main'>
  <div class='doi'>
    <a [href]='doiURL'
        target='_blank'>{{record.doi}}</a> [{{record.type}}]
  </div>
  <div class='authors' *ngIf='record.authors.length'>
      {{record.authorsCitation}}
  </div>
  <div>
    {{record.title}}
  </div>
  <div *ngIf='record.type=="journal-article" || record.issn.length || record.volume'
      class='citation'
      [innerHtml]='citation'></div>
  <div *ngIf='record.isbn.length'
      class='isbn'>
    <b>ISBN: </b>
    <span *ngFor='let isbn of record.isbn; let i=index'>
      <a target='_blank'
      [href]='isbnURL(isbn)'>{{isbn}}</a><span *ngIf='i+1!=record.isbn.length'>; </span>
    </span>
  </div>
  <div *ngIf='record.issn.length'
      class='issn'>
    <b>ISSN: </b>
    <span *ngFor='let tpl of record.issn; let i=index'>
      <a target='_blank'
      [href]='issnURL(tpl[0])'>{{tpl[0]}}</a> ({{tpl[1]}})<span *ngIf='i+1!=record.issn.length'>; </span>
    </span>
  </div>
</div>
