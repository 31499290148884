import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Flower } from '../flower'
import { FlowerService } from '../flower.service'
import { PageService } from '../page.service'
import { EditorService } from '../editor.service'
import { NoteService } from '../note.service'
import { StatusService } from '../status.service';
import { ParserService } from '../parser.service';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-flower-display',
  templateUrl: './flower-display.component.html',
  styleUrls: ['./flower-display.component.css']
})
export class FlowerDisplayComponent implements OnInit {
  flower: Flower;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public fs: FlowerService,
    private pgs: PageService,
    private es: EditorService,
    private ss: StatusService,
    private ns: NoteService,
    public ps: ParserService,
    public utils: UtilsService
  ) {
    this.pgs.clear();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let flowerId = params['id'];
      let h = location.hash.slice(1);
      if (h.charAt(0) === 'R') {
        this.fs.hash = h;
      }
      if (!this.fs.initFinished) {
        this.fs.startFlower = flowerId;
        this.router.navigate(['flowers', 'list']);
        return;
      }

      this.flower = this.fs.get(flowerId);
      let obs;
      if (this.flower) {
        this.fs.currentFlower = this.flower;
        if (!this.flower.isReady) {
          obs = this.fs.initFlower(flowerId);
          obs.subscribe(() => this.ps.isAutoMode &&
            this.ps.processCurrentFlowerSearches());
        } else {
          // tslint:disable-next-line:no-unused-expression
          this.ps.isAutoMode && this.ps.processCurrentFlowerSearches();
        }
        return;
      }

      obs = this.fs.initFlower(flowerId);

      obs.subscribe(() => {
        this.flower = this.fs.get(flowerId);
        this.fs.currentFlower = this.flower;
        if (!this.flower.isReady) {
          obs = this.fs.initFlower(flowerId);
          obs.subscribe(() => this.ps.isAutoMode &&
            this.ps.processCurrentFlowerSearches());
        } else {
          // tslint:disable-next-line:no-unused-expression
          this.ps.isAutoMode &&
            this.ps.processCurrentFlowerSearches();
        }
      });
    });

  }

  ngOnDestroy() {
    this.fs.currentFlower = null;
  }

  edit() {
    this.router.navigate(['/flowers', 'edit', this.flower.id]);
  }

  get searches() {
    let ls = [];
    this.flower.searches.map(st => ls.push(st));
    return ls;
  }

  get statuses() {
    return this.ss.flowerStatusValues;
  }

  get showAddNoteButton() {
    return !this.ns.getOwnerNote(this.flower);
  }

  addNote() {
    this.ns.addNote(this.flower, '', null, true);
  }

  get owner() {
    return this.es.getOwner(this.flower);
  }

  get assignee() {
    return this.es.getAssignee(this.flower);
  }

  get name() {
    try { return this.flower.mainName.join(' '); }
    catch (e) { return this.flower.mainName; }

  }

  get plantList() {
    return this.flower.names.map(tpl =>
      `<a class='plantList'
      target='_blank'
      href="http://www.theplantlist.org/tpl1.1/search?q=${encodeURIComponent(tpl[0])}">${tpl[0]}</a>`
    ).join(" | ");
  }
  
  get kewSciencePlants() {
    return this.flower.names.map(tpl =>
      `<a class='plantList'
      target='_blank'
      href="http://www.plantsoftheworldonline.org/?q=${tpl[0].split(' ').join('+')}">${tpl[0]}</a>`
    ).join(" | ");
  }

  get synonyms() {
    try {
      let ls = [];
      this.flower.names.slice(1).forEach(x => ls.push(x.join(' ')));
      return ls.join('; ');
    } catch (e) { return this.flower.names.slice(1).join('; '); }
  }

  get flowerNotes() {
    let ls = [];
    this.flower.notes.forEach((x) => {
      let fn = x.flowerNote;
      if (fn && (fn.text.trim() || fn.editor === this.es.currentEditor))
        ls.push(fn);
    });
    return ls;
  }


}
