import { Injectable } from '@angular/core';
import { Flower } from './flower'
import { ArcService } from './arc.service'
import { UtilsService } from './utils.service'

@Injectable()
export class FamilyService {
  private _flower2family = new Map<string, string>()
  private _familiesSet = new Set<string>()
  private _familiesCounter = {}

  constructor(
    private arcs: ArcService,
    private utils: UtilsService
  ) { }

  init(obj: Object) {
    //this.utils.saveExport(obj, 'families.json')
    Object.keys(obj).forEach((k) => {
      let f = obj[k]
      this._flower2family.set(k, f)
      if (this._familiesSet.has(f))
        this._familiesCounter[f] += 1
      else
        this._familiesCounter[f] = 1
      this._familiesSet.add(obj[k])
    })
  }

  familyForFlower(flower: Flower) {
    return this._flower2family.get(flower.id)
  }

  flowerCount(family: string) {
    return this._familiesCounter[family]
  }

  get families() {
    let ls = []
    this._familiesSet.forEach((x) => ls.push(x))
    ls.sort((a, b) => a < b ? -1 : 1)
    return ls
  }

  setFamily(flower:Flower, family:string) {
    let obs = this.arcs.run({
      fun: 'setFamily',
      flowerId: flower.id,
      family: family
    })
    obs.subscribe( resp =>
      this._flower2family.set(flower.id, family)
    )
    return obs
  }
}
