import { Injectable } from '@angular/core';
import { Flower } from './flower'
import { ArcService } from './arc.service'
import { FlowerService } from './flower.service';

@Injectable()
export class StatusService {
  public fs: FlowerService

  private flowerStatuses = new Map<string, string>()
  private acceptanceStatuses = new Map<string, Object>()
  private _abstractStatusIds = []
  private _fulltextStatusIds = []
  private _acceptAbstractIds = []
  private _rejectAbstractIds = []

  flowerStatusValues = ['initial', 'Screened', 'Prague', 'Postponed',
    'EFSA', 'Assigned', 'Approved', 'No info']; // 'No relevant info'


  private _rejectLs = [
    ['duplicate', 'duplicate', 'the article already processed in a previous entry'],
    ['mistake', 'wrong plant'],
    ['positive_effects', 'therapeutical effects', 'pharmaceuticals, dietary supplements, protective effects'],
    ['not_health_related', 'not health related', 'ecological studies, systematics, chemical syntheses and mechanisms, analytical chemistry without individual component identification, chemical structural studies'],
    ['no_chemical', 'no chemical of concern', 'does not contain chemicals from red/orange group'],
    ['subspecies_toxicity', 'toxicity data related to non-relevant subspecies', ''],
    ['subspecies_composition', 'composition data related to non-relevant subspecies', ''],
    ['study_type', 'study type', 'biocidal data, patents, in vitro (if not genotoxicity), allergy, non-oral route, people with health problems, pesticides, insects, antimicrobial, antifungal, ecotoxicity, mixtures of plants, the plant as a host of toxic species']
  ]

  constructor(
    private arcs: ArcService,
    ) {
    let rejectAbstractValue = -1
    let acceptAbstractValue = 1

    let rejectLs = this._rejectLs

    let abstractAcceptLs = [
      ["must_see", "accepted - indication of toxic/adverse effect", " - in mammals and birds (genotoxicity everywhere)"],
      ["must_see_composition", "accepted - indication of chemical(s) of concern "],
      ["if_available", "check fulltext before decision", "plant not explicitly in text, possible mixture, hints of usefuleness - e.g. generic review"],
      ["not_rated", "not rated"]
    ]

    let fulltextLs = [
      ["process", "process"],
      ["reject", "reject"],
      ["accept", "process"],
      ["undecided", "undecided"],
      ["composition", "composition"],
      ["toxicity", "toxicity"],
      ["genotoxicity", "genotoxicity"],
      ["no_full_text", 'full text not available'],
      ['no_way_full_text', 'full text not available'],
      ['language_barrier', 'language barrier'],
      ['borderline', 'borderline'],
      ['vague', 'vague'],
      ['no_data', 'no data'],
      ['not_oral', 'without oral route'],
      ['data_repeat', 'repeat of data'],
      ['no_way_language_barrier', 'language barrier insurmountable'],
      ['no_way_full_text', 'access to full text impossible']
    ];

    rejectLs.forEach((tpl) => {
      this._abstractStatusIds.push(tpl[0]);
      this._rejectAbstractIds.push(tpl[0]);
      this._fulltextStatusIds.push(tpl[0]);

      this.acceptanceStatuses
        .set(tpl[0], {
          value: tpl[0],
          title: tpl[1],
          subtitle: tpl[2],
          acceptance: rejectAbstractValue
        });
    });

    abstractAcceptLs.forEach((tpl) => {
      this._abstractStatusIds.push(tpl[0]);
      if (tpl[0] !== 'not_rated')
        this._acceptAbstractIds.push(tpl[0]);
      this.acceptanceStatuses
        .set(tpl[0], {
          value: tpl[0],
          title: tpl[1],
          subtitle: tpl[2],
          acceptance: acceptAbstractValue
        });

    });

    fulltextLs.forEach((tpl) => {
      this._fulltextStatusIds.push(tpl[0]);
      this.acceptanceStatuses
        .set(tpl[0], {
          value: tpl[0],
          title: tpl[1],
        });
    });
  }

  private _childrenTree2(baseId, ls) {

    return ls.map(x => {
      return {
        id: `${baseId}-${x['value']}`,
        data: {
          title: x['title'],
          comment: x['subtitle']
        },
        children: []
      }
    })
  }

  private _childrenTree(baseId, params) {
    const initial = params['initial']
    let undecided
    if (initial) {
      undecided = [initial]
    } else {
      undecided = params['undecided']
    }

    return [
      {
        id: `${baseId}-undecided`,
        data: {
          role: "undecided",
          title: "Undecided"
        },
        children: this._childrenTree2(baseId,
          undecided)
      },
      {
        id: `${baseId}-rejected`,
        data: {
          role: "rejected",
          title: "Rejected"
        },
        children: this._childrenTree2(baseId,
          params['reject']
        )
      },
      {
        id: `${baseId}-accepted`,
        data: {
          role: "accepted",
          title: "Accepted"
        },
        children: this._childrenTree2(baseId,
          params['accept'])
      },
    ]
  }

  abstractTree(baseId) {
    return {
      id: "__ROOT__",
      data: {
        title: "Abstracts",
        role: "root"
      },
      children: this._childrenTree(baseId,
        this.abstractEditData)
    }
  }

  fulltextTree(baseId) {
    return {
      id: "__ROOT__",
      data: {
        title: "Fulltexts",
        role: "root"
      },
      children: this._childrenTree(baseId,
        this.fulltextEditData)
    }
  }

  initFlowerStatuses(obj: Object) {
    Object.keys(obj).forEach((x) =>
      this.flowerStatuses.set(x, obj[x]));
  }


  flowerStatus(flower: Flower) {
    return this.flowerStatuses.get(flower.id) || 'initial';
  }

  setFlowerStatus(flower: Flower, status: string) {
    let oldStatus = this.flowerStatus(flower);
    console.log(444, oldStatus, ' => ',status);
    if (oldStatus === status)
      return;
    this.flowerStatuses.set(flower.id, status);
    const unique = this.fs.searchStats[flower.id]['unique']
    let obs = this.arcs.run({
      fun: 'setFlowerStatus',
      flower: flower.id,
      status: status,
      unique: unique
    });
    obs.subscribe(resp => {
      console.log('SIMEK status', resp)
      /* if (x[0]['saved'])
        this.arcs.sendToSimek({
          type: 'flowerStatusChange',
          flowerId: flower.id,
          combo1id: flower.combo1id,
          isApproved: status === 'Approved'
        }).subscribe(x => console.log('flowerStatusChange', x)); */
    });
  }



  private _abstractEditData;
  get abstractEditData() {
    if (this._abstractEditData)
      return this._abstractEditData;
    let acceptLs = [];
    this._acceptAbstractIds.forEach((x) =>
      acceptLs.push(this.acceptanceStatuses.get(x)));
    let rejectLs = [];
    this._rejectAbstractIds.forEach((x) =>
      rejectLs.push(this.acceptanceStatuses.get(x)));
    this._abstractEditData = {
      accept: acceptLs,
      initial: this.acceptanceStatuses.get('not_rated'),
      reject: rejectLs
    };
    return this._abstractEditData;
  }

  private _fulltextEditData;
  get fulltextEditData() {
    if (this._fulltextEditData)
      return this._fulltextEditData;
    let acceptLs = [];
    let ls = ['composition', 'toxicity', 'genotoxicity'];
    ls.forEach((x) =>
      acceptLs.push(this.acceptanceStatuses.get(x)));
    let undecidedLs = [];
    ls = ['no_full_text', 'language_barrier', 'borderline', 'vague'];
    ls.forEach((x) =>
      undecidedLs.push(this.acceptanceStatuses.get(x)));
    let rejectLs = [];
    ls = ['no_data', 'not_oral'];
    ls.forEach((x) =>
      rejectLs.push(this.acceptanceStatuses.get(x)));
    this._rejectLs.forEach((x) =>
      rejectLs.push(this.acceptanceStatuses.get(x[0])));
    ls = ['data_repeat', 'no_way_language_barrier', 'no_way_full_text'];
    ls.forEach((x) =>
      rejectLs.push(this.acceptanceStatuses.get(x)));

    this._fulltextEditData = {
      accept: acceptLs,
      undecided: undecidedLs,
      reject: rejectLs
    };
    return this._fulltextEditData;
  }

  get abstractIds() {
    return this._abstractStatusIds;
  }

  get fulltextIds() {
    return this._fulltextStatusIds;
  }

  getAbstractObject(tag) {
    return this.acceptanceStatuses.get(tag);
  }

  getFulltextObject(tpl) {
    if (!tpl)
      return null;
    let obj = {};
    obj['mainStatus'] = this.acceptanceStatuses.get(tpl[0]);
    let ls = [];
    obj['reasons'] = ls;
    tpl[1].forEach((x) => {
      let y = this.acceptanceStatuses.get(x);
      if (y)
        ls.push(y);
      else
        console.log('StatusService: getFulltextObject problem', x);
    });
    return obj;
  }

}
