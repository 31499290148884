import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FlowerService } from '../flower.service';
import { UtilsService } from '../utils.service';
import { DbRecordService } from '../db-record.service';
import { ParserService } from '../parser.service';
import { ArcService } from '../arc.service';
import { BackupService } from '../backup.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {
  private tp: string;
  // private flowerResetPrepared = false;
  reprocessFlowers;
  largeSet = new Set<string>();
  clickSet = new Set<string>();;
  reprocessSTART = false;

  constructor(private route: ActivatedRoute,
    private fs: FlowerService,
    public utils: UtilsService,
    private dbrs: DbRecordService,
    private ps: ParserService,
    private arcs: ArcService,
    public bs: BackupService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.tp = params['tp'];
      console.log(6666, this.tp);
      if (this.tp === 'backup')
        this.bs.startBackup();
    });
  }

  get rpFlowers() {
    if (!this.reprocessFlowers) {
      return [];
    }
    return this.reprocessFlowers.slice(0, 100);
  }

  warnLarge(flower) {
    return this.largeSet.has(flower.id);
  }

  flClicked(flower) {
    this.clickSet.add(flower.id);
    console.log(this.clickSet);
  }

  inClickSet(flower) {
    return this.clickSet.has(flower.id);
  }

  

  suggestReprocess() {
    this.reprocessFlowers = undefined;
    this.reprocessSTART = true;
    let obs = this.arcs.run({ fun: 'list', tp: 'combo_json' });
    obs.subscribe(r => {
      const ls = [];
      r[0].forEach(x => {
        // console.log(x)
        const tpl = x['filename'].split('/');
        const flowerId = tpl[3];
        if (x['st_size'] > 30000000) {
          this.largeSet.add(flowerId);
        }
        if (tpl[4] !== 'mainRecords.json') {
          return;
        }
        const fl = this.fs.get(flowerId);
        if (!fl) {
          return;
        }
        ls.push([new Date(x['st_ctime'] * 1000), fl]);
      });
      ls.sort((a, b) => a[0] < b[0] ? -1 : 1);
      this.reprocessFlowers = ls.filter(x => !this.warnLarge(x[1]));
      this.reprocessSTART = false;
      console.log(5544, this.largeSet);
    });
  }

  get processMessage() {
    return this.fs.processMessage;
  }

  testSave() {
    let obs = this.arcs.gaeSave('/tmp/aaa.json', { a: 1, b: 2 });
    obs.subscribe(r => console.log(1111, r));
  }

  continueProcessing() {
    this.ps.processFlowerPipeline();
  }

  reprocessAll() {
    let ls = [];
    this.fs.list.forEach((flower) =>
      ls.push(flower.id));
    this.ps.processFlowerPipeline(ls);
    /*
    this.fs.list.forEach((flower) =>
      this._processLs.push(flower))
    this._processNext(true)*/
  }

  generateUploadedFilesJSON() {
    let obs = this.arcs.run({ fun: 'list', tp: 'combo_pdf' });
    obs.subscribe(r => {
      this.utils.saveExport(r[0],
        `fulltexts.json`);
      console.log(r[0]);
    });
  }

  get isBackup() {
    return this.tp === 'backup';
  }


  get isUploads() {
    return this.tp === 'uploads';
  }

  get isEditors() {
    return this.tp === 'editors';
  }

  get isReset() {
    return this.tp === 'reset';
  }

  get isReprocess() {
    return this.tp === 'reprocess';
  }
/*
  resetFlowers() {
    this.flowerResetPrepared = true;
  }
*/
  resetCriterionCache() {
    this.dbrs.resetCriterionCache();
  }
/*
  confirmResetFlowers() {
    let obs = this.fs.reset();
    obs.subscribe((x) => this.flowerResetPrepared = false);
  }

  cancelResetFlowers() {
    this.flowerResetPrepared = false;
  }
  */
}
