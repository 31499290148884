<div id='page'>
  <table id='pageTable'>
    <tr class='mainHeader'>
      <td id='leftHeader'>
        <img id='logo' src='/assets/logo.png'>
      </td>
      <td id='middleHeader'>
        <app-main-menu></app-main-menu>
      </td>
      <td id='rightHeader'>
        <app-login-box></app-login-box>
      </td>
    </tr>
    <tr>
      <td>
        <app-permalink-resolve-box *ngIf='utils.authenticated'></app-permalink-resolve-box>
        <app-admin-dashboard *ngIf='utils.authenticated && isAdmin'></app-admin-dashboard>
      </td>
      <td id='mainPage'>
        <div *ngIf='!utils.authenticated'>
          <h1>Please, log in</h1>
        </div>

        <router-outlet></router-outlet>
      </td>
      <td>

      </td>
    </tr>
  </table>
</div>