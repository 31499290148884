import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

// https://auth0.com/docs/quickstart/spa/angular2/01-login
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    public auths: AuthService
  ) { }

  ngOnInit() {
    this.auths.handleAuthCallback();
  }

  get isLoggedIn() {
    return this.auths.loggedIn
  }

}
