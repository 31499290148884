import { Component, OnInit, Input } from '@angular/core';
import { UploadSearchData } from '../upload-search-data'

@Component({
  selector: 'app-tag-upload-search',
  templateUrl: './tag-upload-search.component.html',
  styleUrls: ['./tag-upload-search.component.css']
})
export class TagUploadSearchComponent implements OnInit {
  @Input() searchData: UploadSearchData
  @Input() value: string
  @Input() tp: string

  constructor() { }

  ngOnInit() {
  }

  get label() {
    return this.value
  }

  get isSelected() {
    if (this.tp == 'searchTerm')
      return this.searchData.currentSearch == this.value
    else if (this.tp == 'database')
      return this.searchData.currentDatabase == this.value
  }

  switch() {
    console.log(this.searchData)
    if (this.tp == 'searchTerm')
      this.searchData.currentSearch = this.value
    else if (this.tp == 'database')
      this.searchData.currentDatabase = this.value
  }

}
