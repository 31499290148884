import { Injectable } from '@angular/core';
import { Flower } from './flower'
import { FulltextRating, saveRating } from './fulltext-rating'
import { EditorService } from './editor.service'
import { DbRecordService } from './db-record.service'
import { UtilsService } from './utils.service'
import { DbRecord } from './db-record'
import { ArcService } from './arc.service';



@Injectable()
export class FulltextRatingService {
  stamps: Object;

  constructor(
    private arcs: ArcService,
    private es: EditorService,
    private dbrs: DbRecordService,
    private utils: UtilsService
  ) { }

  initFlower(flower: Flower, obj: Object, stamps: Object) {
    if (!obj) {
      return;
    }
    Object.keys(obj).forEach((k) => {
      if (k.charAt(0) === '_') {
        return;
      }
      let editor = this.es.getEditor(k);
      let fr = new FulltextRating(this, flower, editor);
      let data = obj[k];

      Object.keys(data).forEach((x) => {
        let r = this.dbrs.get(x, flower);
        let s: [string, Set<string>] = [data[x][0], new Set<string>(data[x][1])];
        if (r) {
          fr.setItemRating(r, s, true);
        } else {
          fr.setOtherRating(x, s);
        }
      });
    });
    this.stamps = stamps;
  }

  ownerRatings(flower: Flower) {
    for (let i = 0; i < flower.fulltextRatings.length; i++)
      if (flower.fulltextRatings[i].editor && flower.fulltextRatings[i].editor.email === this.utils.email)
        return flower.fulltextRatings[i];
    return new FulltextRating(this, flower, this.es.getEditor(this.utils.email));
  }


  save(fr: FulltextRating, dbRecord: DbRecord, rating: saveRating) {
    let obs = this.arcs.run({
      fun: 'saveFulltextRatingChange',
      flower: fr.flower.id,
      dbRecord: dbRecord.id,
      rating: rating,
      editor: fr.editor.id
    });
    obs.subscribe((x) => x);
  }


}
