import { Component, OnInit } from '@angular/core';
import { EditorService } from '../editor.service'
import { Editor } from '../editor'


@Component({
  selector: 'app-admin-editors',
  templateUrl: './admin-editors.component.html',
  styleUrls: ['./admin-editors.component.css']
})
export class AdminEditorsComponent implements OnInit {
  public current: Editor
  private _filter: RegExp
  displayName: string
  email: string
  isDelete = false
  isEFSAeditor = false

  constructor(private es: EditorService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.deleteIfNew()
  }

  filter(txt: string) {
    txt = txt.trim()
    if (!txt)
      return this._filter = null
    this._filter = new RegExp(txt, 'i')
    console.log(this._filter)
  }

  delete() {
    this.isDelete = true
  }

  cancelDelete() {
    this.isDelete = false
  }

  confirmDelete() {
    this.current.delete()
    this.isDelete = false
    this.current = null
  }

  save() {
    this.current.change({
      email: this.email,
      displayName: this.displayName,
      isEFSAeditor: this.isEFSAeditor
    })
    this.current = null
  }

  deleteIfNew() {
    if (this.current && this.current.id == '__NEW__')
      this.current.delete()
  }

  cancel() {
    this.deleteIfNew()
    this.current = null
  }

  edit(e: Editor) {
    this.current = e
    this.displayName = e.displayName
    this.email = e.email
    this.isEFSAeditor = e.isEFSAeditor
  }

  get editors() {
    let ls = this.es.list
    if (!this._filter)
      return ls
    return ls.filter((x) => this._filter.test(x.displayName) || this._filter.test(x.email))
  }

  newEditor() {
    this.edit(this.es.newEditor({ id: '__NEW__' }))
  }

  switchEFSA() {
    this.isEFSAeditor = !this.isEFSAeditor
    //this.es.switchEfsaEditor(this.email)
  }

}
