<!--span class='tag'
    [class.selected]="isSelected"
    (click)='switch()'>
  {{label}}
</span-->

<label [class.checked] = 'isSelected'>

  <input type='radio'
  [checked]='isSelected'
  (click)='switch()'>
  {{label}}
</label>
