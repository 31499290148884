import { Component, OnInit } from '@angular/core';
import { UtilsService } from './utils.service';
import { AuthService } from './auth.service';
import { MixinService } from './mixin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'comboN';

  constructor(
    private mixin: MixinService,
    public utils: UtilsService,
    public auths: AuthService
  ) {
  }

  ngOnInit() {
    this.auths.localAuthSetup();
  }

  get isAdmin() {
    if (!this.utils)
      return false
    return this.utils.isAdmin
  }

  
}
