import { Component, OnInit, Input } from '@angular/core';
import { Flower  } from '../flower'

@Component({
  selector: 'app-flower-name-edit-box',
  templateUrl: './flower-name-edit-box.component.html',
  styleUrls: ['./flower-name-edit-box.component.css']
})
export class FlowerNameEditBoxComponent implements OnInit {
  @Input() obj: Object

  constructor() { }

  ngOnInit() {
  }

  get name() {
    return this.obj['name']
  }

  get describedBy() {
    return this.obj['describedBy']
  }

  set name(txt) {
    this.obj['name'] = txt.trim()
  }

  set describedBy(txt) {
    this.obj['describedBy'] = txt.trim()
  }


}
