import { Injectable } from '@angular/core';
import { Editor } from './editor'
import { Flower } from './flower'
import { UtilsService } from './utils.service'
import { ArcService } from './arc.service'
import { PageService } from './page.service'

@Injectable()
export class EditorService {
  ownersMap = new Map<string, Editor>()
  assigneeMap = new Map<string, Editor>()
  private _efsaEditorsSet = new Set<Editor>()

  constructor(
    private arcs: ArcService,
    private utils: UtilsService,
    private pgs: PageService
  ) { }

  switchEFSAeditor(editor: Editor) {
    if (editor.isEFSAeditor)
      this._efsaEditorsSet.add(editor)
    else
      this._efsaEditorsSet.delete(editor)
  }

  isEfsaEditor(editor: Editor) {
    return this._efsaEditorsSet.has(editor)
  }

  newEditor(obj: Object): Editor {
    return new Editor(this, obj)
  }

  getEditor(editorId: string) {
    return Editor.editorsMap.get(editorId)
  }

  init(obj: Object) {
    let edObj = obj['editors']
    Object.keys(edObj).forEach((x) => {
      this.newEditor(edObj[x])
    })
    this.initOwners(obj['owners'])
  }

  initOwners(obj: Object) {
    // console.log(123, obj)
    let assigneeDct = obj['_assigned']
    delete obj['_assigned']

    Object.keys(assigneeDct).forEach((x) => {
      let ownerEmail = assigneeDct[x]
      if (ownerEmail) {
        let editor = this.getEditor(ownerEmail)
        this.assigneeMap.set(x, editor)
      }
    })

    Object.keys(obj).forEach((x) => {
      let ownerEmail = obj[x]
      if (ownerEmail) {
        let editor = this.getEditor(ownerEmail)
        this.ownersMap.set(x, editor)
      }
    })
  }

  getOwner(flower: Flower) {
    return this.ownersMap.get(flower.id)
  }

  getAssignee(flower: Flower) {
    return this.assigneeMap.get(flower.id)
  }

  get ownersSet() {
    let s = new Set<Editor>()
    this.ownersMap.forEach((x) => x && s.add(x))
    return s
  }

  get assigneesSet() {
    let s = new Set<Editor>()
    this.assigneeMap.forEach((x) => x && s.add(x))
    return s
  }

  private _ownersLs
  get ownersLs() {
    if (this._ownersLs && this._ownersLs.length)
      return this._ownersLs
    this._ownersLs = []
    this.ownersSet.forEach((x) => this._ownersLs.push(x))
    this._ownersLs.sort((a, b) => a.displayName < b.displayName ? -1 : 1)
    return this._ownersLs
  }


  private _assigneesLs
  get assigneesLs() {
    if (this._assigneesLs && this._assigneesLs.length)
      return this._assigneesLs
    this._assigneesLs = []
    this.assigneesSet.forEach((x) => this._assigneesLs.push(x))
    this._assigneesLs.sort((a, b) => a.displayName < b.displayName ? -1 : 1)
    return this._assigneesLs
  }


  get currentEditor() {
    return this.getEditor(this.utils.email)
  }

  get list() {
    let ls = []
    Editor.editorsMap.forEach((x) => ls.push(x))
    ls.sort((a, b) => a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1)
    return ls
  }

  save(editor: Editor) {
    let obs = this.arcs.run({
      fun: 'saveEditor',
      editor: editor.id,
      payload: editor.saveParams
    })
    obs.subscribe((x) => console.log(12344444, x))
  }

  delete(editor: Editor) {
    let obs = this.arcs.run({
      fun: 'deleteEditor',
      editor: editor.id,
    })
    obs.subscribe((x) => console.log("EDITOR DELETED"))
  }

  assignFlower(flower: Flower, editor: Editor, isAssignee: boolean) {
    let obs = this.arcs.run({
      fun: 'assignFlowerEditor',
      editor: (editor && editor.id) || null,
      flower: flower.id,
      isAssignee: isAssignee
    })
    let m = isAssignee ? this.assigneeMap : this.ownersMap

    obs.subscribe((x) => {
      m.set(flower.id, editor)
      if (isAssignee) {
        this._assigneesLs = null
        if (!this.assigneesLs.length)
          this.pgs.setAssigneeFilter(null)
      } else {
        this._ownersLs = null
        if (!this.ownersLs.length)
          this.pgs.setOwnerFilter(null)
      }
    })
    return obs
  }

}
