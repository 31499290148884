import { Flower } from './flower'
import { Editor } from './editor'
import { Note } from './note'
import { DbRecord } from './db-record'

export class FlowerNotes {
  private _notes = new Map<string, Note>()

  constructor(public flower: Flower, public editor: Editor) {
    this.flower.notes.push(this)
  }


  addNote(target: string, text: string, dt: Date) {
    let n = new Note(target, this, text, dt)
    this._notes.set(target, n)
    return n
  }

  removeNote(target: string) {
    this._notes.delete(target)
  }

  get flowerNote() {
    return this._notes.get('_flower')
  }

  itemNote(dbr: DbRecord) {
    return this._notes.get(dbr.id)
  }

  get saveParams() {
    let obj = {}
    this._notes.forEach((x) => {
      let n = x.saveParams
      obj[n['id']] = [n['text'],n['date']]
    })
    return obj
  }

}
