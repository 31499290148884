import { CriterionItem } from './criterion-item';
import { DbRecord } from './db-record';
import { Flower } from './flower';

export class Criterion {
  static criterionsMap = new Map<string, Criterion>();
  id: string;
  items = new Map<string, CriterionItem>();
  private _sortedItems: CriterionItem[];


  constructor(id, data) {
    this.id = id.split('::').pop();
    Criterion.criterionsMap.set(this.id, this);
    for (let k of Object.keys(data))
      new CriterionItem(this, k, data[k]);

  }

  getItem(cid) {
    return this.items.get(cid);
  }

  getCriterionItemsFromIdList(ls: string[]) {
    let finalLs = [];
    ls.forEach((x) => finalLs.push(this.items.get(x)));
    return finalLs;
  }

  run(dbr: DbRecord, flower: Flower) {
    // let sum = 0;
    // if (dbr.isPatent)
    //   sum -= 50000;
    // this.items.forEach((ci) => sum += ci.run(dbr, flower));
    // dbr.criterionItemMatches.sort((a, b) => {
    //   if (a.weight > b.weight)
    //     return -1;
    //   if (a.weight < b.weight)
    //     return 1;
    //   return a.regexp.source < b.regexp.source ? -1 : 1;
    // });
    // return -1 * sum;  // -1*: reverse order, highest weight first
  }

}
