import { DbRecordService } from './db-record.service';
import { Criterion } from './criterion';
import { CriterionItem } from './criterion-item';
import { Fulltext } from './fulltext';
import { Flower } from './flower';

export class DbRecord {
  static mainCriterion: Criterion;
  // static duplicatesMap = new Map<string, Set<DbRecord>>()
  // static id2DbRecordMap = new Map<string, DbRecord>()
  data: Object = null;
  // private _mainCriterionCache;
  // private _sortTupleCache = new Map<string, number[]>();
  // _position: number // used for stable sort
  criterionPosition: number;
  yearPosition: number;
  sourcePosition: number;
  fulltext: Fulltext;
  criterionItemMatches: CriterionItem[] = [];
  mainCriterionWeight: number;
  compoundHits: [RegExp, number][] = [];
  idVariants: string[] = [];

  constructor(private dbrs: DbRecordService,
    public id: string,
    private flower?: Flower
  ) {
    this.id = this.dbrs.finalizeId(this.id);
    if (flower) {
      flower._dbRecordsMap.set(this.id, this);
    }
  }

  get doi() {
    return this.data['doi']
  }

  setData(obj: Object) {

    this.data = obj;
    if (this.flower && this.lang)
      this.flower.langSet.add(this.lang);
  }

  sortTuple(ls) {
    let tpl = [];
    ls.forEach(x => {
      switch (x) {
        case 'mainCriterion': tpl.push(this.criterionPosition); break;
        case 'dateDesc': tpl.push(this.yearPosition); break;
        case 'dateAsc': tpl.push(this.yearPosition * -1); break;
        case 'source': tpl.push(this.sourcePosition); break;
        default: console.log('UNKNOWN SORT', x);
      }
    });
    return tpl;
  }

  regexpHit(regexp: RegExp) {
    if (regexp.test(this.title))
      return true;
    if (regexp.test(this.abstract || ''))
      return true;
    return false;
  }

  authorHit(regexp: RegExp) {
    const ls = this.authors;
    for (let i = 0; i < ls.length; i++) {
      if (regexp.test(ls[i])) {
        return true;
      }
    }
    return false;
  }


  sendToSimek() {
    let doi = this.data['doi'];
    let flowerNames = this.flower.names.map(tpl => tpl.join(' ')).join(' // ');

    if (Array.isArray(doi)) {
      doi = doi[0];
    }

    let dct = {
      recordId: this.id,
      doi: doi,
      title: this.title,
      abstract: this.abstract,
      lang: this.data['lang'],
      sourceDb: this.database,
      year: this.data['year'],
      source: this.source,
      volume: this.data['volume'],
      issue: this.data['issue'],
      startPage: this.data['startPage'],
      endPage: this.data['endPage'],
      authors: this.data['authors'],
      flowerNames: flowerNames,
      flowerFamily: this.flower.family,
      persistentRecordId: this.flower.getPersistentRecordId(this)
    };

    console.log(334455, dct);
    return dct;
  }

  get mainCriterionValue() {
    return this.mainCriterionWeight;
  }

  get authors() {
    return this.data['authors'] || [];
  }

  get database() {
    return this.data['_sourceDb'] || '?';
  }

  get lang() {
    return this.data['lang'] || '?lang?';
  }

  get title() {
    let t = this.data['title'] || '';
    return t.replace(/[\[\]]/g, ' ');
  }

  private _normalizedTitle: string;
  get normalizedTitle() {
    if (this._normalizedTitle)
      return this._normalizedTitle;
    this._normalizedTitle = this.title;
    return this._normalizedTitle;
  }

  get abstract() {
    return this.data['abstract'];
  }

  private _normalizedAbstract: string;
  get normalizedAbstract() {
    if (!this.abstract)
      return null;
    if (this._normalizedAbstract)
      return this._normalizedAbstract;
    this._normalizedAbstract = this.abstract;
    return this._normalizedAbstract;
  }


  get year() {
    let year = this.data['year'];
    return parseInt(year) || 999;
  }

  get source() {
    return (this.data['source'] || 'zzz').toLowerCase();
  }

 
  get shortCitation() {
    if (this.data['publicationNumber'])
      return this.data['publicationNumber'];

    return `${this.data['source']}, ${this.data['year']}, ${this.data['volume'] || ''}, ${this.data['spage'] || this.data['startPage'] || ''}`;
  }

  get isPatent() {
    return !!this.data['publicationNumber'];
  }

  get longCitation() {
    let tmp = [];
    tmp.push(`<div>${this.title}</div>`);
    tmp.push(`<div>${this.shortCitation}</div>`);
    tmp.push(`<div>${this.abstract || ''}</div>`);
    return tmp.join('');
  }

  get ratingMangerData() {
    let doi = this.data['doi'];
    if (Array.isArray(doi)) {
      doi = doi[0];
    }

    return {
      id: this.id,
      type: 'article',
      title: this.title,
      abstract: this.abstract,
      database: this.database,
      author: this.authors,
      source: this.data['source'] || '',
      year: this.data['year'] || '',
      volume: this.data['volume'] || '',
      issue: this.data['issue'] || '',
      startPage: this.data['spage'] || this.data['startPage'] || '',
      endPage: this.data['endPage'] || '',
      doi: doi,
      lang: this.data['lang'],
    }
  }

}
