import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { Router } from '@angular/router'
import { EditorService } from '../editor.service'
import { StatusService } from '../status.service'
import { UtilsService } from '../utils.service'

@Component({
  selector: 'app-flower-box',
  templateUrl: './flower-box.component.html',
  styleUrls: ['./flower-box.component.css']
})
export class FlowerBoxComponent implements OnInit {
  @Input() flower: Flower;
  @Input() order: number;
  @Input() isSearchQueue: boolean;
  isWhiteCheck = false;
  isNovelFoodsCheck = false;
  isInsectCheck = false;
  isAlgaeCheck = false;
  isFungiCheck = false;
  isCompoundCheck = false;


  constructor(private router: Router,
    public ss: StatusService,
    private es: EditorService,
    public utils: UtilsService,
  ) { }

  ngOnInit() {

  }

  get owner() {
    return this.es.getOwner(this.flower)
  }

  get assignee() {
    return this.es.getAssignee(this.flower)
  }

  get status() {
    return this.ss.flowerStatus(this.flower)
  }

  get name() {
    try {
      return this.flower.mainName.join(' ')
    } catch (e) { return this.flower.mainName.toString() }
  }

  get synonyms() {
    let ls = []
    this.flower.names.slice(1).forEach(x => {
      try {
        ls.push(x.join(' '))
      } catch (e) {
        ls.push(x)
      }
    })
    if (ls.length)
      return `[${ls.join('; ')}]`
    else
      return ''
  }

  get href() {
    return `${window.location.protocol}//${window.location.host}${this.flower.navigateTuple.join('/')}`
  }

  edit() {
    this.router.navigate(['/flowers', 'edit', this.flower.id])
  }

  display() {
    this.router.navigate(this.flower.navigateTuple)
  }
}
