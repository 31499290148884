import { Component, OnInit, Input } from '@angular/core';
import { DbRecord } from '../db-record'
import { Flower } from '../flower'
import { FulltextRating } from '../fulltext-rating'
import { FulltextRatingService } from '../fulltext-rating.service'
import { StatusService } from '../status.service'

@Component({
  selector: 'app-fulltext-rating-edit',
  templateUrl: './fulltext-rating-edit.component.html',
  styleUrls: ['./fulltext-rating-edit.component.css']
})
export class FulltextRatingEditComponent implements OnInit {
  @Input() dbRecord: DbRecord
  @Input() flower: Flower
  private _data: Object
  public fulltextRating: FulltextRating

  constructor(private frs: FulltextRatingService,
    private stats: StatusService) { }

  ngOnInit() {
    this._data = this.stats.fulltextEditData
    this.fulltextRating = this.frs.ownerRatings(this.flower)
  }

  selectReasons(tag) {
    return (this.fulltextRating.getItemRating(this.dbRecord) || ['undecided'])[0]== tag
  }

  get acceptanceLs() {
    return this._data['accept']
  }

  get undecidedLs() {
    return this._data['undecided']
  }

  get rejectLs() {
    return this._data['reject']
  }

}
