// tslint:disable:curly

import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower';
import { ArcService } from '../arc.service';
import { FlowerService } from '../flower.service';

@Component({
  selector: 'app-process-searches',
  templateUrl: './process-searches.component.html',
  styleUrls: ['./process-searches.component.css']
})
export class ProcessSearchesComponent implements OnInit {
  @Input() flower: Flower;
  isExpanded = false;
  public files = [];
  private _deleteFileIndexes = new Set<number>();

  constructor(
    private arcs: ArcService,
    public fs: FlowerService
  ) { }


  ngOnInit() {

  }

  get processMessage() {
    return this.fs.processMessage;
  }

  process() {
    //  this.fs.processGAEsearches(this.flower)
  }

  switch() {
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded)
      this.refreshFiles();
  }

  refreshFiles() {
    let obs = this.arcs.run({
      fun: 'listFlowerSearchFiles',
      flower: this.flower.id
    });
    obs.subscribe((res) => {
      this.files = [];
      res[0].forEach((x) => {
        if (x['filename'].split('.').pop() === 'json')
          return;
        this.files.push({
          gaePath: x['filename'],
          filename: x['filename'].split('/').pop(),
          size: x['st_size']
        });
      });
      this._deleteFileIndexes.clear();
    });
  }


  isFileForDelete(i: number) {
    return this._deleteFileIndexes.has(i);
  }

  deleteFile(i: number) {
    let x = this._deleteFileIndexes.delete(i);
    if (!x)
      this._deleteFileIndexes.add(i);
  }

  cancelDelete(i: number) {
    this._deleteFileIndexes.delete(i);
  }

  deleteConfirmed(i: number) {
    let obs = this.arcs.run({
      fun: 'deleteSearchFile',
      pth: this.files[i]['gaePath']
    });
    obs.subscribe(
      (res) => {
        this.refreshFiles();
      });
  }

}
