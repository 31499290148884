import { Component, OnInit, Input } from '@angular/core';
import { Flower } from '../flower'
import { FlowerService } from '../flower.service'

@Component({
  selector: 'app-flower-statistics',
  templateUrl: './flower-statistics.component.html',
  styleUrls: ['./flower-statistics.component.css']
})
export class FlowerStatisticsComponent implements OnInit {
  @Input() flower: Flower
  _stats: Object

  constructor(public fs: FlowerService) { }

  ngOnInit() {

  }

  get stats() {
    if (this._stats)
      return this._stats
    if (!this.fs.searchStats)
      return {}

    this._stats = this.fs.searchStats[this.flower.id]
    return this._stats
  }


  dbHitsCounter(tp: string) {
    let stats = this.stats
    if (!stats)
      return '-'
    let u = stats[tp] || 0
    let d = (stats['duplicates'] || {})[tp] || 0
    if (tp === 'cas') {
      u += stats['scifinder'] || 0
    }
    return u+d
  }

  latestSearch(tp: string) {
    let stats = this.stats
    if (!stats)
      return ''
    return stats['dates'][tp] || ''
  }


}
