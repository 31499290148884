import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FlowerService } from './flower.service';
import { ArcService } from './arc.service';

// https://auth0.com/docs/quickstart/spa/angular2/01-login
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  flowers: FlowerService;
  arcs: ArcService;
  private _isAdmin = undefined;
  private _email = undefined;
  private _userId = undefined;
  private _prepared = false;

  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: "nicmila.eu.auth0.com",
      client_id: "jqsAoAE50kdIYDt78NC84WSfRWZ4XcHs",
      leeway: 36000,
      redirect_uri: `${window.location.origin}/callback`
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;


  constructor(private router: Router,
  ) {

  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'

        return of(loggedIn);
      })
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      // If authenticated, response will be user object
      // If not authenticated, response will be 'false'
      this.loggedIn = !!response;
      if (this.loggedIn) {
        this.afterLoginInit();
      }
    });
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      //client.loginWithPopup();
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback`,
        appState: { target: redirectPath }
      });

    });
  }


  handleAuthCallback() {

    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // Have client, now call method to handle auth callback redirect
      tap(cbRes => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; get user and login status
        return combineLatest(
          this.getUser$(),
          this.isAuthenticated$
        );
      })
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, loggedIn]) => {
      // Redirect to target route after callback processing
      this.afterLoginInit()
      this.router.navigate([targetRoute]);
    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: "jqsAoAE50kdIYDt78NC84WSfRWZ4XcHs",
        returnTo: `${window.location.origin}`
      });
    });
  }

  afterLoginInit() {
    this.userProfile$.subscribe(resp => {
      this._email = resp['email']
      this.arcs.userNs = resp['user_id']
      this.flowers.init();
    })
  }

  get email() {
    return this._email
  }

  get isAdmin() {
    if (this._isAdmin === undefined) {
      this._isAdmin = new Set(
        [
          'nicmila@gmail.com',
          'kdibusz@gmail.com',
          'pavla.bartakova@gmail.com',
          'khush@ecomole.com',
          'beda@ecomole.com',
          'simona@ecomole.com'
        ]).has(this.email);
    }
    return this._isAdmin;
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticated)
      return true;
  }


  get authenticated() {
    return this.loggedIn;
  }
}


/*

import { Injectable } from '@angular/core';
import { AuthHttp, tokenNotExpired } from 'angular2-jwt/angular2-jwt';
import { UtilsService } from './utils.service';
import { ArcService } from './arc.service';
import { FlowerService } from './flower.service'

import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

declare var Auth0Lock: any;

@Injectable()
export class AuthService {
  private _isAdmin = undefined;
  private _email = undefined;
  private _prepared = false;

  lock = new Auth0Lock('jqsAoAE50kdIYDt78NC84WSfRWZ4XcHs',
    'nicmila.eu.auth0.com',
    { auth: { redirect: false } });

  constructor(
    private utils: UtilsService,
    private router: Router,
    private fs: FlowerService
  ) {
    this.utils.auths = this

    this.lock.on("authenticated", (authResult) => {
      this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          // Handle error
          console.log(error)
          alert("ERROR: see console");
          return;
        }
        localStorage.setItem('auth_profile', JSON.stringify(profile));
        //localStorage.setItem('accessToken', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        //localStorage.setItem('idToken', authResult.idToken);
        this.prepare();
        this.router.navigate(['flowers', 'list'])
      }, (err) => { console.log(err) });
    })
  }

  prepare() {
    if (this._prepared)
      return
    this._prepared = true
    this.utils.uniqueIdentifier
    this.fs.init()
    this.utils.auths = this
  }

  login() {
    // Call the show method to display the widget.
    this.lock.show();
  };

  get email() {
    if (this._email === undefined)
      this._email = JSON.parse(localStorage
        .getItem('auth_profile'))['email']

    return this._email
  }

  get isAdmin() {
    if (this._isAdmin === undefined) {
      this._isAdmin = new Set(
        [
          'nicmila@gmail.com',
          'kdibusz@gmail.com',
          'miroslav.simek@gmail.com',
          'pavla.bartakova@gmail.com',
          'khush@ecomole.com',
          'beda@ecomole.com',
          'simona@ecomole.com'
        ]).has(this.email);
    }
    return this._isAdmin;
  }

  get authenticated() {
    let state = tokenNotExpired('id_token');
    if (state)
      this.prepare()
    return state
  };

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticated)
      return true;
  }

  logout() {
    // Remove token from localStorage
    localStorage.removeItem('auth_profile');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('id_token');
    localStorage.removeItem('idToken');
    location.reload()
  };

}

*/
