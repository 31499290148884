<span (click)='switch($event)'
    [class.dbRecordBookmarkEditor]='isDbRecordBookmarkEditor'
    [class.top]='isTop50'
    [class.top]='isTop100'
    [class.family]='isFamily'
    [class.selected]="isSelected"
    [class.excluded]="isExcluded"
    >
    <input *ngIf='checkbox'
    type='checkbox'
    [checked]='isSelected' />
{{taglabel}}
</span>
