<table>
  <tr>
    <td class='notRated'>
      <h1>NOT RATED </h1>
      <app-tag-abstract-rating [dbRecord]='dbRecord'
          [label]='initialObj.title'
          [value]='initialObj.value'
          [abstractRating]='abstractRating'
          [tp]=0></app-tag-abstract-rating>
    </td>
    <td rowspan=2 class='rejected'>
      <h1>REJECTED</h1>
      <div *ngFor='let obj of rejectLs'>
        <app-tag-abstract-rating [dbRecord]='dbRecord'
            [label]='obj.title'
            [value]='obj.value'
            [abstractRating]='abstractRating'
            [tp]=0></app-tag-abstract-rating>
        <span *ngIf='obj.subtitle'
            class='subtitle'
            [title]='obj.subtitle'></span>
      </div>
    </td>
  </tr>
  <tr>
    <td class='accepted'>
      <h1>ACCEPTED</h1>
      <div *ngFor='let obj of acceptanceLs'>
        <app-tag-abstract-rating [dbRecord]='dbRecord'
            [label]='obj.title'
            [value]='obj.value'
            [abstractRating]='abstractRating'
            [tp]=1></app-tag-abstract-rating>
      </div>
    </td>
  </tr>
</table>
