import { Component, OnInit, Input } from '@angular/core';
import { DbRecord } from '../db-record'
import { StatusService } from '../status.service'
import { FulltextRating } from '../fulltext-rating'

@Component({
  selector: 'app-tag-fulltext-rating',
  templateUrl: './tag-fulltext-rating.component.html',
  styleUrls: ['./tag-fulltext-rating.component.css']
})
export class TagFulltextRatingComponent implements OnInit {
  @Input() tp: string
  @Input() dbRecord: DbRecord
  @Input() label: string;
  @Input() value: string;
  @Input() fulltextRating: FulltextRating

  constructor(private ss: StatusService) { }

  ngOnInit() {

  }

  get isMain() {
    return this.tp == 'main'
  }

  get isSelected() {
    let fr = this.fulltextRating.getItemRating(this.dbRecord)
    if (this.isMain) {
      if (!fr)
        return this.value == 'undecided'
      return fr[0] == this.value
    } else {
      if (!fr)
        return false
      return fr[1].has(this.value)
    }
  }

  switch() {
    if (this.tp == 'main')
      this.fulltextRating.setItemRating(this.dbRecord, [this.value, new Set<string>()])
    else
      this.fulltextRating.setItemReason(this.dbRecord, this.value)
  }

}
