import { Component, OnInit, Input } from '@angular/core';
import { Editor } from '../editor'
import { PageService } from '../page.service'

@Component({
  selector: 'app-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.css']
})
export class TagEditorComponent implements OnInit {
  @Input() editor: Editor
  @Input() tp: string
  @Input() i: number

  constructor(private pgs: PageService) { }

  ngOnInit() {

  }

  get isBreak() {
    return this.i && !(this.i%7)
  }

  switch() {
    if (this.tp == 'assigneeFilter')
        return this.pgs.setAssigneeFilter(this.editor)
    this.pgs.setOwnerFilter(this.editor)
  }

  get isSelected() {
    if (this.tp == 'assigneeFilter')
        return this.pgs.isAssigneeFilter(this.editor)
    return this.pgs.isOwnerFilter(this.editor)
  }

  get label() {
    if (!this.editor)
      return "-"
    return this.editor.displayName
  }
}
