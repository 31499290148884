import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-flower-dashboard',
  templateUrl: './flower-dashboard.component.html',
  styleUrls: ['./flower-dashboard.component.css']
})
export class FlowerDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
