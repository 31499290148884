import { Component, OnInit, Input } from '@angular/core';
import { CrossrefRecord } from '../crossref-record'

@Component({
  selector: 'app-crossref-record-box',
  templateUrl: './crossref-record-box.component.html',
  styleUrls: ['./crossref-record-box.component.css']
})

export class CrossrefRecordBoxComponent implements OnInit {
  @Input() record: CrossrefRecord

  constructor() { }

  ngOnInit() {
    //console.log(this.record)
  }

  get doiURL() {
    return `https://doi.org/${this.record.doi}`
  }

  isbnURL(isbn) {
    return `https://www.worldcat.org/search?qt=worldcat_org_all&q=${isbn}`
  }

  issnURL(issn) {
    return `https://www.worldcat.org/search?qt=worldcat_org_all&q=${issn}`
  }



  get citation() {
    let source = this.record.source || ''
    let year = this.record.year ? `, <b>${this.record.year}</b>` : ''
    let volume = this.record.volume ? `, ${this.record.volume}` : ''
    let issue = this.record.issue ? `(${this.record.issue})` : ''
    let page = this.record.page ? `, ${this.record.page}` : ''
    return `${source}${year}${volume}${issue}${page}.`
  }

}
