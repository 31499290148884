import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentation-dashboard',
  templateUrl: './documentation-dashboard.component.html',
  styleUrls: ['./documentation-dashboard.component.css']
})
export class DocumentationDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
