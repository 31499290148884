<button  *ngIf='!crossrefRecordsLs.length' (click)='run()'>
  Check {{type}}
</button>
<div class='main' *ngIf='running'> CROSSREF INQUIRY STARTED </div>
<div *ngIf='crossrefRecordsLs.length' class='main'>
  <div class='button'>
    <button (click)='close()'>Close</button>
  </div>
  <app-crossref-record-box *ngFor='let cr of crossrefRecordsLs'
      [record]='cr'></app-crossref-record-box>
</div>
